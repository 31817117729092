import * as Turf from '@turf/turf'
import { watch } from 'vue'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore, useLegendStore } from '../stores'
import ZLayers from '../../../../z_layers'
import Vector from '../vector'
export default class PerimeterSegmentAngles {
  constructor() {
    this.sourceId = 'perimeter-segment-angles' // used as label id too
    this.mapStore = useMapStore()
    this.geometryStore = useGeometryStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.labelsByConnectedPerimeterId = {}
    this.config = {
      textColor: this.mapStore.colors.line.red,
      textHaloColor: this.mapStore.colors.line.black,
      visible: false
    }
  }

  initialize() {
    this.mapStore.map.addSource(this.sourceId, {
      'type': 'geojson',
      'data': this.initLabels(),
      'promoteId': 'id'
    })

    this.mapStore.map.addLayer({
      'id': this.sourceId,
      'type': 'symbol',
      'source': this.sourceId,
      'layout': {
        'text-rotation-alignment': 'viewport',
        'text-anchor': ['get', 'anchor'],
        'text-field': ['get', 'text'],
        'text-allow-overlap': true,
        'visibility': this.config.visible ? 'visible' : 'none'
      },
      'paint': {
        'text-color': this.config.textColor,
        'text-halo-color': this.config.textHaloColor,
        'text-halo-width': 0.5
      },
      'filter': ['==', 'visible', true]
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId))

    this.subscribeToLegendStore()
  }

  modeChanged() {
    if (this.modeStore.inBulkMoveMode || this.modeStore.inPlacementMode) {
      return this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', 'none')
    } else {
      this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', this.config.visible ? 'visible' : 'none')
      this.refresh()
    }
  }

  initLabels() {
    const angles = []
    _.forEach(this.geometryStore.getConnectedSections, (section) => {
      angles.push(...this.angles(section, section.clockwiseVertices))
    })

    this.setVisibleProperty(angles)

    return Turf.featureCollection(angles)
  }

  refresh() {
    this.mapStore.setViewSource(this.sourceId, this.initLabels())
  }

  recalculate(feature) {
    const section = this.geometryStore.sections[feature.properties.section_id]

    if (!section.isConnected) { return }

    const angles = this.mapStore.map.getSource(this.sourceId)._data.features
    const oldAngles = _.remove(angles, f => section.id == f.properties.section_id)

    if (oldAngles.length == 0) { return }

    // get the most updated version of the perimeters
    const vertices = section.updatedClockwiseVertices

    if (vertices.length == 0) { return }

    const newAngles = this.angles(section, vertices)

    this.setVisibleProperty(newAngles)

    angles.push(...newAngles)
    this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(angles))
  }

  subscribeToLegendStore() {
    watch(
      () => this.legendStore.noVisibilityIds,
      (newNoVisibilityIds) => {
        const labels = this.mapStore.map.getSource(this.sourceId)._data.features
        this.setVisibleProperty(labels)
        this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(labels))
      }
    )
  }

  setVisibleProperty(labels) {
    const labelsByConnectedPerimeterId1 = _.groupBy(labels, 'properties.connected_perimeter_id_1')
    const labelsByConnectedPerimeterId2 = _.groupBy(labels, 'properties.connected_perimeter_id_2')

    _.forEach(labels, f => f.properties.visible = true)
    _.forEach(this.legendStore.noVisibilityIds, id => {
      if (labelsByConnectedPerimeterId1[id]) (labelsByConnectedPerimeterId1[id].forEach(l => l.properties.visible = false))
      if (labelsByConnectedPerimeterId2[id]) (labelsByConnectedPerimeterId2[id].forEach(l => l.properties.visible = false))
    })
  }

  addControls() {
    const folder = this.mapStore.menu.folders.find(folder => folder._title == "Perimeter Segments")

    const colorController = folder.add(this.config, 'textColor', this.mapStore.colors.line).name('vertex angle color')
    const visibilityController = folder.add(this.config, 'visible').name('show angles')

    colorController.onChange(value => {
      this.mapStore.map.setPaintProperty(this.sourceId, 'text-color', this.mapStore.colorName(value))
    })

    visibilityController.onChange(value => {
      this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', value ? 'visible' : 'none')
    })
  }

  text(angleInDegrees) {
    return `${angleInDegrees.toFixed(2)}°`
  }

  findNextDifferentVertex(vertices, i) {
    const curr = vertices[i]
    let aux = (i + 1) % vertices.length
    let next = vertices[aux]

    while (_.isEqual(curr.mercatorCoordinates, next.mercatorCoordinates) && aux != i) {
      aux = (aux + 1) % vertices.length
      next = vertices[aux]
    }

    return next
  }

  angles(section, vertices) {
    // 1- Iterate through the vertices in groups of three, calculating the angle formed by these vertices.
    // 2- Adjust the angle based on the cross product to ensure we end up with the internal angle.
    // 3- Determine the text-anchor based on the bisector angle relative to the Y-axis (north).
    // 4- Create a label with the angle value and the determined text-anchor.

    const angles = []
    for (var i = 0; i < vertices.length; i++) {
      // 1- Iterate in groups of three and calculate the formed angle
      const prev = vertices[i-1] || _.last(vertices)
      const curr = vertices[i]
      const next = this.findNextDifferentVertex(vertices, i)

      // Skip if the current vertex is a joint, it'll be handled in the next iteration
      if (_.isEqual(curr.mercatorCoordinates, prev.mercatorCoordinates)) { continue }

      const vectorA = [prev.mercatorCoordinates[0] - curr.mercatorCoordinates[0], prev.mercatorCoordinates[1] - curr.mercatorCoordinates[1]]
      const vectorB = [next.mercatorCoordinates[0] - curr.mercatorCoordinates[0], next.mercatorCoordinates[1] - curr.mercatorCoordinates[1]]

      let angle = Vector.angleBetweenVectors(vectorA, vectorB)

      if (isNaN(angle)) { continue }

      const crossProductAB = Vector.crossProduct(vectorA, vectorB)

      // 2- Adjust angle
      if (crossProductAB < 0) {
        angle = 2 * Math.PI - angle
      }
      const angleInDegrees = (angle * 180) / Math.PI

      // 3- Determine text-anchor
      const anchor = this.anchor(vectorA, vectorB)

      // 4- Create label
      const angleLabel = Turf.point(curr.coordinates)
      angleLabel.properties.text = this.text(angleInDegrees)
      angleLabel.properties.anchor = anchor
      angleLabel.properties.section_id = section.id
      angleLabel.properties.connected_perimeter_id_1 = curr.id
      angleLabel.properties.connected_perimeter_id_2 = next.id
      angles.push(angleLabel)
    }

    return angles
  }

  anchor(vectorA, vectorB) {
    // Determines the text-anchor based on the bisector angle relative to the Y-axis (north)
    // In other words, it determines the position of the text based on the direction of the angle
    // formed by the two vectors

    const bisector = Vector.bisector(vectorA, vectorB)
    const yAxis = [0, 1]

    let angle = Vector.angleBetweenVectors(bisector, yAxis)

    // Ensure angle is clockwise
    if (Vector.crossProduct(yAxis, bisector) > 0) {
      angle = 2 * Math.PI - angle
    }

    const angleStep = Math.PI / 8
    if (angle >= 0 && angle < angleStep) return "bottom"
    if (angle >= angleStep && angle < 3 * angleStep) return "bottom-left"
    if (angle >= 3 * angleStep && angle < 5 * angleStep) return "left"
    if (angle >= 5 * angleStep && angle < 7 * angleStep) return "top-left"
    if (angle >= 7 * angleStep && angle < 9 * angleStep) return "top"
    if (angle >= 9 * angleStep && angle < 11 * angleStep) return "top-right"
    if (angle >= 11 * angleStep && angle < 13 * angleStep) return "right"
    if (angle >= 13 * angleStep && angle < 15 * angleStep) return "bottom-right"
    return "bottom"
  }
}
