<div class="grow space-y-1 h-[130rem] overflow-y-scroll">
  <LegendItem
    v-for="(item, idx) in legendItemTree"
    :key="idx"
    :index="idx"
    :item="item"
    @checkbox-value-change="checkboxValueChange"
  />
</div>
<div class="flex flex-col content-end flex-nowrap my-1 bg-white sticky bottom-0 h-[50rem] overflow-x-auto">
  <div class="flex my-1 flex-col justify-end items-baseline">
    <p class="text-xs">
      Show:
    </p>
    <div class="flex mt-1">
      <button @click="selectAllOptions"
        class="shadow bg-blue-500 hover:bg-blue-700 text-white text-xs font-semibold py-1 px-2 rounded-full cursor-pointer"
      >
        All
      </button>
      <button @click="deselectAllOptions"
        class="ml-2 shadow bg-blue-500 hover:bg-blue-700 text-white text-xs font-semibold py-1 px-2 rounded-full cursor-pointer"
      >
        None
      </button>
      <button @click="toggleNoGeometry"
        class="ml-2 shadow bg-blue-500 hover:bg-blue-700 text-white text-xs font-semibold py-1 px-2 rounded-full cursor-pointer"
      >
        No geometry
      </button>
    </div>
  </div>
  <div class="flex flex-wrap legend-items-by-type">
    <EntityItem
      v-for="item in legendItemTypes['entities']"
      :key="item.id"
      :item="item"
      @checkbox-value-entity-change="checkboxValueEntityChange"
    />
  </div>
</div>
