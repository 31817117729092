import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['generateReportForm']

  hideForm() {
    if (this.hasGenerateReportFormTarget) {
      this.generateReportFormTarget.classList.add("hidden");
    }
  }
}
