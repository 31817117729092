import { defineStore } from 'pinia'

import * as Getters from './getters'
import * as Actions from './actions'

export const useGeometryStore = defineStore('geometry', {
  state: () => ({
    perimeterSegments: {},
    inventories: {},
    defects: {},
    sections: {},
    types: ['PerimeterSegments', 'Inventories', 'Defects'],
    selectedFeature: null,
    selectedCoordPaths: null,
    changedFeatures: {},
    classificationIdToNumber: {}
  }),
  getters: {
    featureById: Getters.featureById,
    features: Getters.features,
    featureIds: Getters.featureIds,
    allFeatureIds: Getters.allFeatureIds,
    featuresWithExtendedProps: Getters.featuresWithExtendedProps,
    typeOf: Getters.typeOf,
    featureCollection: Getters.featureCollection,
    allFeatures: Getters.allFeatures,
    hasUnsavedChanges: Getters.hasUnsavedChanges,
    sectionCenters: Getters.sectionCenters,
    sectionCenterCollection: Getters.sectionCenterCollection,
    recordIdsWithoutGeometry: Getters.recordIdsWithoutGeometry,
    getConnectedSections: Getters.getConnectedSections,
  },
  actions: {
    pushFeature: function (f) { this.changedFeatures[f.id] = f },
    clearChanges: function () { this.changedFeatures = {} },
    setSelectedFeature: Actions.setSelectedFeature,
    setSelectedCoordPaths: Actions.setSelectedCoordPaths,
    programmaticSelectFeature: Actions.programmaticSelectFeature,
    setSections: Actions.setSections,
    refreshFeatures: Actions.refreshFeatures,
    saveAll: Actions.saveAll,
    deleteWithLocking: Actions.deleteWithLocking,
    moveAndRotateFeatures: Actions.moveAndRotateFeatures,
    moveAndRotateAllFeatures: Actions.moveAndRotateAllFeatures,
    createCoordinateBetween: Actions.createCoordinateBetween,
    closeAndSavePolygon: Actions.closeAndSavePolygon,
    setSectionCenterVisibility: Actions.setSectionCenterVisibility
  }
})
