<div>
  <div class="flex justify-between items-center hover:bg-gray-lightest" :class="{ '!bg-blue-lighter': isMyContextMenuOpen }">
    <div class="flex items-center text-sm flex-grow">
      <input
        type="checkbox"
        :indeterminate="item.indeterminateValue"
        v-model="itemCheckboxValue"
        class="mr-2 focus:ring-0"
        :class="item.disabled ? 'opacity-75 text-gray-500' : ''"
        @change="checkboxValueChange(item)"
        :disabled="item.disabled"
      />
      <label
        class="no-underline hover:underline cursor-pointer"
        @click="handleLabelClick"
        :data-id="item.id">
        {{ itemLabel }}
      </label>
      <span v-if="alertMessage" class="flex-none legend-tooltip" :class="{ 'tooltip-above': isInteriorPerimeterGroup }">
        <ExclamationCircleIcon class="h-4 w-4 text-red-500"/>
        <span class="tooltiptext">{{alertMessage}}</span>
      </span>
      <EllipsisVerticalIcon v-if="hasContentextMenuItems" @click.stop="openContextMenu()"
        class="flex-none h-4 w-4 text-gray-500 cursor-pointer context-menu-button" />
    </div>
    <component v-if="hasNestedItems" :is="showNestedItems ? 'ChevronDownIcon' : 'ChevronUpIcon'"
      @click="toggleNestedItems"
      class="flex-none h-5 w-5 text-gray-500 cursor-pointer" />
  </div>
  <div v-if="showNestedItems" class="ml-6">
    <!-- Loop nested items -->
    <LegendItem
      v-for="(item, idx) in item.items"
      :key="idx"
      :index="idx"
      :item="item"
      @checkbox-value-change="checkboxValueChange"
    />
  </div>
</div>
