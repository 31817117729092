import * as Turf from '@turf/turf'
import polylabel from 'polylabel'

const isPoint = (feature) => feature.geometry.type == 'Point'
const isPolygon = (feature) => feature.geometry.type == 'Polygon'
const isLineString = (feature) => feature.geometry.type == 'LineString'

const calculateCenter = (feature) => {
  if (isPoint(feature)) {
    return Turf.point(feature.geometry.coordinates)
  }
  if (isLineString(feature)) {
    return Turf.along(feature, Turf.length(feature) / 2)
  }
  if (isPolygon(feature)) {
    return Turf.point(polylabel(feature.geometry.coordinates, 0.000001))
  }
}
class BBox {
  constructor(featureCollection) {
    this.dir = { north: 0, east: 90, south: 180, west: 270 }
    this.featureCollection = featureCollection
    this.bbox = Turf.bbox(featureCollection).map(coord => Number(coord))
    // BBox coords should respect the following order [minX, minY, maxX, maxY].
    // Turf's bbox method seems to have a bug that may mix up the order of the coordinates.
    // So we need to ensure minX < maxX and minY < maxY
    this.ensureCorrectBBoxOrder()
  }

  get southWest() {
    // [west, south]
    return Turf.point([this.bbox[0], this.bbox[1]])
  }

  get southEast() {
    // [east, south]
    return Turf.point([this.bbox[2], this.bbox[1]])
  }

  get northWest() {
    // [west, north]
    return Turf.point([this.bbox[0], this.bbox[3]])
  }

  get northEast() {
    // [east, north]
    return Turf.point([this.bbox[2], this.bbox[3]])
  }

  get value() {
    // [west, south, east, north]
    return this.bbox
  }

  ensureCorrectBBoxOrder() {
    // Ensure minX < maxX
    if (this.bbox[0] > this.bbox[2]) {
      [this.bbox[0], this.bbox[2]] = [this.bbox[2], this.bbox[0]]
    }
    // Ensure minY < maxY
    if (this.bbox[1] > this.bbox[3]) {
      [this.bbox[1], this.bbox[3]] = [this.bbox[3], this.bbox[1]]
    }
  }

  extend(distance, options = { units: 'meters' }) {
    let southWest = this.southWest
    let northEast = this.northEast

    southWest = Turf.destination(southWest, distance, this.dir.south, options)
    southWest = Turf.destination(southWest, distance, this.dir.west, options)
    northEast = Turf.destination(northEast, distance, this.dir.north, options)
    northEast = Turf.destination(northEast, distance, this.dir.east, options)

    this.bbox[0] = southWest.geometry.coordinates[0] // minX
    this.bbox[1] = southWest.geometry.coordinates[1] // minY
    this.bbox[2] = northEast.geometry.coordinates[0] // maxX
    this.bbox[3] = northEast.geometry.coordinates[1] // maxY
    return this
  }

  toSquare() {
    const horizontalDistance = Turf.distance(this.southWest, this.southEast, 'kilometers')
    const verticalDistance = Turf.distance(this.southWest, this.northWest, 'kilometers')

    if(horizontalDistance >= verticalDistance) {
      const verticalMidpoint = Turf.midpoint(this.southWest, this.northWest)
      this.bbox[1] = Turf.destination(verticalMidpoint, horizontalDistance / 2, this.dir.south, { units: 'kilometers' }).geometry.coordinates[1]
      this.bbox[3] = Turf.destination(verticalMidpoint, horizontalDistance / 2, this.dir.north, { units: 'kilometers' }).geometry.coordinates[1]
    } else {
      const horizontalMidpoint = Turf.midpoint(this.southWest, this.southEast)
      this.bbox[0] = Turf.destination(horizontalMidpoint, verticalDistance / 2, this.dir.west, { units: 'kilometers' }).geometry.coordinates[0]
      this.bbox[2] = Turf.destination(horizontalMidpoint, verticalDistance / 2, this.dir.east, { units: 'kilometers' }).geometry.coordinates[0]
    }

    return this
  }

  draw(map, color) {
    const id = Math.random().toString()
    map.addSource(id, {
      'data': Turf.featureCollection([Turf.bboxPolygon(this.value)]),
      'type': 'geojson'
    })

    map.addLayer({
      'id': id,
      'type': 'fill',
      'source': id,
      'paint': { 'fill-color': color, 'fill-opacity': 0.6 }
    })
  }
}

export {
  isPoint,
  isPolygon,
  isLineString,
  calculateCenter,
  BBox
}
