import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  show() {
    const sidebar = document.getElementById("js_sidebar");
    sidebar.classList.remove("hidden");
  }

  hide() {
    const sidebar = document.getElementById("js_sidebar");
    sidebar.classList.add("hidden");
  }
}
