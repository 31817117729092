import _ from 'lodash'

const locationCoords = (state) => [Number(state.location.g_long), Number(state.location.g_lat)]

const layerNames = (state) => (name, filterTypes = null) => {
  const kebabName = _.kebabCase(name)

  if (filterTypes) {
    return state.layers[name].types.filter((t) => filterTypes.includes(t)).map((t) => `${kebabName}-${t}`)
  } else {
    return state.layers[name].types.map((t) => `${kebabName}-${t}`)
  }
}

const layerPaint = (state) => (type, { color, lineWidth, pointRadius }) => {
  const paint = {}
  const pat = new RegExp(`^${type}.*-color$`)
  Object.entries(state.layerStyles[type])
    .forEach(([k, v]) => { paint[k] = pat.test(k) ? state.colors.line[color] : v })

  if (type == 'fill') { paint['fill-color'] = 'transparent' }
  if (type == 'line') { paint['line-width'] = lineWidth }
  if (type == 'point') { paint['circle-radius'] = pointRadius }
  return paint
}

const colorProps = (state) => (style) => {
  const styleObj = state.layerStyles[style]
  const pat = new RegExp(`-color$`)
  return Object.keys(styleObj).filter((name) => pat.test(name))
}

const colorName = (state) => (hex) => (
  Object.keys(state.colors.line).find(key => state.colors.line[key] == hex)
)

const layerConfigs = (state) => (name) => {
  const layer = state.layers[name]
  const kebabName = _.kebabCase(name)
  return layer.types.map((type) => {
    const ruleString = state.layerFilterRule[type]
    return {
      id: `${kebabName}-${type}`,
      type,
      source: kebabName,
      layout: { visibility: layer.visible ? 'visible' : 'none' },
      paint: layerPaint(state)(type, layer),
      filter: ['all', ruleString, ['==', 'visible', true]]
    }
  })
}

const getRenderedFeatures = (state) => (point) => {
  const selectionBoxSize = 5
  const selectionBox = [
    [point.x - selectionBoxSize, point.y - selectionBoxSize],
    [point.x + selectionBoxSize, point.y + selectionBoxSize]
  ]

  return _.chain(state.map.queryRenderedFeatures(selectionBox))
    .map((feature) => ({
      featureId: feature.properties.user_feature_id || feature.properties.feature_id,
      sectionCode: feature.properties.user_section_code || feature.properties.section_code,
      sectionId: feature.properties.user_section_id || feature.properties.section_id,
      classificationName: feature.properties.user_classification_name || feature.properties.classification_name,
      recordId: feature.properties.user_record_id || feature.properties.record_id,
    }))
    .filter('featureId') // discard features coming from unrelated sources (e.g.: street infromation sources)
    .uniqBy('featureId') // remove potential duplicated features that were added automatically by the drawing tool
    .value()
}

const showPopup = (state) => state.popupData != null

export {
  locationCoords,
  layerNames,
  layerPaint,
  colorProps,
  colorName,
  layerConfigs,
  getRenderedFeatures,
  showPopup
}
