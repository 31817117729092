import * as Turf from '@turf/turf'
import { watch } from 'vue'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore, useLegendStore } from '../stores'
import ZLayers from '../../../../z_layers'
import colors from '../../../../colors'
import { constants } from '../constants'

export default class ConditionScoreBackgrounds {
  constructor() {
    this.id = 'condition-score-backgrounds'
    this.mapStore = useMapStore()
    this.geometryStore = useGeometryStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.config = {
      visible: false
    }
  }

  initialize() {
    this.mapStore.map.addSource(this.id, {
      'type': 'geojson',
      'data': this.initBackgrounds(),
      'promoteId': 'id'
    })

    this.mapStore.map.addLayer({
      'id': this.id,
      'type': 'fill',
      'source': this.id,
      'layout': {
        'visibility': this.config.visible ? 'visible' : 'none'
      },
      'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.8
      },
      'filter': ['==', 'visible', true]
    }, ZLayers.myPosition.call(this.mapStore, this.id))

    this.subscribeToLegendStore()
  }

  modeChanged() {
    if (this.modeStore.inBulkMoveMode || this.modeStore.inPlacementMode) {
      return this.mapStore.map.setLayoutProperty(this.id, 'visibility', 'none')
    } else {
      this.mapStore.map.setLayoutProperty(this.id, 'visibility', this.config.visible ? 'visible' : 'none')
      this.refresh()
    }
  }

  initBackgrounds() {
    const sectionPolygons = _.map(this.geometryStore.getConnectedSections, (section) => {
      return this.sectionPolygon(section, section.vertices)
    })

    this.setVisibleProperty(sectionPolygons)

    return Turf.featureCollection(sectionPolygons)
  }

  sectionPolygon(section, coords) {
    try {
      const sectionPolygon = Turf.polygon([coords])
      sectionPolygon.id = section.id
      sectionPolygon.properties.color = section.scoreColor
      sectionPolygon.properties.id = section.id
      return sectionPolygon
    } catch(e) {
      console.error(e)
      throw new Error('Could not generate section polygon for section with id ' + section.id)
    }
  }

  refresh() {
    this.mapStore.setViewSource(this.id, this.initBackgrounds())
  }

  recalculate(feature) {
    const section = this.geometryStore.sections[feature.properties.section_id]
    if (!section.isConnected) { return }

    const sectionPolygons = this.mapStore.map.getSource(this.id)._data.features
    const oldSectionPolygon = _.remove(sectionPolygons, f => section.id == f.id)[0]

    const newSectionPolygon = this.sectionPolygon(section, section.updatedVertices)
    const isVisible = oldSectionPolygon.properties.visible
    newSectionPolygon.properties.visible = isVisible
    sectionPolygons.push(newSectionPolygon)

    this.mapStore.setViewSource(this.id, Turf.featureCollection(sectionPolygons))
  }

  subscribeToLegendStore() {
    watch(
      () => this.legendStore.noVisibilityIds,
      (newNoVisibilityIds) => {
        const sectionPolygons = this.mapStore.map.getSource(this.id)._data.features
        this.setVisibleProperty(sectionPolygons)
        this.mapStore.setViewSource(this.id, Turf.featureCollection(sectionPolygons))
      }
    )
  }

  setVisibleProperty(sectionPolygons) {
    const sectionPolygonsBySectionId = _.groupBy(sectionPolygons, 'id')

    _.forEach(sectionPolygons, f => f.properties.visible = true)
    _.forEach(this.legendStore.noVisibilityIds, id => {
      if (this.geometryStore.typeOf(id) == constants.targets.PERIMETER) {
        const sectionId = this.geometryStore.perimeterSegments[id].section_id
        if (sectionPolygonsBySectionId[sectionId]) {
          sectionPolygonsBySectionId[sectionId][0].properties.visible = false
        }
      }
    })
  }

  addControls() {
    const folder = this.mapStore.menu.folders.find(folder => folder._title == "Settings")
    const visibilityController = folder.add(this.config, 'visible').name('show condition scores')

    visibilityController.onChange(value => {
      this.mapStore.map.setLayoutProperty(this.id, 'visibility', value ? 'visible' : 'none')
    })
  }
}
