import * as Vue from 'vue/dist/vue.esm-bundler.js'

export default {
  name: 'InitializationError',
  methods: {
    reloadPage() { location.reload() }
  },
  template: `
    <div class="flex flex-col items-center justify-center">
      <p class="text-white">An error occurred during initialization.</p>
      <button
        @click="reloadPage()"
        class="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4 cursor-pointer"
      >
        Reload
      </button>
    </div>
  `
}
