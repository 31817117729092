import * as Sentry from '@sentry/browser'
import _ from 'lodash'

import { useMapStore } from './map_store'
import { useGeometryStore } from './geometry_store'
import { useLegendStore } from './legend_store'
import { useLockStore } from './lock_store'
import { useModeStore } from './mode_store'

const tryWithError = async (fn, args, errorCallback) => {
  // fn must be an ApiClient method returning an ApiResponse

  const response = _.isArray(args) ? await fn(...args) : await fn(args)

  if (response.errored()) { errorCallback(response.error) }

  return response.succeeded()
}

export {
  useMapStore,
  useGeometryStore,
  useLegendStore,
  useLockStore,
  useModeStore,
  tryWithError
}
