import { mapStores } from 'pinia'

import { useModeStore, useLockStore } from '../../stores'

export default {
  name: 'SaveAndExitButton',
  props: {
    saveCallback: Function
  },
  computed: {
    ...mapStores(useModeStore, useLockStore),
  },
  methods: {
    handleClick: async function() {
      this.modeStore.startLoading()
      const confirmed = await this.lockStore.confirmSave()
      if (!confirmed) {
        if (confirmed !== null) { this.modeStore.stopLoading() } // Not a fetch error
        return
      }
      const lockArgs = [this.lockStore.releaseLock, this.modeStore.savingError]
      const released = await this.lockStore.tryLock(...lockArgs)
      if (!released) { return }

      await this.saveCallback()
    }
  },
  template: `<button @click='handleClick()' class='whitespace-nowrap black-button ml-2'>Save & Exit</button>`
}
