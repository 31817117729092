import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import _ from 'lodash'
import colors from '../../../../colors'

const id = 'perimeters'

const Perimeters = {}

const color = (count) => {
  if (count == 0) return colors.WHITE
  if (count <= 3) return colors.workOrder.BLUE_LIGTHER
  if (count <= 7) return colors.workOrder.BLUE
  return colors.workOrder.BLUE_DARKER
}

Perimeters.load = function() {
  const sectionPolygons = this.sections.map(({ id, code, work_orders_count, perimeters }) => {
    const coords = perimeters
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((p) => p.geometry.data.geometry.coordinates)
      .flat()

    try {
      const sectionPolygon = Turf.polygon([coords])
      sectionPolygon.id = id
      sectionPolygon.properties.color = color(work_orders_count)
      return sectionPolygon
    } catch(e) {
      console.error(e)
      throw new Error('Could not generate section polygon for section ' + code + ' with id ' + id)
    }
  })

  this.map.addSource(id, {
    'data': Turf.featureCollection(sectionPolygons),
    'type': 'geojson'
  })

  this.map.addLayer({
    'id': id,
    'type': 'fill',
    'source': id,
    'paint': {
      'fill-color': ['get', 'color'],
      'fill-opacity': 0.75
    }
  }, ZLayers.myPosition.call(this, id))

  this.map.addLayer({
    'id': `${id}-outline`,
    'type': 'line',
    'source': id,
    'paint': {
      'line-color': '#000',
      'line-width': 2
    }
  }, ZLayers.myPosition.call(this, `${id}-outline`))
}

export default Perimeters
