import { tokenHeader, tokenParam, surveyId } from '.'
import { ApiClient, ApiResponse } from './api_client'

const baseURL = () => `/admin/api/surveys/${surveyId()}/survey_locks`

const check = async () => {
  return await ApiClient.makeRequest({
    url: baseURL(),
    method: 'GET',
    ...tokenHeader()
  })
}

const lock = async () => {
  return await ApiClient.makeRequest({
    url: baseURL(),
    method: 'POST',
    ...tokenHeader()
  })
}

const unlock = async (action, useSendBeacon = false) => {
  if (useSendBeacon) {
    // Some browsers do not support the 'keepalive' flag, for that reason, when
    // unloading the page, the method 'sendBeacon' should be used to unlock the map.
    return unlockUsingSendBeacon(action)
  } else {
    // Regular synchronous unlocks will continue to use a normal Fetch API request.
    return unlockUsingFetchAPI(action)
  }
}

const unlockUsingSendBeacon = async (action) => {
  const formData = new FormData()
  formData.append('user_action', action)
  const success = await navigator.sendBeacon(baseURL() + `/destroy?${tokenParam()}`, formData)

  return new ApiResponse({ success: success, error: success ? undefined : 'An error occurred while unlocking the map'  })
}

const unlockUsingFetchAPI = async (action) => {
  return await ApiClient.makeRequest({
    url: baseURL() + '/destroy',
    method: 'POST',
    headers: { ...tokenHeader().headers, 'Content-Type': 'application/json' },
    keepalive: true,
    body: JSON.stringify({ user_action: action })
  })
}

export {
  check,
  lock,
  unlock
}
