import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'options']

  connect() {
    this.toggleOptions()
  }

  toggleOptions() {
    if (this.buttonTarget.checked) {
      this.optionsTarget.classList.remove('hidden')
    } else {
      this.optionsTarget.classList.add('hidden')
    }
  }
}
