import * as Sentry from '@sentry/browser'
class ApiClient {
  static async makeRequest(config) {
    try {
      const request = new Request(
        config.url,
        {
          method: config.method,
          headers: config.headers,
          body: config.body,
          keepalive: config.keepalive
        }
      )

      const response = await fetch(request)
      const data = await response.json()

      if (response.ok) {
        return new ApiResponse({ success: true, data: data })
      } else {
        console.error(data.error)
        return new ApiResponse({ success: false, error: data.error })
      }
    } catch (error) {
      const errorMsg = `An unexpected error occurred while processing the request: "${error}"`
      console.error(errorMsg)
      Sentry.captureException(error)
      return new ApiResponse({ success: false, error: errorMsg })
    }
  }
}

class ApiResponse {
  constructor({ success, data, error }) {
    this.success = success
    this.data = data
    this.error = error
  }

  succeeded() {
    return this.success === true
  }

  errored() {
    return !this.succeeded()
  }
}

export {
  ApiClient,
  ApiResponse
}
