import { useMapStore, useLockStore } from '../stores'
import * as Geometries from './geometries'
import * as PerimeterSegments from './perimeter_segments'
import * as Defects from './defects'
import * as Inventories from './inventories'
import * as SurveyLocks from './survey_locks'
import * as UserToken from './user_token'

const tokenHeader = () => {
  const lockStore = useLockStore()
  return { headers: { Authorization: `Bearer ${lockStore.authToken}` } }
}

const tokenParam = () => {
  const lockStore = useLockStore()
  return `token=${lockStore.authToken}`
}

const surveyId = () => {
  const mapStore = useMapStore()
  return mapStore.survey.id
}

const Api = {
  Geometries,
  PerimeterSegments,
  Defects,
  Inventories,
  SurveyLocks,
  UserToken
}

export {
  tokenHeader,
  tokenParam,
  surveyId,
  Api
}
