import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.outsideClickClose.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click', this.outsideClickClose.bind(this))
  }

  outsideClickClose(event) {
    if (!this.element.getElementsByClassName('dui-modal-box')[0].contains(event.target)) {
      this.element.close()
    }
  }
}
