import { defineStore } from 'pinia'

import * as Getters from './getters.js'
import * as Actions from './actions.js'

export const useLegendStore = defineStore('legend', {
  state: () => ({
    legendItemTree: [],
    legendItemTypes: {
      totalCount: {
        perimeter: 0,
        defects: 0,
        inventories: 0,
        interiorPerimeter: 0,
      },
      checkedCount: {
        perimeter: 0,
        defects: 0,
        inventories: 0,
        interiorPerimeter: 0,
      },
      ids: {
        perimeter: [],
        defects: [],
        inventories: [],
        interiorPerimeter: [],
      }
    },
    legendItems: {},
    noVisibilityIds: [],
    showNestedItemsMap: {},
    urls: {},
    noGeometryIds: [],
    sectionVisibility: {}
  }),
  getters: {
    getShowNestedItemState: Getters.getShowNestedItemState
  },
  actions: {
    selectOnlyDeepLinkedFeature: Actions.selectOnlyDeepLinkedFeature,
    buildLegendItemTree: Actions.buildLegendItemTree,
    findAllGeometryIds: Actions.findAllGeometryIds,
    handleNoVisibilityIds: Actions.handleNoVisibilityIds
  }
})
