import { Controller } from "@hotwired/stimulus"

let progressInterval;

export default class extends Controller {
  static values = { progress: Number }

  connect() {
    if (this.progressValue < 100) this.updateProgress();
  }

  updateProgress() {
    progressInterval = setInterval(() => {
      window.location.reload();
    }, 500)
  }

  disconnect() {
    clearInterval(progressInterval);
  }
}
