import * as Turf from '@turf/turf'
import { BBox } from '../../../../geojson_utils'

const SidebarClickHandler = {}

SidebarClickHandler.load = function () {
  document.querySelectorAll('.work-order-title').forEach(element => {
    element.addEventListener('click', (event) => {
      const sections = this.map.getSource('perimeters')._data.features
      const sectionIds = event.currentTarget.dataset.sectionIds.split(',')

      const sectionsToCenter = sections.filter((section) => sectionIds.includes(section.id))
      if (sectionsToCenter.length == 0) return

      const bbox = new BBox(Turf.featureCollection(sectionsToCenter))
      bbox.extend(10)
      this.map.fitBounds(bbox.value, { animate: true })
    })
  })
}

export default SidebarClickHandler
