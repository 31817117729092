import * as Turf from '@turf/turf'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import _ from 'lodash'

const findClosestSegment = (clickedCoords, feature) => {
  let closestSegmentIndex = undefined
  let distanceToClosestSegment = Number.MAX_SAFE_INTEGER
  const clickedPoint = Turf.point(clickedCoords)

  for (let i = 0; i < feature.coordinates.length - 1; i += 1) {
    const vertex1 = feature.getCoordinate(i)
    const vertex2 = feature.getCoordinate(i + 1)
    const currentSegment = Turf.lineString([vertex1, vertex2])
    const currentDistance = Turf.pointToLineDistance(clickedPoint, currentSegment)
    if (currentDistance < distanceToClosestSegment) {
      closestSegmentIndex = i
      distanceToClosestSegment = currentDistance
    }
  }

  return closestSegmentIndex
}

const isRightClick = (e) => {
  return e.originalEvent.button === 2
}

const canDeleteFeature = (feature, coordPathsToDelete = []) => {
  const deletingWholeFeature = coordPathsToDelete.length === 0
  if (deletingWholeFeature) { return  false } // disabled for now

  const isPoint = feature.type === MapboxDraw.constants.geojsonTypes.POINT
  const isLineString = feature.type === MapboxDraw.constants.geojsonTypes.LINE_STRING
  const isPolygon = feature.type === MapboxDraw.constants.geojsonTypes.POLYGON

  if (isPoint) { return canDeletePoint(feature) }
  if (isLineString) { return canDeleteLineString(feature, coordPathsToDelete) }
  if (isPolygon) { return canDeletePolygon(feature, coordPathsToDelete) }
}

const canDeletePoint = (feature) => false

const canDeleteLineString = (feature, coordPathsToDelete) => {
  const firstVertexCoordPath = '0'
  const lastVertexCoordPath = (feature.coordinates.length - 1).toString()
  const isFirstVertexSelected = coordPathsToDelete.includes(firstVertexCoordPath)
  const isLastVertexSelected = coordPathsToDelete.includes(lastVertexCoordPath)
  return !isFirstVertexSelected && !isLastVertexSelected
}

const canDeletePolygon = (feature, coordPathsToDelete) => {
  // The coord paths of polygons have the form 'X.Y' where 'X' is the ring,
  // and 'Y' is the coord path within ring 'X'.
  const getRing = (path) => path.split('.')[0]
  const groupedByRing = _.groupBy(coordPathsToDelete, getRing)
  const canDelete = _.every(groupedByRing, (coordPaths, ring) => canDeleteRing(feature, ring, coordPaths))

  return canDelete
}

const canDeleteRing = (feature, ring, coordPathsToDelete) => {
  // Mapbox Draw doesn't duplicate the initial vertex of a ring during interaction with the feature.
  // Thus, 3 points are required minimum.
  const ringSize = feature.coordinates[ring].length
  const remainingVertices = ringSize - coordPathsToDelete.length
  return remainingVertices >= 3
}

const findConnectedPerimeters = function(perimetersIds, state) {
  const feature = state.feature
  const segmentsCount = perimetersIds.length
  const result = []

  if (segmentsCount == 1) {
    const isSectionClosed = _.isEqual(_.first(feature.coordinates), _.last(feature.coordinates))

    if(isSectionClosed) {
      // drag opposite vertex only if it's connected (section is not broken)
      const firstVertexPath = 0
      const lastVertexPath = (feature.coordinates.length - 1)
      const draggingFirstVertex = state.selectedCoordPaths.includes('0')
      result.push({
        segment: feature,
        connectedCoordPath: draggingFirstVertex ? lastVertexPath : firstVertexPath,
        draggedCoordPath: draggingFirstVertex ? firstVertexPath : lastVertexPath
      })
    }
  } else {
    const indexOfSelectedFeature = perimetersIds.indexOf(feature.id)
    const indexOfPrevSegment = indexOfSelectedFeature == 0 ? segmentsCount - 1 : indexOfSelectedFeature - 1
    const indexOfNextSegment = indexOfSelectedFeature == segmentsCount - 1 ? 0 : indexOfSelectedFeature + 1
    const matchingSegmentsIds = [perimetersIds[indexOfPrevSegment]]
    if (indexOfPrevSegment != indexOfNextSegment) { matchingSegmentsIds.push(perimetersIds[indexOfNextSegment]) }

    state.selectedCoordPaths.forEach((selectedCoordPath) => {
      const coordsBeingDragged = feature.getCoordinate(selectedCoordPath)

      matchingSegmentsIds.map((id) => {
        const matchingSegment = this.getFeature(id)
        const matchingSegmentFirstCoord = matchingSegment.getCoordinate(0)
        const matchingSegmentLastCoord = matchingSegment.getCoordinate(matchingSegment.coordinates.length - 1)

        if (_.isEqual(coordsBeingDragged, matchingSegmentFirstCoord)) {
          result.push({
            segment: matchingSegment,
            connectedCoordPath: 0,
            draggedCoordPath: parseInt(selectedCoordPath)
          })
        } else if (_.isEqual(coordsBeingDragged, matchingSegmentLastCoord)) {
          result.push({
            segment: matchingSegment,
            connectedCoordPath: matchingSegment.coordinates.length - 1,
            draggedCoordPath: parseInt(selectedCoordPath)
          })
        }
      })
    })
  }

  return result
}

const findConnectedInteriorPerimeters = function(interiorPerimeters, state) {
  const findMatchingCoordIndex = (coord, selectedCoords) => _.findIndex(selectedCoords, (selected) => _.isEqual(coord, selected))
  const feature = state.feature
  const selectedCoords = state.selectedCoordPaths.map(path => feature.getCoordinate(path))
  const sameGroupInteriorPerimeters = interiorPerimeters
    .filter((p) => p.properties.group_id == feature.properties.group_id)
  const result = []

  sameGroupInteriorPerimeters.forEach(({ id }, index) => {
    const interiorPerimeter = this.getFeature(id) // get the latest version of the interior perimeter
    const isCurrentPerimeterSelected = feature.id === interiorPerimeter.id

    for (var coordPath = 0; coordPath < interiorPerimeter.coordinates.length; coordPath++) {
      const coord = interiorPerimeter.getCoordinate(coordPath)
      const isCurrentVertexSelected = isCurrentPerimeterSelected && state.selectedCoordPaths.includes(coordPath.toString())

      // no need to add the vertex to connectedSegments since it's being explicitly dragged by the user.
      if (isCurrentVertexSelected) { continue }

      const matchingCoordIndex = findMatchingCoordIndex(coord, selectedCoords)
      if (matchingCoordIndex != -1) {
        result.push({
          segment: interiorPerimeter,
          connectedCoordPath: coordPath,
          draggedCoordPath: parseInt(state.selectedCoordPaths[matchingCoordIndex])
        })
      }
    }
  })

  return result
}

export {
  findClosestSegment,
  isRightClick,
  canDeletePoint,
  canDeleteLineString,
  canDeleteRing,
  canDeletePolygon,
  canDeleteFeature,
  findConnectedPerimeters,
  findConnectedInteriorPerimeters
}
