import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  togglePropertiesOrTagsSelect(event) {
    const propertiesSelect = document.getElementById('properties-select')
    const tagsSelect = document.getElementById('tags-select')

    switch (event.target.value) {
      case 'properties':
        propertiesSelect.style.display = 'block'
        tagsSelect.style.display = 'none'
        break;
      case 'tags':
        propertiesSelect.style.display = 'none'
        tagsSelect.style.display = 'block'
        break;
      case 'all_properties':
        propertiesSelect.style.display = 'none'
        tagsSelect.style.display = 'none'
        break;
      default:
        propertiesSelect.style.display = 'block'
        tagsSelect.style.display = 'none'
    }
  }
}
