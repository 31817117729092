import { Controller } from "@hotwired/stimulus"
import L from 'leaflet';

export default class extends Controller {
  static targets = ['container'];

  static values = {
    accessToken: String,
    satellite: Boolean,
    zoomable: Boolean,
    perimeterColor: String,
    highlightColor: String,
    defectPhotoUrls: Object
  }

  connect() {
    const zoomable = this.zoomableValue;
    const satellite = this.satelliteValue;

    const map = L.map(
      this.containerTarget, {
        attributionControl: false,
        boxZoom: false,
        doubleClickZoom: zoomable,
        dragging: zoomable,
        scrollWheelZoom: zoomable,
        zoomControl: zoomable,
      },
    );

    if (satellite) {
      L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        maxZoom: 22,
        maxNativeZoom: 19,
        accessToken: this.accessTokenValue,
        id: 'satellite-streets-v11'
      }).addTo(map);
    }

    const feature = this.buildOutlineMap(map);
  }

  disconnect() {
    this.map.remove();
  }

  buildOutlineMap(map) {
    const segments = JSON.parse(this.element.dataset.segments);
    const segmentIds = JSON.parse(this.element.dataset.segmentIds);
    const highlightColor = this.highlightColorValue;
    const perimeterColor = this.perimeterColorValue;
    const defectPhotoUrls = this.defectPhotoUrlsValue;

    const geojsonMarkerOptions = {
      radius: 1,
      fillColor: (perimeterColor || "gray"),
      color: (perimeterColor || "gray"),
      weight: 1,
      opacity: 1,
      fillOpacity: 1
    };

    var baseSegments = segments.features.filter(segment => !segmentIds.includes(segment.properties.id));
    var highlightedSegments = segments.features.filter(segment => segmentIds.includes(segment.properties.id));
    var featureOptions = {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, geojsonMarkerOptions);
      },
      style: function(feature) {
        const color = (segmentIds.includes(feature.properties.id)) ? (highlightColor || "gray") : (perimeterColor || "lightgray");
        return { fill: false, stroke: true, color: color, weight: 4, zIndex: 1000 };
      },
      onEachFeature: function(feature, layer) {
        if (feature.properties.name === undefined) return;

        let photoUrls = defectPhotoUrls[feature.properties.id] || [];
        let imageTags = photoUrls.map(url => `<img src="${url}" width="100%" />`);
        return layer.bindPopup("id: " + feature.properties.id + "<br />" + "name: " + feature.properties.name + "<br />" + imageTags.join("<br />"));
      }
    };

    let baseSegmentLayer = L.geoJson({ type: "FeatureCollection", features: baseSegments }, featureOptions).addTo(map);
    let highlightedSegmentLayer = L.geoJson({ type: "FeatureCollection", features: highlightedSegments }, featureOptions).addTo(map)

    map.fitBounds(baseSegmentLayer.getBounds().extend(highlightedSegmentLayer.getBounds()));
  }
}
