<div id="js-map-left-panel" class="absolute top-2 left-2 z-1" v-if="leftPanelComponent">
  <button
    v-show="!visible"
    @click="toggleVisibility()"
    class="shadow bg-white hover:bg-gray-200 border-black text-black text-xs md:text-sm font-semibold py-1 px-2 md:px-4 rounded-full cursor-pointer"
  >
    {{ leftPanelTitle }}
  </button>
  <div v-show="visible" class="bg-white shadow-md mx-2 px-2 rounded-lg flex flex-col h-96 w-72">
    <div class="bg-white sticky top-0 py-2 z-30">
      <span class="flex justify-between items-center">
        <p class="text-sm font-semibold">
          {{ leftPanelTitle }}
        </p>
        <div id="close-panel" class="cursor-pointer h-4 w-4 ml-auto mr-0 text-gray-500">
          <XMarkIcon @click="toggleVisibility()" />
        </div>
      </span>
      <hr class="my-1" />
    </div>
    <component :is="leftPanelComponent"></component>
  </div>
  <div v-if="leftPanelSubMenuComponent">
    <component :is="leftPanelSubMenuComponent" v-bind="leftPanelSubMenuProps"></component>
  </div>
</div>
