import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-carousel"
export default class extends Controller {
  static targets = ['item']

  static values = {
    toggleableClass: {
      type: String,
      default: 'inactive'
    }
  }

  connect() {
    // Not calling this.showCurrentItem() to prevent jump when JS loads
    // view adds inactive class on initial render
    this.currentIndex = 0
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.itemTargets.length
    this.showCurrentItem()
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.itemTargets.length) % this.itemTargets.length
    this.showCurrentItem()
  }

  showCurrentItem() {
    this.itemTargets.forEach((el, index) => {
      el.classList.toggle(this.toggleableClassValue, index !== this.currentIndex)
    })
  }
}
