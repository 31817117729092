import { mapStores } from 'pinia'

import { useModeStore, useLockStore } from '../../stores'

export default {
  name: 'SaveAndContinueButton',
  props: {
    saveCallback: Function
  },
  computed: {
    ...mapStores(useModeStore, useLockStore),
  },
  methods: {
    handleClick: async function() {
      this.modeStore.startLoading()
      const confirmed = await this.lockStore.confirmSave()
      if (!confirmed) {
        if (confirmed !== null) { this.modeStore.stopLoading() } // Not a fetch error
        return
      }
      await this.saveCallback()
      this.modeStore.stopLoading()
      return true
    }
  },
  template: `<button @click='handleClick()' class='whitespace-nowrap black-button'>Save</button>`
}
