import * as Turf from '@turf/turf'
import ZLayers from '../../../z_layers'
import colors from '../../../colors'

const id = 'perimeters'

const Perimeters = {}

Perimeters.load = function() {
  const perimeters = this.sections.flatMap(({ perimeters }) => perimeters).map(({ geometry }) => geometry.data)

  this.map.addSource(id, {
    'data': Turf.featureCollection(perimeters),
    'type': 'geojson'
  })

  this.map.addLayer({
    'id': id,
    'type': 'line',
    'source': id,
    'paint': {
      'line-color': colors.neon.BLUE,
      'line-width': 2
    }
  }, ZLayers.myPosition.call(this, id))
}

export default Perimeters
