<div id="js-map-right-panel" class="absolute top-8 right-2 z-1" v-if="rightPanelComponent">
  <div class="bg-white shadow-md mx-2 my-1 px-2 rounded-lg flex flex-col">
    <div v-if="rightPanelTitle" class="bg-white sticky top-0 py-2 z-1">
      <p class="text-sm font-semibold text-center">
        {{ rightPanelTitle }}
      </p>
      <hr class="my-1" />
    </div>
    <component :is="rightPanelComponent"></component>
  </div>
</div>
