import { mapActions } from 'pinia'
import { useModeStore } from '../../../../stores'

export default {
  name: 'DeletingFeatureError',
  methods: {
    ...mapActions(useModeStore, ['clearError']),
    hideErrorModal() { this.clearError('deletingFeature') }
  },
  template: `
    <div class="flex flex-col items-center justify-center">
      <p class="text-white">An error occurred while deleting the feature.</p>
      <button
       data-test="closeButton"
        @click="hideErrorModal()"
        class="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4 cursor-pointer"
      >
        Close
      </button>
    </div>
  `
}
