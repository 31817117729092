import _ from 'lodash'

import LegendBuilder from '../../plugins/legend/legend_builder.js'
import { useMapStore, useGeometryStore } from '../'

const buildLegendItemTree = function () {
  this.legendItemTypes['checkedCount'] = {
    perimeter: 0,
    defects: 0,
    inventories: 0,
    interiorPerimeter: 0
  }
  this.legendItemTypes['totalCount'] = {
    perimeter: 0,
    defects: 0,
    inventories: 0,
    interiorPerimeter: 0
  },
  this.legendItemTypes['ids'] = {
    perimeter: [],
    defects: [],
    inventories: [],
    interiorPerimeter: []
  }
  const legendBuilder = new LegendBuilder
  this.legendItemTree = legendBuilder.buildLegendItemTree()
  this.legendItems = buildLegendItems(this.legendItemTree)
  this.legendItemTypes['entities'] = legendBuilder.buildTypeEntities()
}

const buildLegendItems = function (items, index = {}) {
  items.forEach((item) => {
    index[item.id] = item
    if (item.items && item.items.length > 0) {
      buildLegendItems(item.items, index)
    }
  })
  return index
}

const findAllGeometryIds = function(data) {
  if (data.type === "geometry") return [data.id];
  const nestedGeometryIds = _.flatMap(data.items, (item) => findAllGeometryIds(item));
  return nestedGeometryIds
}

const handleNoVisibilityIds = function (value, ids) {
  if (value) {
    this.noVisibilityIds = this.noVisibilityIds.filter(id => !ids.includes(id))
  } else {
    this.noVisibilityIds = this.noVisibilityIds.concat(ids)
  }
}

const selectOnlyDeepLinkedFeature = function () {
  const geometryStore = useGeometryStore()
  const mapStore = useMapStore()

  const geometryIdsToHide = geometryStore.allFeatureIds.filter(id => id !== mapStore.deepLinkedFeature.id)
  const idsToHide = geometryIdsToHide.concat(geometryStore.recordIdsWithoutGeometry)
  this.noVisibilityIds = idsToHide
}

export {
  buildLegendItemTree,
  findAllGeometryIds,
  handleNoVisibilityIds,
  selectOnlyDeepLinkedFeature
}
