import { mapStores } from 'pinia'

import { useModeStore } from '../../../../stores'

export default {
  name: 'MoveSectionLink',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useModeStore),
    isSection() { return this.item.type === 'section' },
    canMoveSection() { return this.modeStore.inViewMode }
  },
  methods: {
    enterBulkMove() {
      this.$parent.close()
      this.modeStore.enterBulkMoveMode(this.item.id)
    }
  },
  template: `
    <li v-if="isSection">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !canMoveSection,
          'hover:underline': canMoveSection
        }"
        @click="enterBulkMove">Bulk Move: {{item.label}}
      </a>
    </li>
  `
}
