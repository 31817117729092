import * as Turf from '@turf/turf'
import { watch } from 'vue'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore, useLegendStore } from '../stores'
import ZLayers from '../../../../z_layers'
import Vector from '../vector'
export default class PerimeterSegmentLabels {
  constructor() {
    this.sourceId = 'perimeter-segment-labels' // used as label id too
    this.mapStore = useMapStore()
    this.geometryStore = useGeometryStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.config = {
      lengthsType: 'full',
      textColor: this.mapStore.colors.line.white,
      textHaloColor: this.mapStore.colors.line.black,
      showLengths: true,
      showTypes: true
    }
    this.states = {
      SHOW_ALL: 'show_all',
      SHOW_LENGTHS: 'show_lengths',
      SHOW_TYPES: 'show_types',
      SHOW_NONE: 'show_none'
    }
    this.currentState = this.states.SHOW_ALL
    this.transitions = {
      HideLengths: {
        [this.states.SHOW_ALL]: () => { this.currentState = this.states.SHOW_TYPES; this.updateText() },
        [this.states.SHOW_LENGTHS]: () => { this.currentState = this.states.SHOW_NONE; this.toggleLayer() }
      },
      HideTypes: {
        [this.states.SHOW_ALL]: () => { this.currentState = this.states.SHOW_LENGTHS; this.updateText() },
        [this.states.SHOW_TYPES]: () => { this.currentState = this.states.SHOW_NONE; this.toggleLayer() }
      },
      ShowLengths: {
        [this.states.SHOW_NONE]: () => { this.currentState = this.states.SHOW_LENGTHS; this.toggleLayer(); this.updateText() },
        [this.states.SHOW_TYPES]: () => { this.currentState = this.states.SHOW_ALL; this.updateText() }
      },
      ShowTypes: {
        [this.states.SHOW_NONE]: () => { this.currentState = this.states.SHOW_TYPES; this.toggleLayer(); this.updateText() },
        [this.states.SHOW_LENGTHS]: () => { this.currentState = this.states.SHOW_ALL; this.updateText() }
      }
    }
  }

  initialize() {
    this.mapStore.map.addSource(this.sourceId, {
      'type': 'geojson',
      'data': this.initLabels(),
      'promoteId': 'id'
    })

    this.mapStore.map.addLayer({
      'id': this.sourceId,
      'type': 'symbol',
      'source': this.sourceId,
      'layout': {
        'text-rotation-alignment': 'viewport',
        'text-radial-offset': 0.2,
        'text-anchor': ['get', 'anchor'],
        'text-field': ['get', this.currentState],
        'text-allow-overlap': true,
        'visibility': (this.currentState === this.states.SHOW_NONE) ? 'none' : 'visible'
      },
      'paint': {
        'text-color': this.config.textColor,
        'text-halo-color': this.config.textHaloColor,
        'text-halo-width': 0.5
      },
      'filter': ['==', 'visible', true]
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId))

    this.subscribeToLegendStore()
  }

  modeChanged() {
    if (this.modeStore.inBulkMoveMode || this.modeStore.inPlacementMode) {
      return this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', 'none')
    } else {
      this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', this.currentState === this.states.SHOW_NONE ? 'none' : 'visible')
      this.refresh()
    }
  }

  initLabels() {
    const labels = []

    _.forEach(this.geometryStore.sections, (section) => {

      [...section.clockwisePerimeters, ...section.interiorPerimeters].forEach(perimeter => {
        const fullCenter = this.fullCenter(perimeter)
        const partialCenters = this.partialCenters(perimeter)

        labels.push(fullCenter, ...partialCenters)
      })
    })

    this.setVisibleProperty(labels)

    return Turf.featureCollection(labels)
  }

  refresh() {
    this.mapStore.setViewSource(this.sourceId, this.initLabels())
  }

  updateText() {
    this.mapStore.map.setLayoutProperty(this.sourceId, 'text-field', ['get', this.currentState])
  }

  recalculate(feature) {
    const section = this.geometryStore.sections[feature.properties.section_id]

    const perimeters = [...section.updatedClockwisePerimeters, ...section.updatedInteriorPerimeters]
    const perimeter = perimeters.find(p => p.id == feature.id)
    perimeter.properties.classification_name = feature.properties.classification_name

    const labels = this.mapStore.map.getSource(this.sourceId)._data.features
    const oldFullCenter = _.remove(labels, f => feature.id == f.id)[0]
    const oldPartialCenters = _.remove(labels, f => f.properties.original_perimeter_id == feature.id)

    const newFullCenter = this.fullCenter(perimeter)
    const newPartialCenters = this.partialCenters(perimeter)

    const isFullVisible = oldFullCenter.properties.visible
    const arePartialsVisible = oldPartialCenters[0].properties.visible

    newFullCenter.properties.visible = isFullVisible
    newPartialCenters.forEach(newPartialCenter => newPartialCenter.properties.visible = arePartialsVisible)

    labels.push(newFullCenter)
    newPartialCenters.forEach(newPartialCenter => labels.push(newPartialCenter))

    this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(labels))
  }

  subscribeToLegendStore() {
    watch(
      () => this.legendStore.noVisibilityIds,
      (newNoVisibilityIds) => {
        const labels = this.mapStore.map.getSource(this.sourceId)._data.features
        this.setVisibleProperty(labels)
        this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(labels))
      }
    )
  }

  setVisibleProperty(labels) {
    const labelsById = _.keyBy(labels, 'id')
    const labelsByOriginalPerimeterId = _.groupBy(labels, 'properties.original_perimeter_id')

    _.forEach(_.values(labelsById), f => {
      f.properties.visible = f.properties.type == this.config.lengthsType
    })
    _.forEach(this.legendStore.noVisibilityIds, id => {
      if (labelsById[id]) { (labelsById[id].properties.visible = false) }
      if (labelsByOriginalPerimeterId[id]) {
        labelsByOriginalPerimeterId[id].forEach(f => (f.properties.visible = false))
      }
    })
  }

  addControls() {
    const folder = this.mapStore.menu.folders.find(folder => folder._title == "Perimeter Segments")

    const lengthsController = folder.add(this.config, 'lengthsType', ['full', 'partial']).name('lengths')
    const colorController = folder.add(this.config, 'textColor', this.mapStore.colors.line).name('label color')
    const lengthVisibilityController = folder.add(this.config, 'showLengths').name('show length in labels')
    const typeVisibilityController = folder.add(this.config, 'showTypes').name('show type in labels')

    lengthsController.onChange(value => {
      const labels = this.mapStore.map.getSource(this.sourceId)._data.features
      this.setVisibleProperty(labels)
      this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(labels))
    })

    colorController.onChange(value => {
      this.mapStore.map.setPaintProperty(this.sourceId, 'text-color', this.mapStore.colorName(value))
    })

    lengthVisibilityController.onChange(show => {
      if (show) { this.transitions.ShowLengths[this.currentState]() }
      if (!show) { this.transitions.HideLengths[this.currentState]() }
    })

    typeVisibilityController.onChange(show => {
      if (show) { this.transitions.ShowTypes[this.currentState]() }
      if (!show) { this.transitions.HideTypes[this.currentState]() }
    })
  }

  toggleLayer() {
    const isLayerVisible = this.mapStore.map.getLayoutProperty(this.sourceId, 'visibility') === 'visible'
    this.mapStore.map.setLayoutProperty(this.sourceId, 'visibility', isLayerVisible ? 'none' : 'visible')
  }

  lengthInMiles(feature) {
    return Turf.length(feature.geometry, { units: 'miles' })
  }

  lengthInFeet(feature) {
    const miles = this.lengthInMiles(feature)
    const feet = Turf.convertLength(miles, 'miles', 'feet')
    return Math.ceil(feet)
  }

  fullCenter(feature) {
    // The center of the LineString is calculated by traversing it to half of its length from one of the starting vertices
    const center = Turf.along(feature, this.lengthInMiles(feature) / 2, { units: 'miles' })
    const segmentIndexContainingTheCenter = Turf.nearestPointOnLine(feature, center).properties.index
    const start = feature.geometry.coordinates[segmentIndexContainingTheCenter]
    const end = feature.geometry.coordinates[segmentIndexContainingTheCenter + 1]

    // Store the id in the properties too so it can be promoted with the option promoteId.
    // By doing this, non-numerical ids will be returned by queryRenderedFeatures (necessary in the tests)
    center.id = feature.id
    center.properties = {
      type: 'full',
      anchor: this.anchor(start, end),
      ...this.texts(feature),
      id: feature.id
    }

    return center
  }

  partialCenters(feature) {
    const partialCenters = []
    const coords = feature.geometry.coordinates

    for (let i = 0; i < coords.length - 1; i++) {
      const start = coords[i]
      const end = coords[i+1]
      const segment = Turf.lineString([start, end], { classification_name: feature.properties.classification_name })
      const center = Turf.midpoint(start, end)

      center.id = `${feature.id}-${i}`
      center.properties = {
        original_perimeter_id: feature.id,
        type: 'partial',
        id: center.id,
        anchor: this.anchor(Turf.toMercator(start), Turf.toMercator(end)),
        ...this.texts(segment)
      }
      partialCenters.push(center)
    }
    return partialCenters
  }

  anchor(start, end) {
    // Direction vector
    const dx = end[0] - start[0]
    const dy = end[1] - start[1]

    // Perpendicular vector (clockwise rotation)
    const perpendicularVector = [dy, -dx]

    const yAxis = [0, 1]

    let angle = Vector.angleBetweenVectors(perpendicularVector, yAxis)

    // Ensure angle is clockwise
    if (Vector.crossProduct(yAxis, perpendicularVector) > 0) {
      angle = 2 * Math.PI - angle
    }

    const angleStep = Math.PI / 8
    if (angle >= 0 && angle < angleStep) { return "bottom" }
    if (angle >= angleStep && angle < 3 * angleStep) { return "bottom-left" }
    if (angle >= 3 * angleStep && angle < 5 * angleStep) { return "left" }
    if (angle >= 5 * angleStep && angle < 7 * angleStep) { return "top-left" }
    if (angle >= 7 * angleStep && angle < 9 * angleStep) { return "top" }
    if (angle >= 9 * angleStep && angle < 11 * angleStep) { return "top-right" }
    if (angle >= 11 * angleStep && angle < 13 * angleStep) { return "right" }
    if (angle >= 13 * angleStep && angle < 15 * angleStep) { return "bottom-right" }
    return "bottom"
  }

  texts(feature) {
    const length = this.lengthInFeet(feature)
    return {
      [this.states.SHOW_ALL]: `${feature.properties.classification_name} ${length}'`,
      [this.states.SHOW_LENGTHS]: `${length}'`,
      [this.states.SHOW_TYPES]: feature.properties.classification_name
    }
  }
}
