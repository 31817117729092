import _ from 'lodash'
import * as Turf from '@turf/turf'
import { constants } from '../../constants'

import { useMapStore, useLegendStore } from '../'

const allFeatures = (state) => {
  return state['types']
    .map(type => state[_.camelCase(type)])
    .map(geometry => Object.values(geometry))
    .flat()
}

const allFeatureIds = (state) => {
  return state.allFeatures
    .map((f) => f.id)
}

const featureById = (state) => {
  return (id) => _.find(state.allFeatures, { id })
}

const features = (state) => {
  return (type) => _.map(state[_.camelCase(type)], 'data')
}

const featureIds = (state) => {
  return (type, { includeEmptyGeometry = false } = {}) => {
    const filterCondition = includeEmptyGeometry
      ? () => true
      : (item) => item.data?.geometry?.coordinates?.length >= 1

    return _.map(_.filter(state[_.camelCase(type)], filterCondition), 'data.id')
  }
}

const recordIdsWithoutGeometry = (state) => {
  return state.allFeatures
    .filter((f) => f.id == null)
    .map((f) => f.model_id)
}

const featuresWithExtendedProps = (state) => {
  return (type, { includeEmptyGeometry = false } = {}) => {
    const mapStore = useMapStore()
    const legendStore = useLegendStore()

    const layer = mapStore.layers[type]
    const color = mapStore.colors.line[layer.color]
    return _.chain(state[_.camelCase(type)]).map((feature) => {
      if (!includeEmptyGeometry && !(feature?.data?.geometry?.coordinates.length >= 1)) { return null }

      const isVisible = !legendStore.noVisibilityIds.includes(feature.id || feature.model_id) && layer.visible
      const properties = {
        section_id: feature.section_id,
        section_code: feature.section_code,
        classification_name: feature.classification_name,
        classification_target: feature.classification_target,
        classification_id: feature.classification_id,
        parent_classification_id: feature.parent_classification_id,
        parent_classification_name: feature.parent_classification_name,
        visible: isVisible,
        color: color,
        line_width: layer.lineWidth,
        point_radius: layer.pointRadius,
        vertex_radius: layer.vertexRadius,
        feature_id: feature.id,
        sort_order: feature.sort_order,
        group_id: feature.group_id,
        record_id: feature.record_id
      }
      return _.merge({}, feature.data, { properties: properties, featureId: feature.id, recordId: feature.model_id })
    }).compact().value()
  }
}

const typeOf = (state) => {
  return (featureId) => {
    if (featureId in state.defects) { return constants.domainTypes.DEFECT }
    if (featureId in state.inventories) { return constants.domainTypes.INVENTORY }
    if (featureId in state.perimeterSegments) {
      const target = state.perimeterSegments[featureId].classification_target
      if ([constants.targets.PERIMETER, constants.targets.INTERIOR_PERIMETER].includes(target)) {
        return target
      }
    }
  }
}

const featureCollection = (state) => (type, { includeEmptyGeometry = false } = {}) => {
  return Turf.featureCollection(state.featuresWithExtendedProps(type, { includeEmptyGeometry }))
}

const hasUnsavedChanges = (state) => Object.keys(state.changedFeatures).length > 0

const sectionCenters = (state) => _.map(_.filter(state.sections, section => section.center), 'center')

const sectionCenterCollection = (state) => Turf.featureCollection(sectionCenters(state))

const getConnectedSections = (state) => {
  return _.filter(state.sections, section => section.isConnected)
}

export {
  allFeatures,
  allFeatureIds,
  featureById,
  features,
  featureIds,
  recordIdsWithoutGeometry,
  featuresWithExtendedProps,
  typeOf,
  featureCollection,
  hasUnsavedChanges,
  sectionCenters,
  sectionCenterCollection,
  getConnectedSections
}
