import "./controllers"
import "./extensions"
import * as ActiveStorage from "@rails/activestorage"
import * as Sentry from "@sentry/browser"

ActiveStorage.start()
require("@rails/ujs").start()

const dsn = document.head.querySelector("meta[name=JS_SENTRY_URL]").content
const ignoredErrors = [/the user aborted a request/i]
Sentry.init({
  dsn: dsn,
  ignoreErrors: ignoredErrors
})
