import { tokenHeader, surveyId } from '.'
import { ApiClient } from './api_client'

const baseURL = (id) => `/admin/api/surveys/${surveyId()}/perimeter_segments${id ? `/${id}` : ''}`

const addOptions = async ({ preselectedSectionId, target }) => {
  const encodedSectionId = preselectedSectionId ? `&preselected_section_id=${encodeURIComponent(preselectedSectionId)}` : ''
  const encodedTarget = encodeURIComponent(target)
  return await ApiClient.makeRequest({
    url: `${baseURL()}/new?target=${encodedTarget}${encodedSectionId}`,
    ...tokenHeader()
  })
}

const upsert = async (sectionId, target, closed, perimeterSegments) => {
  const encodedSectionId = encodeURIComponent(sectionId)
  const encodedTarget = encodeURIComponent(target)
  const encodedClosed = encodeURIComponent(closed)
  return await ApiClient.makeRequest({
    url: `${baseURL()}/?section_id=${encodedSectionId}&target=${encodedTarget}&closed=${encodedClosed}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...tokenHeader().headers },
    body: JSON.stringify({ perimeter_segments: perimeterSegments })
  })
}

const changeTypeOptions = async (perimeterSegmentId) => {
  return await ApiClient.makeRequest({
    url: baseURL(perimeterSegmentId) + '/edit',
    ...tokenHeader()
  })
}

const update = async (perimeterSegmentId, selectedClassificationId) => {
  return await ApiClient.makeRequest({
    url: baseURL(perimeterSegmentId),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...tokenHeader().headers },
    body: JSON.stringify({ classification_id: selectedClassificationId })
  })
}

const destroy = async ({ id }) => {
  return await ApiClient.makeRequest({
    url: baseURL(id),
    method: 'DELETE',
    ...tokenHeader()
  })
}

const destroyAll = async ({ sectionId, target, groupId }) => {
  const encodedSectionId = encodeURIComponent(sectionId)
  const encodedTarget = encodeURIComponent(target)
  let url = `${baseURL()}/destroy_all?section_id=${encodedSectionId}&target=${encodedTarget}`
  if (groupId) { url += `&group_id=${encodeURIComponent(groupId)}` }
  return await ApiClient.makeRequest({
    url: url,
    method: 'DELETE',
    ...tokenHeader()
  })
}

export {
  addOptions,
  upsert,
  changeTypeOptions,
  update,
  destroyAll,
  destroy
}
