import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts"

// Connects to data-controller="high-chart"
export default class extends Controller {
  static values = {
    chartType: String,
    chartTitle: String,
    yAxisTitle: String,
    noTooltipSeriesName: String,
    plotOptions: Object,
    legendOptions: Object,
    xAxisCategories: Array,
    series: Array,
    formatCurrency: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    const _this = this
    const isStackedColumnChart = 'column' in this.plotOptionsValue
      && 'stacking' in this.plotOptionsValue.column

    Highcharts.chart(this.element, {
      chart: {
        type: this.chartTypeValue
      },
      title: {
        text: this.chartTitleValue,
        align: 'center'
      },
      xAxis: {
        categories: this.xAxisCategoriesValue
      },
      yAxis: [
        {
          title: {
            text: this.yAxisTitleValue
          },
          minorTickInterval: "auto",
          labels: {
            formatter: function () {
              const formattedValue = _this.numberFormat(this.value)
              return _this.formatCurrencyValue ? `$${formattedValue}` : formattedValue
            }
          }
        },
        { title: { text: null }, labels: { enabled: false }, min: 0, max: 100 },
      ],
      plotOptions: this.plotOptionsValue,
      legend: this.legendOptionsValue,
      series: this.seriesValue,
      tooltip: {
        formatter: function () {
          if (this.series.name === _this.noTooltipSeriesNameValue) return false

          const formattedYValue = _this.formatCurrencyValue
            ? `$${_this.numberFormat(this.y)}`
            : _this.numberFormat(this.y)

          if (isStackedColumnChart) {
            const formattedTotalValue = _this.formatCurrencyValue
              ? `$${_this.numberFormat(this.point.stackTotal)}`
              : _this.numberFormat(this.point.stackTotal)

            return '<b>' + this.key + '</b><br/>' +
              this.series.name + ': ' + formattedYValue + '<br/>' +
              'Total: ' + formattedTotalValue
          } else {
            return '<b>' + this.key + '</b><br/>' + this.series.name + ': ' + formattedYValue
          }
        }
      },
      accessibility: { enabled: false },
      credits: { enabled: false }
    })
  }
  numberFormat(value) {
    return Highcharts.numberFormat(value, 0, ',', ',')
  }
}
