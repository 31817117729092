import _ from 'lodash'
import { mapStores } from 'pinia'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { Api } from '../../api'
import { useModeStore, useLockStore, useGeometryStore, useMapStore } from '../../stores'

export default {
  name: 'DeleteFeatureButton',
  props: {
    feature: Object
  },
  computed: {
    ...mapStores(useModeStore, useLockStore, useGeometryStore, useMapStore),
    canDelete() {
      if(!this.modeStore.inViewMode) { return false }

      if (this.feature.type === 'Perimeter') {
        return !this.geometryStore.sections[this.feature.sectionId].isUnclosed &&
          !this.geometryStore.sections[this.feature.sectionId].isOutOfOrder
      }

      return true
    },
    deleteError() { return this.modeStore.deleteFeatureError }
  },
  methods: {
    async confirmDelete() {
      const confirmDelete = `Do you want to delete "${this.feature.type}: ${this.feature.cfName}"`
        + ', and its associated records and geometry?'
      const deleteConfirmed = confirm(confirmDelete)
      if (!deleteConfirmed) { return }

      return true
    },
    async refreshMap() {
      this.$parent.closePopup()
      const result = await this.geometryStore.refreshFeatures(this.modeStore.initializeError)
      if (!result) { return false }

      _.keys(this.mapStore.layers).forEach(name => {
        this.mapStore.removeViewLayers(name)
        this.mapStore.removeViewSource(name)
      })

      _.keys(this.mapStore.layers).sort().forEach((name) => {
        this.mapStore.addViewSource(name)
        this.mapStore.addViewLayers(name)
      })

      this.mapStore.plugins.perimeterSegmentLabels.refresh()

      return true
    },
    async handleClick() {
      const confirmed = await this.confirmDelete()
      if (!confirmed) { return }

      const typeToApiNamespace = {
        'Perimeter': Api.PerimeterSegments,
        'Interior Perimeter': Api.PerimeterSegments,
        'Inventory': Api.Inventories,
        'Defect': Api.Defects
      }

      const result = await this.geometryStore.deleteWithLocking({
        id: this.feature.recordId,
        deleteFunction: typeToApiNamespace[this.feature.type].destroy,
        postDeleteCallback: this.refreshMap
      })
      if (!result) { return }

      return true
    }
  },
  components: { TrashIcon },
  template: `
  <span :id="feature.id" class="px-1.5">
    <TrashIcon
      class="h-4 w-4 inline"
      :class="{ 'cursor-pointer': this.canDelete, 'text-gray-500 cursor-not-allowed': !this.canDelete }"
      @click='this.canDelete && handleClick()'>
    </TrashIcon>
  </span>
  `
}
