const getShowNestedItemState = (state) => (id, initialState) => {
  if (state.showNestedItemsMap[id] === undefined) {
    state.showNestedItemsMap[id] = initialState
    return initialState
  } else {
    return state.showNestedItemsMap[id]
  }
}

export {
  getShowNestedItemState
}
