import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const selectInput = this.element;
    const plugins = selectInput.multiple ? ['dropdown_input', 'remove_button'] : ['dropdown_input'];
    const options = {
      allowEmptyOption: true,
      plugins: plugins,
      maxOptions: null,
      onItemAdd: function () {
        this.setTextboxValue('');
        this.refreshOptions();
      }
    };
    new TomSelect(selectInput, options);
  }

  redirectAndCloseDetail(event) {
    const selectedValue = event.target.value
    const detailsElement = event.target.closest('details')

    if (selectedValue) {
      detailsElement?.removeAttribute('open')
      window.location.href = selectedValue
    }
  }
}
