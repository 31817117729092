import ZLayers from '../../../z_layers'

const id = 'custom-satellite'

const tileUrl = (key) => `https://atlas.microsoft.com/map/imagery/png?subscription-key=${key}&api-version=1.0&style=satellite&zoom={z}&x={x}&y={y}`

const Azure = {}

Azure.load = function() {
  this.map.addSource('azure', {
    'type': 'raster',
    'tiles': [tileUrl(this.azureKey)],
    'tileSize': 256,
    'attribution': '© Microsoft, Azure Maps'
  })

  this.map.addLayer({
    'id': id,
    'type': 'raster',
    'source': 'azure'
  }, ZLayers.myPosition.call(this, id))
}

export default Azure
