import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'target', 'option'];
  static classes = ['optionDisabled'];

  connect() {
    this.updateTarget(this.sourceTarget.value);
  }

  handleSelectChange() {
    this.updateTarget(this.sourceTarget.value);
  }

  updateTarget(sourceId) {
    const self = this;
    const options = this.optionTargets;
    const sourceSelect = this.sourceTarget;
    const targetSelect = this.targetTarget;

    targetSelect.value = sourceSelect.dataset.originalValue != sourceId ? "" : targetSelect.dataset.originalValue;
    targetSelect.disabled = !sourceId;

    if (targetSelect.disabled) return;

    options.forEach(option => {
      option.classList.toggle(self.optionDisabledClasses, option.dataset.categoryId != self.sourceTarget.value);
      option.disabled = option.dataset.categoryId != self.sourceTarget.value;
    });
  }
}
