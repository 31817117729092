import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import Icons from './icons'
import ZLayers from '../../../../z_layers'
import Properties from './properties'
import { BBox } from '../../../../geojson_utils'

export default class {
  constructor(mapboxToken, properties) {
    this.mapboxToken = mapboxToken
    this.properties = properties
    this.LAYER_POSITIONS = [
      'satellite',
      'properties'
    ]
    this.map = null
  }

  mount(container) {
    this.map = new MapboxGL.Map({
      accessToken: this.mapboxToken,
      container: container,
      style: 'mapbox://styles/mapbox/light-v11'
    })

    this.map.addControl(new MapboxGL.NavigationControl(), 'bottom-right')

    this.map.once('load', async () => {
      await Icons.load.call(this)
      await ZLayers.load.call(this)
      this.setup()
    })
  }

  setup() {
    try {
      Properties.load.call(this)
      this.fitMapToGeometries()
      this.map.once('idle', this.stopLoader)
    } catch(e) {
      console.error(e, e.stack)
      Sentry.captureException(e)
      this.stopLoader()
      this.displayErrorPage()
    }
  }

  fitMapToGeometries() {
    const sourceIds = ['properties']
    const combinedGeojsons = Turf.featureCollection([])
    sourceIds.forEach((id) => {
      combinedGeojsons.features = combinedGeojsons.features.concat(this.map.getSource(id)._data.features)
    })

    const bbox = new BBox(combinedGeojsons).toSquare()

    let distance = Turf.distance(bbox.southWest, bbox.northEast, { units: 'meters' }) / 6
    if (distance == 0) { distance = 3000 }

    bbox.extend(distance)
    this.map.fitBounds(bbox.value, { animate: false })
  }

  stopLoader() {
    const loader = document.getElementById('map-loader')
    loader.style.display = 'none'
  }

  displayErrorPage() {
    document.getElementById('map-container').innerHTML = `
      <div class="flex flex-col h-full map-placeholder text-white justify-center items-center text-base">
        Something went wrong. Please try again later.
      </div>
    `
  }
}
