export default {
  name: 'EditButton',
  props: {
    handleClick: Function,
    disabled: Boolean,
    tooltipText: String
  },
  template:`
    <button
      @click='handleClick()'
      class="whitespace-nowrap black-button"
      :title="tooltipText"
      :disabled="disabled"
    >
      Edit
    </button>
  `
}
