import { Controller } from "@hotwired/stimulus"
import L from 'leaflet';

export default class extends Controller {
  static targets = ['container', 'overlayContainer'];

  static values = {
    perimeterColor: String,
    highlightColor: String,
  }

  connect() {
    const map = L.map(
      this.containerTarget, {
        attributionControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        dragging: false,
        scrollWheelZoom: false,
        zoomControl: false,
      },
    );

    const feature = this.buildOutlineMap(map);
  }

  disconnect() {
    this.map.remove();
  }

  buildOutlineMap(map) {
    const sectionGeometry = JSON.parse(this.element.dataset.sectionGeometry);
    const featuresGeometry = JSON.parse(this.element.dataset.featuresGeometry);
    const highlightColor = this.highlightColorValue;
    const perimeterColor = this.perimeterColorValue;

    const geojsonMarkerOptions = {
      radius: 2,
      fillColor: (perimeterColor || "gray"),
      color: (perimeterColor || "gray"),
      weight: 1,
      opacity: 1,
      fill: false
    };

    var sectionOptions = {
      style: function(feature) {
        const color = perimeterColor || "lightgray";
        return { fill: false, stroke: true, color: color, weight: 3, zIndex: 1000 };
      }
    };
    var featureOptions = {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, geojsonMarkerOptions);
      },
      style: function(feature) {
        const color = highlightColor || "gray";
        return { fill: false, stroke: true, color: color, weight: 2, zIndex: 1000 };
      }
    };

    let sectionLayer = L.geoJson({ type: "FeatureCollection", features: sectionGeometry.features }, sectionOptions).addTo(map);
    let featuresLayer = L.geoJson({ type: "FeatureCollection", features: featuresGeometry.features }, featureOptions).addTo(map)

    map.fitBounds(sectionLayer.getBounds().extend(featuresLayer.getBounds()));
  }

  async downloadImage() {
    const svg = this.containerTarget.querySelector('svg')
    const svgData = this._encodeAsUTF8(this._serializeAsXML(svg))
    const img = await this._loadImage(svgData)
    const format = 'png'

    const canvas = document.createElement('canvas')
    canvas.width = svg.clientWidth
    canvas.height = svg.clientHeight
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, svg.clientWidth, svg.clientHeight)
    ctx.drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight)

    const dataURL = await canvas.toDataURL(`image/${format}`, 1.0)

    const sectionCode = this.element.dataset.sectionCode
    let finalImg = await this._loadImage(dataURL)
    const imageContainer = this.overlayContainerTarget.querySelector('.lb-overlay-image-container')
    imageContainer.innerHTML = ''
    imageContainer.appendChild(finalImg);
    this.overlayContainerTarget.classList.add('lb-force-show');

    const link = document.createElement('a')
    link.download = `section-image-${sectionCode}.${format}`
    link.href = dataURL
    link.target = '_blank'
    link.textContent = 'Download Image'
    link.className = "blue-button -mt-12"
    imageContainer.appendChild(link);
  }

  _serializeAsXML(e) {
    return (new XMLSerializer()).serializeToString(e)
  }

  _encodeAsUTF8(s) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(s)}`
  }

  async _loadImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = url;
    });
  }
}
