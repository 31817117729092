import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']
  static values = { multipleSiblings: Boolean }

  toggle(event) {
    event.preventDefault()

    const button = event.currentTarget;
    let row = button.closest('tr').nextElementSibling
    const firstRow = row;

    while(row) {
      row.classList.toggle('hidden')

      if (!this.multipleSiblingsValue) {
        break
      }

      row = row.nextElementSibling
    }

    const isExpanded = !firstRow.classList.contains('hidden')

    if (isExpanded) {
      this.iconTarget.querySelector('svg').classList.add('rotate-180')
    } else {
      this.iconTarget.querySelector('svg').classList.remove('rotate-180')
    }
  }
}
