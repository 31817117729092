import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import _ from 'lodash'
import colors from '../../../../colors'

const id = 'perimeters'
const polygonsLayerId = 'perimeters-fill'
const lineStringsLayerId = 'perimeters-line'

const Perimeters = {}

const lineColor =  (colorName) => {
  if (colorName == 'badge-green') return colors.neon.GREEN
  if (colorName == 'badge-orange') return colors.neon.YELLOW
  if (colorName == 'badge-red') return colors.neon.RED
}

const polygonColor = (colorName) => {
  if (colorName == 'badge-green') return colors.score.GREEN
  if (colorName == 'badge-orange') return colors.score.YELLOW
  if (colorName == 'badge-red') return colors.score.RED
}

Perimeters.load = function() {
  const sectionPolygons = this.perimeters_by_section.map(({ id, code, score_color, perimeters }) => {
    const coords = perimeters
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((p) => p.geometry.data.geometry.coordinates)
      .flat()

    try {
      const sectionPolygon = Turf.polygon([coords])
      sectionPolygon.id = id
      sectionPolygon.properties.line_color = lineColor(score_color)
      sectionPolygon.properties.polygon_color = polygonColor(score_color)
      return sectionPolygon
    } catch(e) {
      throw new Error('Could not generate section polygon for section ' + code + ' with id ' + id)
    }
  })

  const featureCollection = Turf.featureCollection(sectionPolygons)

  this.map.addSource(id, {
    'data': featureCollection,
    'type': 'geojson'
  })

  this.map.addLayer({
    'id': polygonsLayerId,
    'type': 'fill',
    'source': id,
    'layout': {
      'visibility': 'visible'
    },
    'paint': {
      'fill-color': ['get', 'polygon_color'],
      'fill-opacity': 0.6
    }
  }, ZLayers.myPosition.call(this, polygonsLayerId))

  this.map.addLayer({
    'id': lineStringsLayerId,
    'type': 'line',
    'source': id,
    'layout': {
      'visibility': 'visible'
    },
    'paint': {
      'line-color': ['get', 'line_color'],
      'line-width': 2
    }
  }, ZLayers.myPosition.call(this, lineStringsLayerId))

  this.map.on('custom.toggleVisibility', ({ layerId, checked }) => {
    if (layerId !== id) { return }

    const currentVisibility = this.map.getLayoutProperty(polygonsLayerId, 'visibility')
    const newVisibility = currentVisibility === 'visible' ? 'none' : 'visible'
    this.map.setLayoutProperty(polygonsLayerId, 'visibility', newVisibility)
    this.map.setLayoutProperty(lineStringsLayerId, 'visibility', newVisibility)
  })
}

export default Perimeters
