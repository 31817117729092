import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'attributes', 'uom', 'assetStrategyPlan'];
  static values = {
    reloadPath: String
  };

  formRequestFinished = false;

  handleCategoryChange = () => {
    const formData = new FormData(this.formTarget);
    const classificationId = formData.get('inventory[classification_id]');

    classificationId ? this.handleClassificationChange() : this.clearClassificationDependentFields();
  }

  handleClassificationChange = () => {
    this.formRequestFinished = false;

    // Prevents form from submitting invalid data attributes
    // for inventories and condition scores
    this.clearClassificationDependentFields();

    fetch(this.buildReloadURL())
      .then(response => response.text())
      .then(html => {
        this.updateFormContent(html);
        this.formRequestFinished = true;
      });
  }

  handleAssetStrategyChange = (e) => {
    if(this.hasAssetStrategyPlanTarget){
      const assetStrategyId = e.target.value;
      // Remove "hidden" attribute from all options of this.assetStrategyPlanTarget
      this.assetStrategyPlanTarget.value = '';
      this.assetStrategyPlanTarget.querySelectorAll('option').forEach(option => {
        if (option.value == '' || option.dataset.assetStrategyId == assetStrategyId) {
          option.removeAttribute('hidden');
        } else {
          option.setAttribute('hidden', 'hidden');
        }
      });
    }
  }

  clearClassificationDependentFields = () => {
    if(this.hasAttributesTarget){
      this.attributesTarget.innerHTML = '';
    }

    if(this.hasUomTarget){
      if(this.uomTarget.type == 'input') {
        this.uomTarget.value = '';
      } else {
        this.uomTarget.innerHTML = '';
      }
    }
  }

  buildReloadURL = () => {
    const formData = new FormData(this.formTarget);
    const formInput = new URLSearchParams(formData);
    const reloadURL = new URL(window.location.protocol + '//' + window.location.host + this.reloadPathValue);
    const baseParams = new URLSearchParams(reloadURL.searchParams);
    reloadURL.search = formInput.toString();
    baseParams.forEach((value, key) => {
      reloadURL.searchParams.set(key, value);
    })
    return reloadURL;
  }

  updateFormContent = (html) => {
    const parser = new DOMParser();
    const newForm = parser.parseFromString(html, 'text/html').querySelector('#model-form');
    this.formTarget.innerHTML = newForm.innerHTML;
  }
}
