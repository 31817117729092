import _ from 'lodash'
import { mapStores, mapActions } from 'pinia'

import { useMapStore, useModeStore, useGeometryStore, useLockStore } from '../stores'
import { CancelButton, SaveAndContinueButton, SaveAndExitButton } from './buttons'
import { initializeLockedMode } from '.'
import { SectionLabels } from '../plugins'
import { constants } from '../constants'

export default {
  name: 'EditMode',
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore, useLockStore),
  },
  methods: {
    ...mapActions(useModeStore, ['enteringEditError']),
    ...mapActions(useMapStore, ['setDrawFeaturesProperty']),
    updateFeatureCallback({ features }) {
      const feature = features[0]

      if (this.geometryStore.typeOf(feature.id) === constants.targets.PERIMETER) {
        SectionLabels.repositionCenter(feature.properties.section_id)
        this.mapStore.plugins.conditionScoreBackgrounds.recalculate(feature)
      }

      if ([constants.targets.PERIMETER, constants.targets.INTERIOR_PERIMETER].includes(this.geometryStore.typeOf(feature.id))) {
        const connectedSegments = feature.properties.connected_segments

        if (_.isArray(connectedSegments) && !_.isEmpty(connectedSegments)) {
          connectedSegments.forEach(({ feature_id, connected_coord_path, dragged_coord_path }) => {
            const connectedSegment = this.mapStore.draw.get(feature_id)
            const newPosition = feature.geometry.coordinates[dragged_coord_path]

            // One more time, update the connected segment with the new position of the dragged vertex
            connectedSegment.geometry.coordinates[connected_coord_path] = [newPosition[0], newPosition[1]]
            this.mapStore.draw.add(connectedSegment)
            this.geometryStore.pushFeature(connectedSegment)
          })

          // After all connected segments have been updated, recalculate the labels and angles
          connectedSegments.forEach(({ feature_id }) => {
            const connectedSegment = this.mapStore.draw.get(feature_id)
            this.mapStore.plugins.perimeterSegmentLabels.recalculate(connectedSegment)
            this.mapStore.plugins.perimeterSegmentAngles.recalculate(connectedSegment)
          })

          // Finally, delete the connected segments property from the feature and recalculate the labels and angles
          this.mapStore.draw.setFeatureProperty(feature.id, 'connected_segments', undefined)
          delete feature.properties.connected_segments
        }

        this.mapStore.plugins.perimeterSegmentLabels.recalculate(feature)
        this.mapStore.plugins.perimeterSegmentAngles.recalculate(feature)
      }

      if ([constants.domainTypes.DEFECT, constants.domainTypes.INVENTORY].includes(this.geometryStore.typeOf(feature.id))) {
        this.mapStore.plugins.classificationIcons.recalculate(feature)
      }

      if (this.geometryStore.typeOf(feature.id) === constants.targets.DEFECT) {
        this.mapStore.plugins.defectAngles.recalculate(feature)
      }

      this.geometryStore.pushFeature(feature)

      if (this.geometryStore.selectedFeature.id == feature.id) {
        // Refresh the selected feature if it's the one that got updated
        this.geometryStore.setSelectedFeature(feature)
      }
    },
    selectFeatureCallback({ features }) { this.geometryStore.setSelectedFeature(features[0]) },
    updateColor(name, value) { this.setDrawFeaturesProperty(name, 'color', value) },
    updateLineWidth(name, value) { this.setDrawFeaturesProperty(name, 'line_width', value) },
    updatePointRadius(name, value) { this.setDrawFeaturesProperty(name, 'point_radius', value) },
    updateVertexRadius(name, value) { this.setDrawFeaturesProperty(name, 'vertex_radius', value) },
    enterEditing() {
      this.mapStore.addDrawFeatures()
      this.mapStore.addMenuFolders({
        displayColorCallback: this.updateColor,
        lineWidthCallback: this.updateLineWidth,
        pointRadiusCallback: this.updatePointRadius,
        vertexRadiusCallback: this.updateVertexRadius
      })
      this.mapStore.addDrawListener('draw.update', this.updateFeatureCallback)
      this.mapStore.addDrawListener('draw.selectionchange',this.selectFeatureCallback)
      this.$parent.modeChanged()
    },
    exitEditing() { this.modeStore.enterViewMode() },
    cancelCallback() {
      this.geometryStore.clearChanges()
      this.exitEditing()
    },
    async saveCallback() { return await this.geometryStore.saveAll() },
    async saveAndExitCallback() {
      const saved = await this.saveCallback(true)
      if (!saved) { return false }
      this.exitEditing()
    }
  },
  async mounted() { await initializeLockedMode(this.enterEditing) },
  unmounted() {
    this.mapStore.removeDrawListener('draw.update', this.updateFeatureCallback)
    this.mapStore.removeDrawListener('draw.selectionchange', this.selectFeatureCallback)
    this.mapStore.draw.deleteAll()
    this.mapStore.$patch({ popupData: null })
    this.geometryStore.setSelectedFeature(null)
    this.lockStore.disablePageExitGuard()
  },
  components: { CancelButton, SaveAndContinueButton, SaveAndExitButton },
  template: `
  <div class='absolute left-2 bottom-8'>
    <SaveAndContinueButton :save-callback='saveCallback' />
    <SaveAndExitButton :save-callback='saveAndExitCallback' />
    <CancelButton :cancel-callback='cancelCallback' />
  </div>
  `
}
