import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { isRightClick } from './utils'

const { CommonSelectors } = MapboxDraw.lib
const SimpleSelect = MapboxDraw.modes.simple_select
const Patch = { ...SimpleSelect }

Patch.onTrash = function() {}

Patch.startOnActiveFeature = function(state, e) {
  this.map.fire('draw.startdragging')
  SimpleSelect.startOnActiveFeature.call(this, state, e)
}

Patch.onClick = Patch.onTap = function(state, e) {
  if (isRightClick(e)) { return }

  // The goal is to make the map enter direct_select mode directly, instead of
  // staying in simple_select, whenever a feature is clicked (with the exception
  // of Point features, which are not valid features for direct_select)

  // Clicking on an empty part of the map
  if (CommonSelectors.noTarget(e)) return SimpleSelect.onClick.call(this, state, e)

  // Clicking on a vertex
  // Vertices will never be clicked in simple_select mode because they require an active
  // feature to be selectable. Currently, after clicking a feature and making it active, we
  // switch to direct_select mode. The 'if' statement is left below just to be more explicit
  if (CommonSelectors.isVertex(e)) { return }

  // Clicking on a feature (the only option remaining)
  const featureId = e.featureTarget.properties.id

  const isFeaturePoint = this.getFeature(featureId).type === MapboxDraw.constants.geojsonTypes.POINT
  if (isFeaturePoint) {
    // Point features don't use the direct_select mode
    this.setSelected(featureId)
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE })
    this.doRender(featureId)
  } else {
    // Switch to direct_select mode
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE })
    return this.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, {
      featureId
    })
  }
}

export default Patch
