import { ApiClient } from "./api_client"

const create = async () => {
  return await ApiClient.makeRequest({
    url: '/admin/api/auth_token',
    method: 'POST',
    credentials: 'same-origin'
  })
}

export {
  create
}
