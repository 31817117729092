import * as Turf from '@turf/turf'
import _ from 'lodash'
import { mapWritableState, mapStores, mapActions } from 'pinia'

import LegendItem from '../legend_item'
import EntityItem from '../entity_item'
import { useLegendStore, useGeometryStore, useMapStore, useModeStore } from '../../../../stores'
import SectionLabels from '../../../section_labels'

import template from './index.html'

export default {
  name: 'LegendList',
  components: { LegendItem, EntityItem },
  mounted() {
    this.subscribeToRefreshFeaturesAction()
  },
  computed: {
    ...mapStores(useLegendStore, useGeometryStore, useMapStore, useModeStore),
    ...mapWritableState(
      useLegendStore,
      [
        'legendItemTree',
        'noGeometryIds',
        'legendItems',
        'noVisibilityIds',
        'showNestedItemsMap',
        'legendItemTypes',
        'sectionVisibility'
      ]),
    mapStoreLayers() {
      return _.keys(this.mapStore.layers)
    },
    noGeometryItems() {
      return _.values(_.pick(this.legendItems, this.noGeometryIds))
    }
  },
  methods: {
    ...mapActions(useLegendStore, ['findAllGeometryIds', 'handleNoVisibilityIds']),
    subscribeToRefreshFeaturesAction() {
      this.geometryStore.$onAction(({ name, after }) => {
        after(() => {
          if (name === 'refreshFeatures') {
            this.legendStore.buildLegendItemTree()
          }
        })
      }, true)
    },
    checkboxValueChange(item) {
      this.handleCheckboxAndIndeterminateValues(item)
      this.handleFilters(item)
    },
    handleFilters(item) {
      if (item.type === 'location') {
        this.toggleAll(item.checkboxValue)
      } else {
        this.filterView(item)
      }
    },
    filterView(item) {
      const filteredIds = this.findAllGeometryIds(item)
      if (item.type === 'section') { this.sectionVisibility[item.id] = item.checkboxValue }
      this.handleNoVisibilityIds(item.checkboxValue, filteredIds)
      const layer =  _.camelCase(item.layer)
      this.modeStore.inEditMode ? this.updateEditModeMap(filteredIds, item.checkboxValue) : this.updateMap(this.mapStoreLayers.includes(layer) ? layer : null)
    },
    updateEditModeMap(ids, value) {
      ids.forEach((featureId) => {
        if (!this.noGeometryIds.includes(featureId)) {
          this.mapStore.draw.setFeatureProperty(featureId, 'visible', value)
          const feature = this.mapStore.draw.get(featureId)
          this.mapStore.draw.add(feature)
        }
      })
      this.deselectHiddenFeatures()
      SectionLabels.refreshVisibility()
    },
    updateMap(layer = null) {
      const layersToUpdate = layer ? [layer] : this.mapStoreLayers
      layersToUpdate.forEach((currentLayer) => this.updateMapByLayer(currentLayer))
      SectionLabels.refreshVisibility()
    },
    updateMapByLayer(layer) {
      const layerName = _.kebabCase(layer)
      const data = Turf.featureCollection(this.geometryStore.featuresWithExtendedProps(layer, { includeEmptyGeometry: true }))
      const geojsonObj = this.mapStore.map.getSource(layerName)
      geojsonObj.setData(data)
    },
    deselectHiddenFeatures () {
      // When a feature that will be hidden is currently selected, it's necessary to deselect it.
      // This way, all the dynamically added elements by the drawing tool during the selection and interaction
      // with a feature are removed from the map.
      const selectedFeatures = this.mapStore.draw.getSelected().features
      const featuresRemainingSelected = selectedFeatures.filter(feature => feature.properties.visible === true)

      if (selectedFeatures.length !== featuresRemainingSelected.length) {
        // There are selected features that are going to be hidden.
        const drawMode = featuresRemainingSelected.length > 0 ? this.mapStore.draw.getMode() : 'simple_select'
        this.mapStore.draw.changeMode(drawMode, { featureIds: featuresRemainingSelected.map(feature => feature.id) })
      }
    },
    async selectAllOptions() {
      await this.handleCheckedOptions(true)
      this.toggleAll(true)
    },
    async deselectAllOptions() {
      await this.handleCheckedOptions(false)
      this.toggleAll(false)
    },
    async handleCheckedOptions(state, items = this.legendItems) {
      _.values(items).forEach((item)=> {
        if (!item.disabled) {
          item.checkboxValue = state
          item.indeterminateValue = false
        }
      })
    },
    toggleAll(state) {
      if (state) {
        this.noVisibilityIds = []
        this.sectionVisibility = _.mapValues(this.sectionVisibility, () => true)
        this.legendItemTypes['checkedCount'] = { ...this.legendItemTypes['totalCount'] }
      } else {
        this.noVisibilityIds = [...new Set([...this.geometryStore.allFeatureIds, ...this.noGeometryIds])]
        this.sectionVisibility = _.mapValues(this.sectionVisibility, () => false)
        this.legendItemTypes['checkedCount'] = {
          perimeter: 0,
          defects: 0,
          inventories: 0,
          interiorPerimeter: 0,
        }
      }
      this.modeStore.inEditMode ? this.updateEditModeMap(_.compact(this.geometryStore.allFeatureIds), state) : this.updateMap()
    },
    handleCheckboxAndIndeterminateValues(item, { toggleNested = true } = {}) {
      const children = this.findChildrenByParentId(item.parentId)
      const parent = this.findParentById(item.parentId)

      if (parent) {
        const headerCheckCheck = children.every(child => child.checkboxValue)
        parent.checkboxValue = headerCheckCheck
        if (toggleNested) this.showNestedItemsMap[parent.id] = true

        parent.indeterminateValue = headerCheckCheck
          ? false
          : children.length === 1
          ? children[0].items.some(child => child.checkboxValue)
          : children.some(child => child.checkboxValue) || children.some(child => child.indeterminateValue);

        if (parent.type === 'section') {
          const isVisible = parent.indeterminateValue || parent.checkboxValue
          this.sectionVisibility[parent.id] = isVisible
        }

        this.handleCheckboxAndIndeterminateValues(parent, { toggleNested });
      }
    },
    findChildrenByParentId(parentId) {
      let items = this.legendItems[parentId]?.items
      if (items) {
        items = items.filter((child) => child.disabled === false)
      }
      return items
    },
    findParentById(parentId) {
      return this.legendItems[parentId]
    },
    toggleNoGeometry() {
      this.handleCheckedOptions(false)
      this.resetVisibilityAndItemCounter()
      this.handleNoVisibilityIds(true, this.noGeometryIds)

      this.noGeometryItems.forEach((item)=> {
        this.legendItemTypes['checkedCount'][item.geometryType] += 1
        item.checkboxValue = true
        this.handleCheckboxAndIndeterminateValues(item)
      })
      this.modeStore.inEditMode ? this.updateEditModeMap(_.compact(this.geometryStore.allFeatureIds), false) : this.updateMap()
    },
    resetVisibilityAndItemCounter() {
      this.noVisibilityIds = this.geometryStore.allFeatureIds
      this.showNestedItemsMap = {}
      this.legendItemTypes['checkedCount'] = {
        perimeter: 0,
        defects: 0,
        inventories: 0,
        interiorPerimeter: 0,
      }
    },
    checkboxValueEntityChange(item) {
      this.toggleVisibilityByType(item.geometryType, item.checkboxValue)
    },
    async toggleVisibilityByType(geometryType, value) {
      const itemsByType = _.pick(this.legendItems, this.legendItemTypes['ids'][geometryType])
      await this.handleCheckedOptions(value, itemsByType)
      _.flatMap(_.values(itemsByType), (item) => this.handleCheckboxAndIndeterminateValues(item, { toggleNested: false }))
      this.handleNoVisibilityIds(value, this.legendItemTypes['ids'][geometryType])
      this.modeStore.inEditMode ? this.updateEditModeMap(this.legendItemTypes['ids'][geometryType], value) : this.updateMap()
    },
  },
  template: template
}
