import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "userMenu"
  ]

  static classes = [
    "userMenuClosed",
    "userMenuOpened"
  ]

  static values = {}

  initialize() {
    this.userMenuOpen = false;
  }

  toggleUserMenu() {
    if (this.userMenuOpen) {
      this.userMenuTarget.classList.remove(...this.userMenuOpenedClasses);
      this.userMenuTarget.classList.add(...this.userMenuClosedClasses);
    } else {
      this.userMenuTarget.classList.remove(...this.userMenuClosedClasses);
      this.userMenuTarget.classList.add(...this.userMenuOpenedClasses);
    }

    this.userMenuOpen = !this.userMenuOpen;
  }
}
