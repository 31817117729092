import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submitForm(event) {
    event.preventDefault();
    document.getElementById('warranty_reload').value = false;
    this.element.submit();
  }

  reloadForm(event) {
    event.preventDefault();
    document.getElementById('warranty_reload').value = true;
    this.element.submit();
  }
}
