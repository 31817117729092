const colors = {}

colors.neon = {
  GREEN: '#39FF14',
  YELLOW: '#FFFF00',
  BLUE: '#00BFFF',
  ORANGE: '#FFA500',
  RED: '#FF0055',
  PURPLE: '#DA70D6'
}

colors.score = {
  GREEN: '#BAD888',
  YELLOW: '#FCF197',
  RED: '#F0A9A8'
}

colors.workOrder = {
  BLUE_LIGTHER: '#B9D4EE',
  BLUE: '#3B8CDB',
  BLUE_DARKER: '#1A548C'
}

colors.BLACK = '#000000'
colors.WHITE = '#FFFFFF'

export default colors
