import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { useMapStore } from '../../stores/map_store'
import SelectedFeature from './components/selected_feature'

export default class ContextualToolbar {
  constructor() {}
  initialize() {
    const mapStore = useMapStore()
    mapStore.$patch({
      rightPanelComponent: Vue.shallowRef(SelectedFeature)
    })
  }

  modeChanged(toMode) {}
}
