import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import Popup from '../../../../images_popup'
import { calculateCenter } from '../../../../geojson_utils'

const Defects = {}

const id = 'defects'

Defects.load = function() {
  const centers = this.defects.map((defect) => {
    const defectFeature = defect.geometry.data
    const center = calculateCenter(defectFeature)
    center.id = defect.id
    center.properties = {
      id: defect.id,
      image_ids: defect.image_ids,
      popup_text: defect.id.slice(-5) + ' - ' + defect.classification_name
    }
    return center
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(centers)
  })

  this.map.addLayer({
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'icon-image': 'map-pin-red',
      'icon-allow-overlap': true,
      'visibility': 'visible'
    }
  }, ZLayers.myPosition.call(this, id))

  this.map.on('custom.toggleVisibility', ({ layerId, checked }) => {
    if (layerId !== id) { return }

    const currentVisibility = this.map.getLayoutProperty(id, 'visibility')
    const newVisibility = currentVisibility === 'visible' ? 'none' : 'visible'
    this.map.setLayoutProperty(id, 'visibility', newVisibility)
  })

  Popup.init.call(this, id)
}

export default Defects
