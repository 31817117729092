import * as Vue from 'vue/dist/vue.esm-bundler.js'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore } from '../../../../stores'
import { mapStores, mapState } from 'pinia'
import { Api } from '../../../../api'

import template from './index.html'

export default {
  data() {
    return {
      loading: false,
      classificationOptions: [],
      selectedClassificationId: null
    }
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore),
    ...mapState(useModeStore, ['showLoader']),
    ...mapState(useGeometryStore, ['selectedFeature']),
    perimeterSegmentId() { return this.selectedFeature.properties.id },
    selectedFeatureType() { return _.startCase(this.geometryStore.typeOf(this.selectedFeature.id)) }
  },
  methods: {
    closeModal() {
      this.mapStore.closeModal()
    },
    async updatePerimeterSegment() {
      const response = await Api.PerimeterSegments.update(this.perimeterSegmentId, this.selectedClassificationId)
      if (response.errored()) { this.modeStore.savingError() }
      return response.succeeded()
    },
    async refreshMap() {
      const selectedFeatureId = this.selectedFeature.id
      const success = await this.geometryStore.refreshFeatures(this.modeStore.savingError)
      if (!success) { return false }
      this.mapStore.draw.deleteAll()
      this.mapStore.addDrawFeatures()
      this.geometryStore.programmaticSelectFeature(selectedFeatureId)
      this.mapStore.plugins.perimeterSegmentLabels.recalculate(this.selectedFeature)
      return true
    },
    async handleSubmit() {
      this.modeStore.startLoading()

      const updated = await this.updatePerimeterSegment()
      if (!updated) { return this.closeModal() }

      const refreshed = await this.refreshMap()
      if (!refreshed) { return this.closeModal() }

      this.closeModal()
      this.modeStore.stopLoading()
    }
  },
  async beforeMount() {
    this.modeStore.startLoading()
    const response = await Api.PerimeterSegments.changeTypeOptions(this.perimeterSegmentId)
    if (response.errored()) {
      this.modeStore.loadingFormError()
      this.closeModal()
    } else {
      this.classificationOptions = response.data.classification_options
      this.selectedClassificationId = response.data.current_classification_id
    }
    this.modeStore.stopLoading()
  },
  template: template
}
