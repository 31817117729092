const svgDataUrl = (svg) => `data:image/svg+xml;base64,${btoa(svg)}`

const loadIconsIntoMap = (map, svgs) => {
  return new Promise((resolve) => {
    let loadedCount = 0

    if (svgs.length === 0) { resolve() }

    map.on('custom.icon.loaded', () => {
      loadedCount++
      if (loadedCount === svgs.length) {
        resolve()
      }
    })

    svgs.forEach((svg) => {
      const image = new Image(svg.width || 20, svg.height || 20)
      image.onload = () => {
        map.addImage(svg.id, image)
        map.fire('custom.icon.loaded')
      }
      image.src = svgDataUrl(svg.data)
    })
  })
}

export { loadIconsIntoMap }
