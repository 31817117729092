import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('keyup', this.handleKeyup.bind(this));
  }

  handleKeyup(e) {
    const overlay = this._getOverlay();
    if (!overlay) return;

    if (e.key === 'ArrowRight') {
      overlay.querySelector('.lb-next')?.click();
    } else if (e.key === 'ArrowLeft') {
      overlay.querySelector('.lb-prev')?.click();
    } else if (e.key === 'Escape') {
      overlay.querySelector('.lb-close').click();
    }
  }

  handleOverlayClick(e) {
    const overlay = this._getOverlay();
    if (e.target === overlay) {
      overlay.querySelector('.lb-close').click();
    }
  }

  close(e) {
    e.preventDefault()
    const overlay = this._getOverlay();
    const currentPos = document.documentElement.scrollTop || document.body.scrollTop
    location.hash = ''
    document.documentElement.scrollTop = document.body.scrollTop = currentPos
    overlay.classList.remove('lb-force-show');
  }

  _getOverlay() {
    return document.querySelector('.lb-overlay:target') ||
      document.querySelector('.lb-overlay.lb-force-show');
  }
}
