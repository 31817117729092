import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'addButton', 'selectAllCheckbox', 'selectCheckbox' ];

  toggleSelectAll(event) {
    for (let checkbox of this.selectCheckboxTargets){
      checkbox.checked = event.target.checked;
    }

    this.change();
  }

  change(_event) {
    const selectedImages = this.selectCheckboxTargets.filter((image) => image.checked === true).length;
    const noSelectedImages = selectedImages === 0;

    this.selectAllCheckboxTarget.checked = !noSelectedImages && this.selectCheckboxTargets.length === selectedImages;
    this.addButtonTarget.disabled = noSelectedImages;
  }
}
