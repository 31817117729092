import MapboxDraw from '@mapbox/mapbox-gl-draw'
import _ from 'lodash'
import { findClosestSegment, isRightClick, canDeleteFeature, findConnectedInteriorPerimeters, findConnectedPerimeters } from './utils'
import { toggleTrashButton } from '../stores/map_store/actions'
import { useGeometryStore } from '../stores/'

const { createSupplementaryPoints, CommonSelectors } = MapboxDraw.lib
const DirectSelect = MapboxDraw.modes.direct_select
const Patch = { ...DirectSelect }

const setSelectedCoordPaths = (selectedCoordPaths) => {
  const geometryStore = useGeometryStore()
  geometryStore.setSelectedCoordPaths(selectedCoordPaths)
}

Patch.onSetup = function(opts) {
  let state = DirectSelect.onSetup.call(this, opts)
  state.draggingLineStringSegment = false
  state.connectedSegments = []
  toggleTrashButton(canDeleteFeature(state.feature, state.selectedCoordPaths))
  setSelectedCoordPaths(state.selectedCoordPaths)
  return state
}

Patch.onStop = function() {
  toggleTrashButton(false)
  setSelectedCoordPaths([])
  DirectSelect.onStop.call(this)
}

Patch.dragVertex = function(state, e, delta) {
  DirectSelect.dragVertex.call(this, state, e, delta) // The position of the dragged feature is updated here

  state.connectedSegments.forEach(({ segment, connectedCoordPath, draggedCoordPath }) => {
    // Get the new position from the connected original vertex being dragged
    const newPosition = state.feature.getCoordinate(draggedCoordPath)
    segment.updateCoordinate(connectedCoordPath, newPosition[0], newPosition[1])
  })
}

Patch.onMouseUp = function(state) {
  if (state.dragMoving && state.connectedSegments.length) {
    const connectedSegments = []
    state.connectedSegments.forEach((data) => {
      // Copy the new position of the dragged vertex to the connected segments one last time before
      // the dragging is finished. Since this is the function that fires the UPDATE event,
      // we can ensure that the connected segments are updated with the latest position of the dragged vertex.
      const newPosition = state.feature.getCoordinate(data.draggedCoordPath)
      data.segment.updateCoordinate(data.connectedCoordPath, newPosition[0], newPosition[1])

      // Also, the connected segments' data is stored in the properties of the feature
      // that was just dragged. This is done at the end of the update to keep the features as lightweight as possible.
      // In this way, the single UPDATE event fired for the feature update will contain the information of
      // all the connected segments, useful for refreshing corresponding plugins (e.g.: lengths, angles, etc.).
      connectedSegments.push({
        feature_id: data.segment.id,
        connected_coord_path: data.connectedCoordPath,
        dragged_coord_path: data.draggedCoordPath
      })
    })
    state.feature.properties.connected_segments = connectedSegments
  }

  DirectSelect.onMouseUp.call(this, state)
}

Patch.onTrash = function(state) {
  if (canDeleteFeature(state.feature, state.selectedCoordPaths)) {
    DirectSelect.onTrash.call(this, state)
    toggleTrashButton(false)
    setSelectedCoordPaths(state.selectedCoordPaths)
  }
}

Patch.onVertex = function (state, e) {
  // Ensure that the last clicked vertex is always the only one selected (multiple vertices cannot be manually selected)
  state.selectedCoordPaths = [e.featureTarget.properties.coord_path]

  DirectSelect.onVertex.call(this, state, e)
  toggleTrashButton(canDeleteFeature(state.feature, state.selectedCoordPaths))
  setSelectedCoordPaths(state.selectedCoordPaths)
}

Patch.clickActiveFeature = function (state) {
  DirectSelect.clickActiveFeature.call(this, state)
  toggleTrashButton(canDeleteFeature(state.feature, state.selectedCoordPaths))
  setSelectedCoordPaths(state.selectedCoordPaths)
}

Patch.onClick = function(state, e) {
  if (isRightClick(e)) { return }

  DirectSelect.onClick.call(this, state, e)
}

Patch.startDragging = function(state, e) {
  const feature = state.feature

  const isLineString = feature.type === MapboxDraw.constants.geojsonTypes.LINE_STRING
  if (CommonSelectors.isActiveFeature(e) && isLineString) {
    // When dragging an active LineString feature, we want only the segment that was clicked on to move,
    // not the entire set of segments that make up the LineString. This is achieved by automatically selecting
    // the edges of the clicked segment, just as if the user had manually selected those edges.
    const clickedCoords = [e.lngLat.lng, e.lngLat.lat]
    const closestSegmentIndex = findClosestSegment(clickedCoords, feature)
    state.selectedCoordPaths = [closestSegmentIndex.toString(), (closestSegmentIndex + 1).toString()]
    state.draggingLineStringSegment = true
  }

  const geometryStore = useGeometryStore()
  const isPerimeter = geometryStore.typeOf(feature.id) === 'Perimeter'
  const isInteriorPerimeter = geometryStore.typeOf(feature.id) === 'Interior Perimeter'
  const draggingFirstVertex = state.selectedCoordPaths.includes('0')
  const draggingLastVertex = state.selectedCoordPaths.includes((feature.coordinates.length - 1).toString())

  if (isPerimeter && (draggingFirstVertex || draggingLastVertex)) {
    // In the case of moving a perimeter segment, we want to prevent the geometry that forms the section from breaking
    // (stop forming a polygon). To achieve this, we check if the vertices being moved coincide with the start or
    // end of a connected perimeter segment.
    // If there are matches, they are saved in state.connectedSegments to be updated according to the movement
    // of the selected vertices.
    const perimetersIds = geometryStore.sections[feature.properties.section_id].perimeterIds
    state.connectedSegments = findConnectedPerimeters.call(this, perimetersIds, state)
  }

  if (isInteriorPerimeter) {
    // In the case of moving an interior perimeter segment, we want to prevent connected segments from becoming
    // disconnected. To achieve this, we search for any vertex connected to the vertices being dragged.
    // If there are matches, they are saved in state.connectedSegments to be updated according to the movement
    // of the selected vertices.
    const interiorPerimeters = geometryStore.sections[feature.properties.section_id].interiorPerimeters
    state.connectedSegments = findConnectedInteriorPerimeters.call(this, interiorPerimeters, state)
  }

  this.map.fire('draw.startdragging')
  DirectSelect.startDragging.call(this, state, e)
}

Patch.stopDragging = function(state) {
  if (state.draggingLineStringSegment) {
    state.draggingLineStringSegment = false
    state.selectedCoordPaths = []
  }
  state.connectedSegments = []

  DirectSelect.stopDragging.call(this, state)
}

Patch.toDisplayFeatures = function(state, geojson, push) {
  // Disable the midpoint feature by setting the midpoint option to false.
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE
    push(geojson)
    createSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: false,
      selectedPaths: state.selectedCoordPaths
    }).forEach((point) => {
      // Vertices are added dynamically when selecting a feature. Currently, there is no built-in way to
      // ensure that the vertices inherit the properties of their parents, so custom properties are added manually
      // before rendering them (related github issue: https://github.com/mapbox/mapbox-gl-draw/pull/964).
      point.properties.user_color = geojson.properties.user_color
      point.properties.user_line_width = geojson.properties.user_line_width
      point.properties.user_point_radius = geojson.properties.user_point_radius
      point.properties.user_vertex_radius = geojson.properties.user_vertex_radius
      push(point)
    })
  } else {
    geojson.properties.active = MapboxDraw.constants.activeStates.INACTIVE
    push(geojson)
  }
  this.fireActionable(state)
}

export default Patch
