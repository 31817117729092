import { Controller } from "@hotwired/stimulus"

import AerialMap from './aerial_map/app.js'

export default class extends Controller {
  static targets = ['map-container']

  static values = {
    properties: Array,
    mapboxToken: String
  }

  connect() {
    window.app = new AerialMap(this.mapboxTokenValue, this.propertiesValue)

    window.app.mount('map-container')

    this.element.setAttribute('data-controller-connected', 'true')
  }
}
