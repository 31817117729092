<div class="flex w-24 items-center cursor-pointer text-sm mx-1">
  <input
    :id="item.id"
    v-model="checkboxValue"
    :indeterminate="indeterminateValue"
    type="checkbox"
    class="mr-2 focus:ring-0"
    :class="disabled ? 'opacity-75 text-gray-500' : ''"
    @change="checkboxValueEntityChange(item)"
    :disabled="disabled"
  />
  <label :for="item.id" class="text-sm no-underline hover:underline cursor-pointer whitespace-nowrap">
    {{item.label}}
  </label>
</div>
