<div class="flex flex-col items-center justify-center absolute h-full w-full bg-opacity-70 z-50 bg-gray-900">
  <div class="bg-white shadow-md p-4 rounded-lg flex flex-col h-96 w-96">
    <span class="font-semibold">Add Point</span>
    <span class="text-sm text-gray-900">Choose the desired side for adding a point</span>
    <div class="flex flex-col justify-between h-full">
      <div class="flex items-center justify-center h-full">
        <div
          id="option_0"
          class="h-32 w-32 border-solid border-2 rounded-lg cursor-pointer"
          :class="selectedOption == 0 ? 'border-gray-900' : 'border-gray-200'"
          @click="selectOption(0)"
        >
          <ArrowLongUpIcon
            class="h-full"
            :class="selectedOption == 0 ? 'fill-gray-900' : 'fill-gray-200'"
            :style="{ transform: `rotate(${options[0].bearing}deg)` }"
          >
          </ArrowLongUpIcon>
        </div>
        <div
          id="option_1"
          class="h-32 w-32 border-solid border-2 rounded-lg ml-5 cursor-pointer"
          :class="selectedOption == 1 ? 'border-gray-900' : 'border-gray-200'"
          @click="selectOption(1)"
        >
          <ArrowLongUpIcon
            class="h-full"
            :class="selectedOption == 1 ? 'fill-gray-900' : 'fill-gray-200'"
            :style="{ transform: `rotate(${options[1].bearing}deg)` }"
          >
          </ArrowLongUpIcon>
        </div>
      </div>
      <div class="flex justify-end">
        <button @click="closeModal" class="white-button">Cancel</button>
        <button @click="createPoint" :disabled="selectedOption == null" class="ml-3 blue-button">Add</button>
      </div>
    </div>
  </div>
</div>
