import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import colors from '../../../../colors'
import ZLayers from '../../../../z_layers'
import Popup from '../../../../images_popup'

const Defects = {}

const color = (status) => {
  switch(status) {
    case 'Monitor':
      return { color: colors.neon.ORANGE, icon: 'map-pin-orange' }
    case 'Assigned':
      return { color: colors.neon.RED, icon: 'map-pin-red' }
    case 'Proposed':
      return { color: colors.neon.RED, icon: 'map-pin-red' }
    case 'Temp Repair':
      return { color: colors.neon.GREEN, icon: 'map-pin-green' }
    case 'Repaired':
      return { color: colors.neon.GREEN, icon: 'map-pin-green' }
  }
}
const id = 'defects'

Defects.load = function() {
  const defects = this.defects.map((defect) => {
    const feature = defect.geometry.data
    feature.properties = color(defect.status)
    feature.properties.popup_text = `${defect.classification_name} - ${defect.status} - ${defect.section_code}`
    feature.properties.selected = false
    return feature
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(defects)
  })

  this.map.addLayer({
    'id': `${id}-line`,
    'type': 'line',
    'source': id,
    'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
    'paint': {
      'line-color': ['get', 'color'],
      'line-width': 2
    }
  }, ZLayers.myPosition.call(this, `${id}-line`))

  this.map.addLayer({
    'id': `${id}-line-highlight`,
    'type': 'line',
    'source': id,
    'filter': ['all',
      ['==', 'selected', true],
      ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
    ],
    'paint': {
      'line-color': colors.neon.YELLOW,
      'line-width': 6,
    },
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }, ZLayers.myPosition.call(this, `${id}-line-highlight`))

  this.map.addLayer({
    'id': `${id}-symbol`,
    'type': 'symbol',
    'source': id,
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'selected', false]
    ],
    'layout': {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true
    }
  }, ZLayers.myPosition.call(this, `${id}-symbol`))

  this.map.addLayer({
    'id': `${id}-symbol-highlight`,
    'type': 'symbol',
    'source': id,
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'selected', true]
    ],
    'layout': {
      'icon-image': ['concat', ['get', 'icon'], '-selected'],
      'icon-allow-overlap': true
    }
  }, ZLayers.myPosition.call(this, `${id}-symbol-highlight`))

  // The following invisible layer is added to enlarge the hitbox of the lines when clicked (to display the popup).
  // Without it, the hitbox is too small, making it difficult to click on top of the lines.
  this.map.addLayer({
    'id': `${id}-line-click-zone`,
    'type': 'line',
    'source': id,
    'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
    'paint': {
      'line-color': colors.WHITE,
      'line-width': 10,
      'line-opacity': 0 // make it invisible
    }
  }, ZLayers.myPosition.call(this, `${id}-line-click-zone`))

  Popup.init.call(this, `${id}-line-click-zone`, { noImagesMessage: null })
  Popup.init.call(this, `${id}-symbol`, { noImagesMessage: null })
  Popup.init.call(this, `${id}-symbol-highlight`, { noImagesMessage: null })
}

export default Defects
