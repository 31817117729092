const Vector = {}

Vector.magnitude = (vector) => {
  return Math.sqrt(vector[0] ** 2 + vector[1] ** 2)
}

Vector.norm = (vector) => {
  const magnitude = Vector.magnitude(vector)
  return [vector[0] / magnitude, vector[1] / magnitude]
}

Vector.sum = (vectorA, vectorB) => {
  return [vectorA[0] + vectorB[0], vectorA[1] + vectorB[1]]
}

Vector.dotProduct = (vectorA, vectorB) => {
  return vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1]
}

Vector.crossProduct = (vectorA, vectorB) => {
  return vectorA[0] * vectorB[1] - vectorA[1] * vectorB[0]
}

Vector.angleBetweenVectors = (vectorA, vectorB) => {
  const dotProduct = Vector.dotProduct(vectorA, vectorB)
  return Math.acos(dotProduct / (Vector.magnitude(vectorA) * Vector.magnitude(vectorB)))
}

Vector.bisector = (vectorA, vectorB) => {
  const crossProductAB = Vector.crossProduct(vectorA, vectorB)
  const normA = Vector.norm(vectorA)
  const normB = Vector.norm(vectorB)
  let bisector = Vector.norm(Vector.sum(normA, normB))

  // Set the correct orientation
  if (crossProductAB < 0) {
    bisector = [-bisector[0], -bisector[1]]
  }

  return bisector
}

export default Vector
