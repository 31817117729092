import ZLayers from '../../../../z_layers'

const id = 'custom-satellite'

const tileUrl = (key) => `https://apis.eagleview.com/imagery/wmts/v1/visual/tile/Latest/default/GoogleMapsCompatible/{z}/{x}/{y}.png?api_key=${key}`

const Eagleview = {}

Eagleview.load = function() {
  this.map.addSource('eagleview', {
    'type': 'raster',
    'tiles': [tileUrl(this.eagleviewKey)],
    'tileSize': 256,
    'attribution': '© Eagleview or © Mapbox'
  })

  this.map.addLayer({
    'id': id,
    'type': 'raster',
    'source': 'eagleview'
  }, ZLayers.myPosition.call(this, id))
}

export default Eagleview
