import { mapStores } from 'pinia'

import { useModeStore, useLockStore } from '../../stores'

export default {
  name: 'CancelButton',
  props: {
    cancelCallback: Function
  },
  computed: {
    ...mapStores(useModeStore, useLockStore),
  },
  methods: {
    async handleClick() {
      const confirmed = this.lockStore.confirmDiscard()
      if (!confirmed) { return }

      this.modeStore.startLoading()
      const lockArgs = [this.lockStore.discardLock, this.modeStore.initializeError]
      const discarded = await this.lockStore.tryLock(...lockArgs)
      if (!discarded) { return }

      await this.cancelCallback()
      return true
    }
  },
  template: `<button @click='handleClick()' class='whitespace-nowrap black-button ml-2'>Cancel</button>`
}
