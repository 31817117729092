import MapboxGL from 'mapbox-gl'
import _ from 'lodash'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useGeometryStore, useMapStore, useModeStore } from '../../stores'

import template from './index.html'
import DeleteFeatureButton from './delete_feature_button'

export default {
  // The calls to togglePopup during mounted and beforeUnmount are necessary because the watcher
  // will not be executed that first and last time in the component's lifecycle.
  mounted() { this.togglePopup() },
  beforeUnmount() { this.togglePopup() },
  watch: {
    'popupData.clickedFeatures'() { this.togglePopup() }
  },
  computed: {
    ...mapState(useModeStore, ['inViewMode', 'inEditMode', 'inReadOnlyMode']),
    ...mapState(useMapStore, ['popupData', 'map']),
    ...mapWritableState(useMapStore, ['popupInstance']),
    featuresGroupedBySectionCode() { return _.groupBy(this.popupData.clickedFeatures, 'sectionCode') },
    sortedSectionCodes() { return Object.keys(this.featuresGroupedBySectionCode).sort() }
  },
  methods: {
    ...mapActions(useGeometryStore, ['typeOf', 'programmaticSelectFeature']),
    togglePopup() {
      const popupAlreadyOpen = this.popupInstance != null
      if (popupAlreadyOpen) { this.closePopup() }
      if (this.popupData) { this.openPopup() }
    },
    openPopup() {
      const popup = new MapboxGL.Popup({ closeButton: false, maxWidth: '400px', className: 'admin' })
        .setLngLat(this.popupData.lngLat)
        .setDOMContent(this.$refs.htmlPopup)
        .addTo(this.map)

      this.popupInstance = popup
      this.map.once('draw.startdragging', this.closePopup)
    },
    closePopup() {
      this.popupInstance.remove()
      this.popupInstance = null
      this.map.off('draw.startdragging', this.closePopup)
    },
    featureDetails(feature) {
      return {
        id: feature.featureId,
        type: this.typeOf(feature.featureId),
        cfName: feature.classificationName,
        sectionId: feature.sectionId,
        recordId: feature.recordId
      }
    }
  },
  components: { DeleteFeatureButton },
  template: template
}
