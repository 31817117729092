import { loadIconsIntoMap } from '../../../icon_loader'
import colors from '../../../colors'
import MapPin from '../../../../assets/images/icons/custom/map-pin-stroked.svg'

const svgSetFillColor = (svg, color) => svg.replace(/fill="[^"]*"/, `fill="${color}"`)

const svgs = [
  {
    id: 'map-pin-red',
    data: svgSetFillColor(MapPin, colors.neon.RED)
  }
]

const Icons = {}

Icons.load = function() {
  return loadIconsIntoMap(this.map, svgs)
}

export default Icons
