import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import * as Sentry from '@sentry/browser'

const getImageSrc = async (scope, imageUrl) => {
  let src = imageUrl

  try {
    const cache = await caches.open(scope)
    const cachedImage = await cache.match(imageUrl)
    if (cachedImage) {
      const blob = await cachedImage.blob()
      src = URL.createObjectURL(blob)
    } else {
      const response = await fetch(imageUrl)
      if (response.ok) {
        const blob = await response.blob()
        await cache.put(imageUrl, new Response(blob))
        const src = URL.createObjectURL(blob)
      }
    }
  } catch (e) {
    console.error(e, e.stack)
    Sentry.captureException(e)
  }

  return src
}

const openImageModal = (src) => async () => {
  const modal = document.createElement('div')
  modal.id = 'image-modal'
  modal.className = `fixed inset-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50`
  modal.innerHTML = `<img src="${src}" class="max-w-full max-h-full">`
  modal.onclick = function() { this.remove() }
  document.body.appendChild(modal)
}

const Popup = {
  isPopupOpened: false,
  isRightClickEventInitialized: false
}

Popup.init = function(layerId, options = {}) {
  const { scope = 'client', noImagesMessage = 'This element does not have any associated photos' } = options

  const url = {
    client: (imageId, variant = 'thumb') => `/client_portal/properties/${this.property.id}/aerial_map/image_url/${imageId}?variant=${variant}`,
    public: (imageId, variant = 'thumb') => `/public/surveys/${this.survey.id}/aerial_map/image_url/${imageId}?variant=${variant}`
  }

  if (!this.isRightClickEventInitialized) {
    this.map.on('contextmenu', (e) => {
      this.map.fire('preclick', e) // Trigger 'preclick' event to close any open popups before processing a new click
      this.map.fire('click', e) // Simulate a 'click' event to handle the action as a left-click
    })
    this.isRightClickEventInitialized = true
  }

  this.map.on('click', layerId, async (e) => {
    if (this.isPopupOpened) { return }
    this.isPopupOpened = true

    const feature = e.features[0]
    const imageIds = feature.properties.image_ids ? JSON.parse(feature.properties.image_ids) : []
    const imagePlaceholders = imageIds.map((imageId) => `<img id="${imageId}" class="placeholder">`).join('')

    const popup = new MapboxGL.Popup({ closeOnMove: true, closeButton: true, className: scope, maxWidth: '400px' })
      .setLngLat(e.lngLat)
      .setHTML(`
        <div class="text-sm leading-none font-semibold mr-8">${feature.properties.popup_text}</div>
        ${imageIds.length
          ? `<div id="images-container" class="flex space-x-2.5 overflow-x-auto h-80 mt-2.5">${imagePlaceholders}</div>`
          : (noImagesMessage ? `<div class="flex space-x-2.5 overflow-x-auto mt-2.5">${noImagesMessage}</div>` : '')
        }
      `)

    popup.on('open', async () => {
      imageIds.forEach(async (imageId) => {
        const imageNode = document.getElementById(imageId)
        const thumbSrc = await getImageSrc(scope, url[scope](imageId))
        const defaultSrc = await getImageSrc(scope, url[scope](imageId, 'default'))

        imageNode.addEventListener('load', (event) => event.target.classList.remove('placeholder'))
        imageNode.src = thumbSrc
        imageNode.addEventListener('click', openImageModal(defaultSrc))
      })
    })

    popup.on('close', () => { this.isPopupOpened = false })

    popup.addTo(this.map)
  })
}

export default Popup
