import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import _ from 'lodash'
import colors from '../../../../colors'
import SectionLabels from './section_labels'

const id = 'warranties'

const Warranties = {}

Warranties.load = function() {
  const perimeters = this.perimeters_by_section
    .filter(({ has_active_warranty }) => has_active_warranty)
    .flatMap(({ perimeters }) => perimeters)
    .map(({ geometry }) => geometry.data)

  this.map.addSource(id, {
    'data': Turf.featureCollection(perimeters),
    'type': 'geojson'
  })

  this.map.addLayer({
    'id': id,
    'type': 'line',
    'source': id,
    'layout': {
      'visibility': 'none'
    },
    'paint': {
      'line-color': colors.neon.BLUE,
      'line-width': 3
    }
  }, ZLayers.myPosition.call(this, id))

  this.map.on('custom.toggleVisibility', ({ layerId, checked }) => {
    if (layerId !== id) { return }

    const currentVisibility = this.map.getLayoutProperty(id, 'visibility')
    const newVisibility = currentVisibility === 'visible' ? 'none' : 'visible'
    this.map.setLayoutProperty(id, 'visibility', newVisibility)

    SectionLabels.refresh.call(this)
  })
}

export default Warranties
