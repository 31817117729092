import { Controller } from "@hotwired/stimulus"
let updatableParam;

// Connects to data-controller="admin--image-bulk-edit"
export default class extends Controller {
  static targets = ['submitButton', 'checkAllCheckBox', 'selectCheckbox'];
  static values = {
    updatableAttr: String,
    defaultButtonText: String,
    buttonTextTrue: String,
    buttonTextFalse: String
  };

  connect() {
    if (this.hasCheckAllCheckBoxTarget) {
      this.checkFilteredOption()
    }
  }

  checkFilteredOption() {
    const urlParams = new URLSearchParams(window.location.search)
    updatableParam = urlParams.get(this.updatableAttrValue)
    this.checkAllCheckBoxTarget.disabled = !updatableParam
    if (updatableParam) this.updateButtonText(updatableParam === 'yes' ? 'true' : 'false')
  }

  disableOppositeCheckboxes(event) {
    if (event.target.checked) {
      this.checkAllCheckBoxTarget.disabled = false
      this.submitButtonTarget.disabled = false
      const currentSelection = event.target.dataset.selectionValue
      this.updateButtonText(currentSelection)

      this.selectCheckboxTargets.forEach(selectCheckbox => {
        if (selectCheckbox.dataset.selectionValue !== currentSelection) {
          selectCheckbox.disabled = true
        }
      })
    } else {
      if (this.selectCheckboxTargets.every(checkbox => !checkbox.checked)) {
        this.selectCheckboxTargets.forEach(selectCheckbox => selectCheckbox.disabled = false)
        this.resetSelection()
      }
    }

    this.updateCheckboxes()
  }

  toggleSelectAll(event) {
    this.checkAllCheckBoxTarget.indeterminate = false
    this.submitButtonTarget.disabled = false

    this.selectCheckboxTargets.filter(checkbox => !checkbox.disabled).forEach(selectCheckbox => {
      selectCheckbox.checked = event.target.checked
    })

    if (!event.target.checked) {
      this.selectCheckboxTargets.forEach(selectCheckbox => {
        selectCheckbox.checked = false
        selectCheckbox.disabled = false
      })
      this.resetSelection()
    }
  }

  updateCheckboxes() {
    const filteredCheckboxes = this.selectCheckboxTargets.filter(checkbox => !checkbox.disabled)
    const allChecked = filteredCheckboxes.every(checkbox => checkbox.checked)
    const someChecked = filteredCheckboxes.some(checkbox => checkbox.checked)

    this.checkAllCheckBoxTarget.indeterminate = !allChecked && someChecked
    this.checkAllCheckBoxTarget.checked = allChecked
  }

  resetSelection() {
    this.submitButtonTarget.disabled = true

    if (!updatableParam) {
      this.checkAllCheckBoxTarget.disabled = true
      this.submitButtonTarget.value = this.defaultButtonTextValue
    }
  }

  updateButtonText(currentSelection) {
    this.submitButtonTarget.value = currentSelection === 'true'
    ? this.buttonTextTrueValue
      : this.buttonTextFalseValue
  }
}
