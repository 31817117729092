const getValueFrom = (property_id, default_value, modifier = null) => {
  return ['case',
    ['has', property_id],
    modifier
      ? modifier(['get', property_id])
      : ['get', property_id],
    default_value
  ]
}

// The expression ['!has', 'user_visible'] verifies if the 'user_visible' property has been assigned
// to the feature. Since the drawing tool dynamically adds features that won't have this property,
// it's important to add this check, so that the layers continue to apply to all these features seamlessly.
const visibilityFilter = ['any',
  ['!has', 'user_visible'],
  ['==', 'user_visible', true]
]

const defaults = {
  LINE_WIDTH_ACTIVE: 2,
  LINE_WIDTH_INACTIVE: 2,
  CIRCLE_RADIUS_ACTIVE: 5,
  CIRCLE_RADIUS_INACTIVE: 3,
  COLOR_ACTIVE: '#fbb03b',
  COLOR_INACTIVE: '#3bb2d0'
}

// Offical defaults for reference:
// https://github.com/mapbox/mapbox-gl-draw/blob/main/src/lib/theme.js
export default [
  {
    'id': 'gl-draw-polygon-fill-inactive',
    'type': 'fill',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      visibilityFilter
    ],
    'paint': {
      'fill-color': 'transparent',
      'fill-outline-color': getValueFrom('user_color', defaults.COLOR_INACTIVE),
      'fill-opacity': 0.1
    }
  },
  {
    'id': 'gl-draw-polygon-fill-active',
    'type': 'fill',
    'filter': ['all', 
      ['==', 'active', 'true'], 
      ['==', '$type', 'Polygon'], 
      visibilityFilter
    ],
    'paint': {
      'fill-color': 'transparent',
      'fill-outline-color': getValueFrom('user_color', defaults.COLOR_ACTIVE),
      'fill-opacity': 0.1
    }
  },
  {
    'id': 'gl-draw-polygon-stroke-inactive',
    'type': 'line',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      visibilityFilter
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': getValueFrom('user_color', defaults.COLOR_INACTIVE),
      'line-width': getValueFrom('user_line_width', defaults.LINE_WIDTH_INACTIVE)
    }
  },
  {
    'id': 'gl-draw-polygon-stroke-active',
    'type': 'line',
    'filter': ['all', 
      ['==', 'active', 'true'], 
      ['==', '$type', 'Polygon'],
      visibilityFilter
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': getValueFrom('user_color', defaults.COLOR_ACTIVE),
      'line-width': getValueFrom('user_line_width', defaults.LINE_WIDTH_ACTIVE),
      'line-dasharray': [0.2, 2]
    }
  },
  {
    'id': 'gl-draw-line-inactive',
    'type': 'line',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      visibilityFilter
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': getValueFrom('user_color', defaults.COLOR_INACTIVE),
      'line-width': getValueFrom('user_line_width', defaults.LINE_WIDTH_INACTIVE)
    }
  },
  {
    'id': 'gl-draw-line-active',
    'type': 'line',
    'filter': ['all',
      ['==', '$type', 'LineString'],
      ['==', 'active', 'true'],
      visibilityFilter
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': getValueFrom('user_color', defaults.COLOR_ACTIVE),
      'line-width': getValueFrom('user_line_width', defaults.LINE_WIDTH_ACTIVE),
      'line-dasharray': [0.2, 2]
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_vertex_radius', defaults.CIRCLE_RADIUS_INACTIVE + 2, (v) => ['+', v, 2]),
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_vertex_radius', defaults.CIRCLE_RADIUS_INACTIVE),
      'circle-color': getValueFrom('user_color', defaults.COLOR_ACTIVE) // vertices use active color always
    }
  },
  {
    'id': 'gl-draw-point-point-stroke-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_point_radius', defaults.CIRCLE_RADIUS_INACTIVE + 2, (v) => ['+', v, 2]),
      'circle-opacity': 1,
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-point-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_point_radius', defaults.CIRCLE_RADIUS_INACTIVE),
      'circle-color': getValueFrom('user_color', defaults.COLOR_INACTIVE)
    }
  },
  {
    'id': 'gl-draw-point-stroke-active',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'meta', 'feature'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_point_radius', defaults.CIRCLE_RADIUS_ACTIVE + 2, (v) => ['+', ['*', v, 1.66], 2]),
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-point-active',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_point_radius', defaults.CIRCLE_RADIUS_ACTIVE, (v) => ['*', v, 1.66]),
      'circle-color': getValueFrom('user_color', defaults.COLOR_ACTIVE)
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-stroke-active',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_vertex_radius', defaults.CIRCLE_RADIUS_ACTIVE + 2, (v) => ['+', ['*', v, 1.66], 2]),
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-polygon-and-line-vertex-active',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      visibilityFilter
    ],
    'paint': {
      'circle-radius': getValueFrom('user_vertex_radius', defaults.CIRCLE_RADIUS_ACTIVE, (v) => ['*', v, 1.66]),
      'circle-color': getValueFrom('user_color', defaults.COLOR_ACTIVE)
    }
  }
]
