import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import Azure from './azure'
import Eagleview from './eagleview'
import ZLayers from '../../../../z_layers'
import SectionLabels from './section_labels'
import Perimeters from './perimeters'
import Warranties from './warranties'
import Inventories from './inventories'
import Defects from './defects'
import Icons from './icons'
import Checkboxes from './checkboxes'
import { BBox } from '../../../../geojson_utils'

export default class {
  constructor(provider, mapboxToken, azureKey, eagleviewKey, property, perimeters_by_section, inventories, defects) {
    this.provider = provider
    this.mapboxToken = mapboxToken
    this.azureKey = azureKey
    this.eagleviewKey = eagleviewKey
    this.property = property
    this.center = [Number(this.property.long), Number(this.property.lat)]
    this.perimeters_by_section = perimeters_by_section
    this.inventories = inventories
    this.defects = defects
    this.LAYER_POSITIONS = [
      'satellite',
      'custom-satellite',
      'perimeters-fill',
      'perimeters-line',
      'warranties',
      'section-labels',
      'inventories',
      'defects'
    ]
    this.map = null
  }

  mount(container) {
    this.map = new MapboxGL.Map({
      accessToken: this.mapboxToken,
      container: container,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: this.center,
      zoom: 18,
      minZoom: 15
    })

    this.map.addControl(new MapboxGL.NavigationControl(), 'bottom-right')

    this.map.once('load', async () => {
      await Icons.load.call(this)
      await ZLayers.load.call(this)
      this.setup()
    })
  }

  setup() {
    if (this.provider == 'azure') {
      this.map.removeLayer('satellite')
      this.map.removeLayer('background')
      this.map.removeSource('mapbox')
      Azure.load.call(this)
    } else if (this.provider == 'eagleview') {
      Eagleview.load.call(this)
    }

    try {
      SectionLabels.load.call(this)
      Perimeters.load.call(this)
      Warranties.load.call(this)
      Inventories.load.call(this)
      Defects.load.call(this)
      Checkboxes.init.call(this)
      this.fitMapToGeometries()
      this.map.once('idle', this.stopLoader)
    } catch(e) {
      console.error(e, e.stack)
      Sentry.captureException(e)
      this.stopLoader()
      this.displayErrorPage()
    }
  }

  fitMapToGeometries() {
    const sourceIds = ['perimeters', 'inventories', 'defects']
    const combinedGeojsons = Turf.featureCollection([])
    sourceIds.forEach((id) => {
      combinedGeojsons.features = combinedGeojsons.features.concat(this.map.getSource(id)._data.features)
    })

    const bbox = new BBox(combinedGeojsons).toSquare()

    bbox.extend(10) // mandatory visible area
    this.map.fitBounds(bbox.value, { animate: this.provider == 'azure' })

    bbox.extend(1500) // maximum visible area
    this.map.setMaxBounds(bbox.value)
  }

  stopLoader() {
    const loader = document.getElementById('map-loader')
    loader.style.display = 'none'
  }

  displayErrorPage() {
    document.getElementById('map-container').innerHTML = `
      <div class="flex flex-col h-full map-placeholder text-white justify-center items-center text-base">
        Something went wrong. Please try again later.
      </div>
    `
  }
}
