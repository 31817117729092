import Loader from './loader'
import ViewMode from './view_mode'
import EditMode from './edit_mode'
import BulkMoveMode from './bulk_move_mode'
import PlacementMode from './placement_mode'
import LeftPanel from './left_panel'
import RightPanel from './right_panel'
import Modal from './modal'
import Popup from  './popup'

import { useMapStore, useModeStore, useGeometryStore, useLockStore } from '../stores'

const initializeLockedMode = async (modeCallback) => {
  const lockStore = useLockStore()
  const modeStore = useModeStore()
  const geometryStore = useGeometryStore()
  const mapStore = useMapStore()

  modeStore.startLoading()
  const confirmed = await lockStore.confirmClaim()
  if (!confirmed) {
    if (confirmed == null) { return } // For a fetch error we stay and display the error modal
    return modeStore.enterPreviousMode()
  }
  const locked = await lockStore.tryLock(lockStore.claimLock, modeStore.enteringEditError)
  if (!locked) { return }
  const refreshed = await geometryStore.refreshFeatures(modeStore.enteringEditError)
  if (!refreshed) { return }

  modeCallback()
  lockStore.enablePageExitGuard()
  mapStore.map.once('idle', () => modeStore.stopLoading())
}

export {
  initializeLockedMode,
  Loader,
  ViewMode,
  EditMode,
  BulkMoveMode,
  PlacementMode,
  LeftPanel,
  RightPanel,
  Modal,
  Popup
}
