import * as Vue from 'vue/dist/vue.esm-bundler.js'
import draggable from 'vuedraggable'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore } from '../../../../../stores'
import { mapStores, mapState } from 'pinia'
import { Api } from '../../../../../api'
import { TrashIcon } from '@heroicons/vue/24/outline'
import template from './index.html'
import { constants } from '../../../../../constants'

export default {
  name: 'AddPerimeterForm',
  props: {
    addingInteriorPerimeters: {
      type: Boolean,
      default: false
    },
    preselectedSectionId: {
      type: String,
      default: undefined
    },
    preselectedGroupId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      sectionOptions: [],
      classificationOptions: [],
      perimeters: [],
      selectedSectionId: null,
      selectedClassification: null,
      prefilledPerimetersBySection: {},
      prefilledPerimetersBySectionAndGroup: {},
      isClosed: false
    }
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore),
    ...mapState(useModeStore, ['showLoader']),
    addingExteriorPerimeters() { return !this.addingInteriorPerimeters },
    target() { return this.addingInteriorPerimeters ? constants.targets.INTERIOR_PERIMETER : constants.targets.PERIMETER },
    groupLabel() { return this.preselectedGroupId === 'ungrouped' ? 'Ungrouped' : this.preselectedGroupId.slice(-5) },
    isSelectSectionDisabled() {
      if (this.addingInteriorPerimeters) { return true }
      if (this.addingExteriorPerimeters) { return this.sectionOptions.length === 0 }
    },
    isSelectClassificationDisabled() { return this.hasExistingPerimeters(this.selectedSectionId) },
    isAddPerimeterDisabled() { return !this.selectedClassification || this.hasExistingPerimeters(this.selectedSectionId) },
    isDragDisabled() { return this.hasExistingPerimeters(this.selectedSectionId) },
    isSubmitDisabled() { return !this.selectedSectionId || !this.perimeters.length > 0 }
  },
  methods: {
    addPerimeter() {
      this.perimeters.push({
        classificationId: this.selectedClassification.id,
        classificationName: this.selectedClassification.name,
        perimeterId: null
      })
      this.selectedClassification = null
    },
    removePerimeter(index){
      !this.isDragDisabled && this.perimeters.splice(index, 1)
    },
    closeModal() {
      this.mapStore.closeModal()
    },
    handleSubmit() {
      this.closeModal()
      const perimetersWithFinalOrder = this.perimeters.map((perimeter, i) =>({...perimeter, sortOrder: (i + 1) * 100 }))
      const perimetersShouldBeClosed = this.addingExteriorPerimeters || this.isClosed
      this.modeStore.enterPlacementMode(this.selectedSectionId, perimetersWithFinalOrder, perimetersShouldBeClosed, this.target)
    },
    hasExistingPerimeters(sectionId) {
      if (this.addingExteriorPerimeters) { return this.prefilledPerimetersBySection[sectionId]?.length > 0 }
      if (this.addingInteriorPerimeters) {
        return this.prefilledPerimetersBySectionAndGroup[sectionId]?.[this.preselectedGroupId]?.length > 0
      }
    },
    handleSectionChange() {
      this.perimeters = []
      this.selectedClassification = null
      this.refreshPerimeters()
    },
    prefillData() {
      this.selectedSectionId = this.preselectedSectionId
      this.refreshPerimeters()
    },
    refreshPerimeters() {
      this.perimeters = []
      if (this.hasExistingPerimeters(this.selectedSectionId)) {
        let prefilledPerimeters
        if (this.addingExteriorPerimeters) { prefilledPerimeters = this.prefilledPerimetersBySection[this.selectedSectionId] }
        if (this.addingInteriorPerimeters) {
          prefilledPerimeters = this.prefilledPerimetersBySectionAndGroup[this.selectedSectionId]?.[this.preselectedGroupId]
        }

        this.perimeters = prefilledPerimeters.map(perimeter => {
          return {
            classificationId: perimeter.classification_id,
            classificationName: perimeter.classification_name,
            perimeterId: perimeter.id
          }
        })
      }
    }
  },
  async beforeMount() {
    this.modeStore.startLoading()
    const response = await Api.PerimeterSegments.addOptions({
      preselectedSectionId: this.preselectedSectionId,
      target: this.target
    })

    if (response.errored()) {
      this.modeStore.loadingFormError()
      this.closeModal()
      return
    }

    this.sectionOptions = response.data.section_options
    this.classificationOptions = response.data.classification_options
    this.prefilledPerimetersBySection = response.data.prefilled_perimeters_by_section
    this.prefilledPerimetersBySectionAndGroup = response.data.prefilled_perimeters_by_section_and_group

    if (this.preselectedSectionId) { this.prefillData() }

    this.modeStore.stopLoading()
  },
  components: { TrashIcon, draggable },
  template: template
}
