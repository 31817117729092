<div v-if="!showLoader" class="flex flex-col items-center justify-center absolute h-full w-full bg-opacity-70 z-50 bg-gray-900">
  <form @submit.prevent="handleSubmit" class="bg-white shadow-md p-4 rounded-lg flex flex-col h-96 w-96">
    <span class="font-semibold">Change Classification Type</span>
    <label for="classification" class="mt-4 block text-sm font-medium text-gray-dark">Section</label>
    <label for="classification" class="text-sm text-gray-900">{{ selectedFeature.properties.section_code }}</label>
    <label for="classification" class="mt-4 block text-sm font-medium text-gray-dark">Classification</label>
    <select v-model="selectedClassificationId" id="classificationSelect" class="border-gray-light focus:border-blue focus:ring-blue placeholder-gray-light appearance-none block border focus:outline-none px-3 py-2 rounded-md shadow-sm sm:text-sm w-full">
      <option v-for="classification in classificationOptions" :key="classification.id" :value="classification.id">
        {{ classification.name }}
      </option>
    </select>
    <div class="flex justify-end mt-4 mt-auto">
      <button @click="closeModal" class="white-button">Cancel</button>
      <input type="submit" name="commit" value="Save" class="ml-3 blue-button">
    </div>
  </form>
</div>
