import * as Turf from '@turf/turf'
import polylabel from 'polylabel'
import ZLayers from '../../../../z_layers'
import colors from '../../../../colors'

const id = 'section-labels'
const haloColor = (areWarrantiesVisible) => {
  if (areWarrantiesVisible) {
    return ['case', ['==', ['get', 'has_active_warranty'], true], colors.neon.BLUE, colors.WHITE]
  } else {
    return colors.WHITE
  }
}

const SectionLabels = {}

SectionLabels.load = function() {
  const centers = this.perimeters_by_section.map((section) => {
    const perimeterCoords = section.perimeters.map(p => p.geometry.data.geometry.coordinates).flat()
    const centerCoords = polylabel([perimeterCoords], 0.000001)
    const center = Turf.point(centerCoords)
    center.id = section.id
    center.properties = {
      id: section.id,
      label_text: section.code,
      has_active_warranty: section.has_active_warranty
    }
    return center
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(centers)
  })

  this.map.addLayer({
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'text-field': ['get', 'label_text'],
      'text-radial-offset': 1,
      'text-variable-anchor': ['center', 'top', 'bottom', 'left', 'right'],
      'visibility': 'visible'
    },
    'paint': {
      'text-color': colors.BLACK,
      'text-halo-color': haloColor(false),
      'text-halo-width': 2,
      'text-opacity-transition': {
        duration: 0,
        delay: 0
      }
    }
  }, ZLayers.myPosition.call(this, id))

  this.map.on('custom.toggleVisibility', ({ layerId, checked }) => {
    if (layerId !== id) { return }

    // For this specific layer the oppacity is toggled instead of the visibility
    // because the visbility property has a fade in effect that is not desired
    const currentOpacity = this.map.getPaintProperty(id, 'text-opacity')
    const newOpacity = currentOpacity === 0 ? 1 : 0
    this.map.setPaintProperty(id, 'text-opacity', newOpacity)
  })
}

SectionLabels.refresh = function() {
  const areWarrantiesVisible = this.map.getLayoutProperty('warranties', 'visibility') === 'visible'
  this.map.setPaintProperty(id, 'text-halo-color', haloColor(areWarrantiesVisible))
}

export default SectionLabels
