import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapState } from 'pinia'
import { useMapStore } from '../../stores/map_store/index.js'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import template from './index.html'

export default {
  name: 'LeftPanel',
  data() {
    return {
      visible: false
    }
  },
  components: { XMarkIcon },
  computed: {
    ...mapState(useMapStore, ['leftPanelTitle', 'leftPanelComponent', 'leftPanelSubMenuComponent', 'leftPanelSubMenuProps'])
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible
    }
  },
  template: template
}
