import { tokenHeader, surveyId } from '.'
import { ApiClient } from './api_client'

const baseURL = (id) => `/admin/api/surveys/${surveyId()}/defects${id ? `/${id}` : ''}`

const destroy = async ({ id }) => {
  return await ApiClient.makeRequest({
    url: baseURL(id),
    method: 'DELETE',
    ...tokenHeader()
  })
}

export {
  destroy
}
