import { useLockStore } from "../stores"
import { tokenHeader, surveyId } from '.'
import { ApiClient } from "./api_client"

const baseUrl = () => `/admin/api/surveys/${surveyId()}/geometries`

const geometryByType = async (type) => {
  return await ApiClient.makeRequest({
    url: `${baseUrl()}?type=${type}`,
    method: 'GET',
    ...tokenHeader()
  })
}

const saveAllFeatures = async (features) => {
  return await ApiClient.makeRequest({
    url: `${baseUrl()}/save_features`,
    method: 'POST',
    body: JSON.stringify({ features }),
    headers: {
      'Content-Type': 'application/json',
      ...tokenHeader().headers
    }
  })
}

const destroy = async ({ id }) => {
  return await ApiClient.makeRequest({
    url: `${baseUrl()}/${id}`,
    method: 'DELETE',
    ...tokenHeader()
  })
}

const destroyAll = async ({ sectionId, target, groupId }) => {
  const encodedSectionId = encodeURIComponent(sectionId)
  const encodedTarget = encodeURIComponent(target)
  let url = `${baseUrl()}/destroy_all?section_id=${encodedSectionId}&target=${encodedTarget}`
  if (groupId) { url += `&group_id=${encodeURIComponent(groupId)}` }
  return await ApiClient.makeRequest({
    url: url,
    method: 'DELETE',
    ...tokenHeader()
  })
}

export {
  geometryByType,
  saveAllFeatures,
  destroy,
  destroyAll
}
