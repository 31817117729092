import _ from 'lodash'

import { useGeometryStore } from '../../stores/geometry_store'
import { useMapStore } from '../../stores/map_store'
import { useLegendStore } from '../../stores/legend_store'

class LegendBuilder {
  constructor() {
    this.geometryStore = useGeometryStore()
    this.mapStore = useMapStore()
    this.legendStore = useLegendStore()
    this.location = this.mapStore.location
    this.geometriesFeatureCollection = this.buildGeometriesFeatureCollection()
  }

  buildGeometriesFeatureCollection() {
    const allGeometryFeatures = this.geometryStore.types.reduce((result, type) => {
      const features = this.geometryStore.featuresWithExtendedProps(_.camelCase(type), { includeEmptyGeometry: true })
      const featureProperties = features.map((f) => {

        return {
          ...f.properties,
          featureId: f.featureId,
          recordId: f.recordId,
          featureType: type,
          noGeometry: !(f.geometry?.coordinates.length >= 1)
        }
      })

      result.push(...featureProperties)
      return result
    }, [])

    const geometriesFeatureCollection = this.groupAndSortFeatures(
      allGeometryFeatures,
      'section_id',
      'section_code'
    )

    return geometriesFeatureCollection
  }

  buildLegendItemTree() {
    const id = this.location.id
    const items = this.geometriesFeatureCollection.map((featureSet) => this.buildSectionItems(featureSet, id))

    return [
      {
        label: this.location.name,
        checkboxValue: this.checkboxValueCheck(_.flatten(this.geometriesFeatureCollection)),
        indeterminateValue: this.indeterminateValueCheck(_.flatten(this.geometriesFeatureCollection)),
        id: id,
        parentId: null,
        type: 'location',
        layer: null,
        showNestedItems: this.legendStore.getShowNestedItemState(id, true),
        contextMenu: [
          { name: 'Details', linkTo: this.legendStore.urls.location_path },
        ],
        items: items,
        disabled: items.length === 0
      }
    ]
  }

  buildSectionItems(featureSet, parentId) {
    const id = featureSet[0].section_id

    return {
      label: featureSet[0].section_code,
      checkboxValue: this.checkboxValueCheck(featureSet, false),
      indeterminateValue: this.indeterminateValueCheck(featureSet),
      id: id,
      parentId: parentId,
      type: 'section',
      layer: null,
      showNestedItems: this.legendStore.getShowNestedItemState(id, false),
      contextMenu: [
        { name: 'Details', linkTo: this.handleSectionPath(id) },
      ],
      items: [
        this.buildFeatureItems(featureSet, "PerimeterSegments", "Perimeter", id),
        this.buildInteriorPerimeterGroups(featureSet, id),
        this.buildFeatureItems(featureSet, "Defects", "Defects", id),
        this.buildFeatureItems(featureSet, "Inventories", "Inventory", id)
      ],
      disabled: false
    }
  }

  buildFeatureItems(featureSet, type, headerName, parentId) {
    let featureSetByType = this.filterAndSortFeatures(featureSet, type)

    if (headerName === "Perimeter") {
      featureSetByType = featureSetByType.filter((f) => f.classification_target.toLowerCase() === "perimeter")
    } else if (headerName === "Interior Perimeter") {
      featureSetByType = featureSetByType.filter((f) => f.classification_target.toLowerCase() === "interior perimeter")
    }

    const id = `${_.kebabCase(headerName)}-section-${parentId}`
    const items = this.handleParentClassification(featureSetByType, type, id)

    return {
      label: headerName,
      checkboxValue: this.checkboxValueCheck(featureSetByType),
      indeterminateValue: this.indeterminateValueCheck(featureSetByType),
      id: id,
      parentId: parentId,
      type: _.kebabCase(type),
      layer: _.kebabCase(type),
      showNestedItems: this.legendStore.getShowNestedItemState(id, false),
      contextMenu: [
        { name: 'Details', linkTo: this.handleFeatureItemsPath(type, parentId) },
      ],
      items: items,
      disabled: items.length === 0
    }
  }

  buildInteriorPerimeterGroups(featureSet, parentId) {
    const id = `interior-perimeter-section-${parentId}`
    const interiorPerimeters = this.filterAndSortFeatures(featureSet, 'PerimeterSegments').filter((f) => f.classification_target == "Interior Perimeter")
    const groups = _.groupBy(interiorPerimeters, 'group_id')
    let items = []

    _.forEach(groups, (groupedPerimeters, key) => {
      const label = key === 'null' ? 'Ungrouped' : key.slice(-5)
      const groupId = `${label}-${id}`
      items.push({
        label: label,
        checkboxValue: this.checkboxValueCheck(groupedPerimeters),
        indeterminateValue: this.indeterminateValueCheck(groupedPerimeters),
        id: groupId,
        parentId: id,
        type: 'interior-perimeter-group',
        layer: 'perimeter-segments',
        geometryType: 'Interior Perimeter',
        showNestedItems: this.legendStore.getShowNestedItemState(groupId, false),
        contextMenu: [],
        items: this.handleParentClassification(groupedPerimeters, 'PerimeterSegments', groupId),
        disabled: false,
        properties: { sectionId: groupedPerimeters[0].section_id, groupId: key === 'null' ? null : key }
      })
    })

    return {
      label: 'Interior Perimeter',
      checkboxValue: this.checkboxValueCheck(interiorPerimeters),
      indeterminateValue: this.indeterminateValueCheck(interiorPerimeters),
      id: id,
      parentId: parentId,
      type: 'interior-perimeter-segments',
      layer: 'perimeter-segments',
      showNestedItems: this.legendStore.getShowNestedItemState(id, false),
      contextMenu: [
        { name: 'Details', linkTo: this.handleFeatureItemsPath('PerimeterSegments', parentId) }
      ],
      items: items,
      disabled: items.length === 0
    }
  }

  handleParentClassification(featureSetByType, type, parentId) {
    if (type === "Inventories") {
      const classificationParent = this.groupAndSortFeatures(
        featureSetByType,
        'parent_classification_id',
        'classification_name'
      )
      return classificationParent.map((classification) => this.buildParentClassificationItems(classification, parentId, type))
    } else {
      return featureSetByType.map((feature) => this.buildGeometryItems(feature, parentId, type))
    }
  }

  buildParentClassificationItems(classification, parentId, type) {
    const id = `${classification[0].parent_classification_id}-section-${classification[0].section_id}`
    const items = classification.map((feature) => this.buildGeometryItems(feature, id, type))

    return {
      label: classification[0].parent_classification_name,
      checkboxValue: this.checkboxValueCheck(classification),
      indeterminateValue: this.indeterminateValueCheck(classification),
      id: id,
      parentId: parentId,
      type: 'parent_classification',
      layer: _.kebabCase(type),
      showNestedItems: this.legendStore.getShowNestedItemState(id, false),
      contextMenu: [],
      items: items,
      disabled: items.length === 0
    }
  }

  buildGeometryItems(feature, parentId, type) {
    const id = feature.featureId || feature.recordId
    this.handleNoGeometry(feature, id)
    this.buildLegendItemsCounterByType(feature, id)

    return {
      label: feature.classification_name,
      checkboxValue: feature.visible,
      indeterminateValue: false,
      id: id,
      recordId: feature.recordId,
      parentId: parentId,
      type: 'geometry',
      layer: _.kebabCase(type),
      geometryType: this.handleGeometryType(feature),
      showNestedItems: this.legendStore.getShowNestedItemState(id, false),
      contextMenu: [
        { name: 'Details', linkTo: this.handleGeometryItemsPath(feature.featureType, feature.recordId) },
      ],
      items: [],
      disabled: false,
      classificationId: feature.classification_id,
      properties: { sectionId: feature.section_id, groupId: feature.group_id }
    }
  }

  buildTypeEntities() {
    return [
      {
        id: 'perimeter-section',
        label: 'Perimeter',
        geometryType: 'perimeter',
        checkboxValue: this.checkboxValueCheck(this.getItemsByType('PerimeterSegments', 'Perimeter')),
        indeterminateValue: this.indeterminateValueCheck(this.getItemsByType('PerimeterSegments', 'Perimeter')),
      },
      {
        id: 'interior-perimeter-section',
        label: 'Interior Perimeter',
        geometryType: 'interiorPerimeter',
        checkboxValue: this.checkboxValueCheck(this.getItemsByType('PerimeterSegments', 'Interior Perimeter')),
        indeterminateValue: this.indeterminateValueCheck(this.getItemsByType('PerimeterSegments', 'Interior Perimeter')),
      },
      {
        id: 'defects-section',
        label: 'Defects',
        geometryType: 'defects',
        checkboxValue: this.checkboxValueCheck(this.getItemsByType('Defects')),
        indeterminateValue: this.indeterminateValueCheck(this.getItemsByType('Defects')),
      },
      {
        id: 'inventory-section',
        label: 'Inventory',
        geometryType: 'inventories',
        checkboxValue: this.checkboxValueCheck(this.getItemsByType('Inventories')),
        indeterminateValue: this.indeterminateValueCheck(this.getItemsByType('Inventories')),
      }
    ]
  }

  buildLegendItemsCounterByType(feature, id) {
    const type = this.handleGeometryType(feature)

    if (!this.legendStore.noVisibilityIds.includes(id)) {
      this.legendStore.legendItemTypes['checkedCount'][type] += 1
    }

    this.legendStore.legendItemTypes['totalCount'][type] += 1
    this.legendStore.legendItemTypes['ids'][type].push(id)
  }

  handleGeometryType(feature) {
    return (feature.featureType === 'PerimeterSegments')
      ? _.camelCase(feature.classification_target)
      : _.camelCase(feature.featureType)
  }

  getItemsByType(type, label = null) {
    let itemsByType = _.flatMap(this.geometriesFeatureCollection, (featureSet) => this.filterAndSortFeatures(featureSet, type))

    if (label === "Perimeter") {
      itemsByType = itemsByType.filter((f) => f.classification_target.toLowerCase() === "perimeter")
    } else if (label === "Interior Perimeter") {
      itemsByType = itemsByType.filter((f) => f.classification_target.toLowerCase() === "interior perimeter")
    }

    return itemsByType
  }

  groupAndSortFeatures(features, groupBy, sortBy) {
    return _.chain(features)
      .groupBy(groupBy)
      .sortBy((featureSet) => featureSet[0][sortBy])
      .value()
  }

  filterAndSortFeatures(featureSet, filterBy, sortBy = 'classification_name') {
    return _.chain(featureSet)
      .filter((f) => f.featureType === filterBy)
      .sortBy(sortBy)
      .value()
  }

  checkboxValueCheck(array) {
    return array.every(item => item.visible)
  }

  indeterminateValueCheck(array) {
    return this.checkboxValueCheck(array) ? false : array.some(item => item.visible)
  }

  handleSectionPath(id) {
    const stringToReplace = encodeURIComponent('{section_id}')
    return _.replace(this.legendStore.urls.section_path, stringToReplace, id)
  }

  handleFeatureItemsPath(type, sectionId) {
    const typeToPathMap = {
      PerimeterSegments: this.legendStore.urls.perimeter_segments_path,
      Inventories: this.legendStore.urls.inventories_path,
      Defects: this.legendStore.urls.defects_path
    }

    const path = typeToPathMap[type]
    const stringToReplace = encodeURIComponent('{section_id}')
    return _.replace(path, stringToReplace, sectionId)
  }

  handleGeometryItemsPath(type, id) {
    const typeToPathMap = {
      PerimeterSegments: this.legendStore.urls.perimeter_segment_path,
      Inventories: this.legendStore.urls.inventory_path,
      Defects: this.legendStore.urls.defect_path
    }

    const path = typeToPathMap[type]
    const stringToReplace = encodeURIComponent('{feature_id}')
    return _.replace(path, stringToReplace, id)
  }

  handleNoGeometry(feature, id) {
    if (feature.noGeometry && !_.includes(this.legendStore.noGeometryIds, id)) {
      this.legendStore.noGeometryIds.push(id)
    }
  }
}

export default LegendBuilder

// Structure of object
// {
//   label: name of header,
//   checkboxValue: false,
//   indeterminateValue: false,
//   id: some id,
//   parentId: some id
//   type: some type
//   layer: some layer
//   showNestedItems: false,
//   contextMenu: [],
//   items: []  -> nested items go here,
//   disabled: false
// }
