import _ from 'lodash'
import MapboxGL from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import GUI from 'lil-gui'
import * as Turf from '@turf/turf'

import ZLayers from '../../../../../z_layers'
import { useGeometryStore, useModeStore } from '../../stores'
import { constants } from '../../constants'

const initMap = function (args) {
  this.$patch({ location: args.location, survey: args.survey })
  this.$patch({
    provider: args.provider,
    mapboxConfig: {
      accessToken: args.mapboxToken,
      center: this.locationCoords,
      minZoom: 15
    }
  })
  const urlParams = new URLSearchParams(window.location.search)

  this.map = new MapboxGL.Map(this.mapboxConfig)
  this.draw = new MapboxDraw(this.mapboxDrawConfig)

  this.map.addControl(new MapboxGL.NavigationControl(), 'bottom-right')
  this.map.addControl(this.draw, 'bottom-right')

  this.menu = new GUI({ container: document.querySelector('#map-container') })
  this.menu.close()
  this.menu.onFinishChange(this.saveSettings)

  toggleTrashButton(false)
}

const boundMap = function (meters) {
  const dir = { north: 0, east: 90, south: 180, west: 270 }
  const options = { units: 'meters' }
  let center = Turf.point(this.mapboxConfig.center)

  let southWest = Turf.destination(center, meters / 2, dir.south, options)
  southWest = Turf.destination(southWest, meters / 2, dir.west, options)

  let northEast = Turf.destination(center, meters / 2, dir.north, options)
  northEast = Turf.destination(northEast, meters / 2, dir.east, options)

  this.map.setMaxBounds([southWest.geometry.coordinates, northEast.geometry.coordinates])
}

const switchMapProvider = function(args) {
  if (args.provider == 'azure') {
    this.map.removeLayer('satellite')
    this.map.removeLayer('background')
    this.map.removeSource('mapbox-satellite')

    this.map.addSource('azure-maps-satellite', {
      'type': 'raster',
      'tiles': [
        `https://atlas.microsoft.com/map/imagery/png?subscription-key=${args.azureKey}&api-version=1.0&style=satellite&zoom={z}&x={x}&y={y}`
      ],
      'tileSize': 256,
      'attribution': '© Microsoft, Azure Maps'
    })

    this.map.addLayer({
      'id': 'custom-satellite',
      'type': 'raster',
      'source': 'azure-maps-satellite'
    }, ZLayers.myPosition.call(this, 'custom-satellite'))
  } else if (args.provider == 'eagleview') {
    this.map.addSource('eagleview-satellite', {
      'type': 'raster',
      'tiles': [
        `https://apis.eagleview.com/imagery/wmts/v1/visual/tile/Latest/default/GoogleMapsCompatible/{z}/{x}/{y}.png?api_key=${args.eagleviewKey}`
      ],
      'tileSize': 256,
      'attribution': '© Eagleview or © Mapbox'
    })

    this.map.addLayer({
      'id': 'custom-satellite',
      'type': 'raster',
      'source': 'eagleview-satellite'
    }, ZLayers.myPosition.call(this, 'custom-satellite'))
  }
}

const hideMenu = function () {
  const el = document.querySelector('.lil-gui.root')
  el.style.display = "none"
}

const showMenu = function () {
  const el = document.querySelector('.lil-gui.root')
  el.style.display = ""
}

const addViewSource = function (name) {
  const sourceName = _.kebabCase(name)
  const geometryStore = useGeometryStore()

  this.map.addSource(sourceName, {
    type: 'geojson',
    data: geometryStore.featureCollection(name, { includeEmptyGeometry: true }),
  })
}

const removeViewSource = function (name) {
  const kebabName = _.kebabCase(name)
  if (this.map.getSource(kebabName)) { this.map.removeSource(kebabName) }
}

const setViewSource = function(name, collection) {
  const source = this.map.getSource(name)
  source.setData(collection)
}

const addViewLayers = function (name) {
  this.layerConfigs(name).forEach((config) => { this.map.addLayer(config, ZLayers.myPosition.call(this, 'view-layers')) })
}

const removeViewLayers = function(name) {
  this.layerNames(name).forEach((id) => {
    if (this.map.getLayer(id)) { this.map.removeLayer(id) }
  })
}

const removeSourceFeature = function(name, featureId) {
  const source = this.map.getSource(_.kebabCase(name))
  const filteredFeatures = source._data.features.filter((f) => f.id !== featureId)
  source.setData(Turf.featureCollection(filteredFeatures))
}

const showLayer = function (id) { this.map.setLayoutProperty(id,'visibility', 'visible') }
const hideLayer = function (id) { this.map.setLayoutProperty(id,'visibility', 'none') }

const setViewColor = function (layerName, color) {
  this.layerNames(layerName).forEach((name) => {
    const layer = this.map.getLayer(name)
    this.colorProps(layer.type).forEach((prop) => {
      this.map.setPaintProperty(layer.id, prop, color)
    })
  })
}

const setLineWidth = function(layerName, value) {
  this.layerNames(layerName, ['line']).forEach((name) => {
    const layer = this.map.getLayer(name)
    this.map.setPaintProperty(layer.id, 'line-width', value)
  })
}

const setPointRadius = function(layerName, value) {
  this.layerNames(layerName, ['circle']).forEach((name) => {
    const layer = this.map.getLayer(name)
    this.map.setPaintProperty(layer.id, 'circle-radius', value)
  })
}

const addMenuFolder = function (name, opts) {
  const title = _.startCase(name)
  const idx = _.indexOf(_.map(this.menu.folders, '_title'), title)
  if (idx >= 0) { return this.menu.folders[idx] }

  const folder = this.menu.addFolder(title)
  folder.close()

  const config = {
    displayColor: this.colors.line[opts.color],
    lineWidth: opts.lineWidth,
    pointRadius: opts.pointRadius,
    vertexRadius: opts.vertexRadius
  }

  folder.add(config, 'displayColor', this.colors.line).name('display color')
  folder.add(config, 'lineWidth', 1, 10, 1).name('line width')
  folder.add(config, 'pointRadius', 1, 10, 1).name('point radius')
  folder.add(config, 'pointRadius', 1, 10, 1).name('line / vertex radius')
  return folder
}

const addMenuFolderCallback = function (folder, name, callbacks) {
  folder.controllers[0].onChange(value => {
    this.$patch({ layers: { [name]: { color: this.colorName(value) } } })
    if (callbacks.displayColorCallback) { callbacks.displayColorCallback(name, value) }
  })

  folder.controllers[1].onFinishChange(value => {
    this.$patch({ layers: { [name]: { lineWidth: value } } })
    if (callbacks.lineWidthCallback) { callbacks.lineWidthCallback(name, value) }
  })

  folder.controllers[2].onFinishChange(value => {
    this.$patch({ layers: { [name]: { pointRadius: value } } })
    if (callbacks.pointRadiusCallback) { callbacks.pointRadiusCallback(name, value) }
  })

  folder.controllers[3].onFinishChange(value => {
    this.$patch({ layers: { [name]: { vertexRadius: value } } })
    if (callbacks.vertexRadiusCallback) { callbacks.vertexRadiusCallback(name, value) }
  })
}

const addMenuFolders = function (callbacks) {
  _.entries(this.layers).forEach(([name, opts]) => {
    const folder = this.addMenuFolder(name, opts)
    this.addMenuFolderCallback(folder, name, callbacks)
  })

  if (!_.includes(_.map(this.menu.folders, '_title'), 'Settings')) {
    const folder = this.menu.addFolder('Settings').close()
    folder.add({ resetSettings: this.resetSettings }, 'resetSettings').name('Reset')

    const pointOfInterestOptions = { visible: false }
    const pointOfInterestController = folder.add(pointOfInterestOptions, 'visible').name('show points of interest')
    const setCompositeLayersVisibility = (value) => {
      this.map.getStyle().layers
        .filter(({ source }) => source === 'composite')
        .forEach(({ id }) => this.map.setLayoutProperty(id, 'visibility', value ? 'visible' : 'none'))
    }

    setCompositeLayersVisibility(pointOfInterestOptions.visible)
    pointOfInterestController.onChange(value => setCompositeLayersVisibility(value))
  }
}

const addDrawFeatures = function () {
  const geometryStore = useGeometryStore()
  _.keys(this.layers).sort().forEach(name => { this.draw.add(geometryStore.featureCollection(name)) })
}

const setDrawFeaturesProperty = function (layerName, propertyName, propertyValue) {
  const geometryStore = useGeometryStore()
  const featureIds = geometryStore.featureIds(layerName)
  featureIds.forEach((id) => {
    this.draw.setFeatureProperty(id, propertyName, propertyValue)
    const feature = this.draw.get(id)
    this.draw.add(feature)
  })
}

const addDrawListener = function(event, callback) { this.map.on(event, callback) }

const removeDrawListener = function(event, callback) { this.map.off(event, callback) }

const repositionDrawLayers = function () {
  this.map.getStyle().layers
    .filter(({ id }) => id.startsWith('gl-draw'))
    .forEach(({ id }) => this.map.moveLayer(id, ZLayers.myPosition.call(this, 'draw-layers')))
}

const repositionPOILayers = function () {
  this.map.getStyle().layers
    .filter(({ source }) => source === 'composite')
    .forEach(({ id }) => this.map.moveLayer(id, ZLayers.myPosition.call(this, 'pois')))
}

const rightClickCallback = function(e) {
  const rightClickedFeatures = this.getRenderedFeatures(e.point)

  if (rightClickedFeatures.length) {
    this.popupData = { clickedFeatures: rightClickedFeatures, lngLat: e.lngLat }
  } else {
    this.popupData = null
  }
}

const toggleTrashButton = function(canDelete) {
  const trashButton = document.querySelector('.mapbox-gl-draw_trash')
  if (trashButton) {
    if (canDelete) {
      trashButton.disabled = false
      trashButton.classList.remove('opacity-50')
    } else {
      trashButton.disabled = true
      trashButton.classList.add('opacity-50')
    }
  }
}

const applyGridConfig = function() {
  this.grid.applyConfig()
}

const recenterGrid = function(coords) {
  this.grid.config.center = coords
}

const centerOnFeature = function() {
  const geometryStore = useGeometryStore()
  const urlParams = new URLSearchParams(window.location.search)
  const focusId = urlParams.get('focus-geometry-id')

  this.deepLinkedFeature =
    geometryStore.inventories[focusId] ||
    geometryStore.defects[focusId] ||
    geometryStore.perimeterSegments[focusId]

  if (!this.deepLinkedFeature) {
    console.error('There is no geometry record with the specified ID')
    return false
  }

  const geometry = this.deepLinkedFeature.data.geometry
  const coordinates = geometry.coordinates
  const isPoint = geometry.type === MapboxDraw.constants.geojsonTypes.POINT
  const isLineString = geometry.type === MapboxDraw.constants.geojsonTypes.LINE_STRING
  const isPolygon = geometry.type === MapboxDraw.constants.geojsonTypes.POLYGON
  let arrayWithAllVertices
  if (isPoint) { arrayWithAllVertices = [coordinates] }
  if (isLineString) { arrayWithAllVertices = coordinates }
  if (isPolygon) { arrayWithAllVertices = coordinates[0] }

  // Pass the first coordinates in the Geometry to `LngLatBounds` &
  // wrap each coordinate pair in `extend` to include them in the bounds.
  let bounds = arrayWithAllVertices.reduce(
    (bounds, coord) => bounds.extend(coord),
    new MapboxGL.LngLatBounds(arrayWithAllVertices[0], arrayWithAllVertices[0])
  )

  // An issue with Azure Maps causes tiles to not render when zooming
  // beyond level 18 using fitBounds, unless animation is enabled
  const enableAnimation = this.provider == 'azure'
  this.map.fitBounds(bounds, { padding: 100, animate: enableAnimation })

  return true
}

const saveSettings = function() {
  // When loading/resetting settings, a few onFinishChange events are triggered, which in turn execute the
  // saveSettings function. With this flag, we can prevent these unnecessary saves during loading/resetting.
  if (this.updatingSettings) return

  const menu = this.menu.save()
  const storedGrids = JSON.parse(localStorage.getItem('settings'))?.grids
  const grids = _.defaults({ [this.survey.id]: { center: this.grid.config.center, visible: this.grid.config.visible } }, storedGrids)
  localStorage.setItem('settings', JSON.stringify({ menu, grids }))
}

const loadSettings = function() {
  this.updatingSettings = true

  const settings = JSON.parse(localStorage.getItem('settings'))
  if (settings?.menu) { this.menu.load(settings.menu) }
  if (settings?.grids?.[this.survey.id]) {
    this.recenterGrid(settings.grids[this.survey.id].center)
    if (settings.grids[this.survey.id].visible) { this.applyGridConfig() }
  }

  this.updatingSettings = false
}

const resetSettings = function() {
  this.updatingSettings = true

  let grids
  const settings = JSON.parse(localStorage.getItem('settings'))

  if (settings?.grids) { grids = _.omit(settings.grids, [this.survey.id]) }

  if (_.isEmpty(grids)) {
    localStorage.removeItem('settings')
  } else {
    localStorage.setItem('settings', JSON.stringify({ grids }))
  }

  this.menu.reset()
  this.grid.remove()
  this.recenterGrid(this.mapboxConfig.center)

  this.updatingSettings = false
}

const closeModal = function() {
  this.$patch({
    showModal: false,
    modalComponent: null,
    modalComponentProps: null
  })
}

export {
  initMap,
  switchMapProvider,
  boundMap,
  hideMenu,
  showMenu,
  addViewSource,
  addViewLayers,
  removeViewSource,
  removeViewLayers,
  removeSourceFeature,
  setViewSource,
  showLayer,
  hideLayer,
  setViewColor,
  setLineWidth,
  setPointRadius,
  addMenuFolder,
  addMenuFolderCallback,
  addMenuFolders,
  addDrawFeatures,
  setDrawFeaturesProperty,
  addDrawListener,
  removeDrawListener,
  repositionDrawLayers,
  repositionPOILayers,
  toggleTrashButton,
  applyGridConfig,
  recenterGrid,
  saveSettings,
  loadSettings,
  resetSettings,
  rightClickCallback,
  centerOnFeature,
  closeModal
}
