import { mapState } from 'pinia'
import { useModeStore } from '../../stores'

import { InitializationError, SavingError, EnteringEditModeError, LoadingFormError, DeletingFeatureError } from './components'

export default {
  components: {
    InitializationError,
    SavingError,
    EnteringEditModeError,
    LoadingFormError,
    DeletingFeatureError
  },
  computed: {
    ...mapState(useModeStore, ['errors']),
    initializingError() { return this.errors.initializing },
    enterEditModeError() { return this.errors.enterEditMode },
    savingError() { return this.errors.saving },
    loadingFormError() { return this.errors.loadingForm },
    deletingFeatureError() { return this.errors.deletingFeature }
  },
  template: `
    <div data-test="loader" class="absolute flex items-center justify-center h-full w-full z-50 bg-gray-900">
      <InitializationError v-if="initializingError" />
      <SavingError v-else-if="savingError" />
      <EnteringEditModeError v-else-if="enterEditModeError" />
      <LoadingFormError v-else-if="loadingFormError" />
      <DeletingFeatureError v-else-if="deletingFeatureError" />
      <div v-else class="border-4 border-solid border-white animate-spin rounded-full w-20 h-20 border-t-4 border-t-blue-500"></div>
    </div>
  `
}
