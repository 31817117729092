import { loadIconsIntoMap } from '../../../../icon_loader'
import colors from '../../../../colors'
import MapPin from '../../../../../assets/images/icons/custom/map-pin-stroked.svg'

const svgSetFillColor = (svg, color) => svg.replace(/fill="[^"]*"/, `fill="${color}"`)
const svgSetStrokeColor = (svg, color) => svg.replace(/stroke="[^"]*"/, `stroke="${color}"`)
const svgSetStrokeWidth = (svg, width) => svg.replace(/stroke-width="[^"]*"/, `stroke-width="${width}"`)

const svgs = [
  {
    id: 'map-pin-red',
    data: svgSetFillColor(MapPin, colors.neon.RED)
  },
  {
    id: 'map-pin-blue',
    data: svgSetFillColor(MapPin, colors.neon.BLUE)
  },
  {
    id: 'map-pin-orange',
    data: svgSetFillColor(MapPin, colors.neon.ORANGE)
  },
  {
    id: 'map-pin-green',
    data: svgSetFillColor(MapPin, colors.neon.GREEN)
  },
  {
    id: 'map-pin-red-selected',
    data: svgSetStrokeWidth(svgSetStrokeColor(svgSetFillColor(MapPin, colors.neon.RED), colors.neon.YELLOW), 3)
  },
  {
    id: 'map-pin-blue-selected',
    data: svgSetStrokeWidth(svgSetStrokeColor(svgSetFillColor(MapPin, colors.neon.BLUE), colors.neon.YELLOW), 3)
  },
  {
    id: 'map-pin-orange-selected',
    data: svgSetStrokeWidth(svgSetStrokeColor(svgSetFillColor(MapPin, colors.neon.ORANGE), colors.neon.YELLOW), 3)
  },
  {
    id: 'map-pin-green-selected',
    data: svgSetStrokeWidth(svgSetStrokeColor(svgSetFillColor(MapPin, colors.neon.GREEN), colors.neon.YELLOW), 3)
  }

]

const Icons = {}

Icons.load = function() {
  return loadIconsIntoMap(this.map, svgs)
}

export default Icons
