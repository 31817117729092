import { Controller } from "@hotwired/stimulus"

import AerialMap from './aerial_map/app.js'

export default class extends Controller {
  static targets = ['map-container']

  static values = {
    propertyData: Object,
    mapboxToken: String,
    azureKey: String,
    eagleviewKey: String,
    mapProvider: String
  }

  connect() {
    const { property, perimeters_by_section, inventories, defects } = this.propertyDataValue
    window.app = new AerialMap(
      this.mapProviderValue,
      this.mapboxTokenValue,
      this.azureKeyValue,
      this.eagleviewKeyValue,
      property,
      perimeters_by_section,
      inventories,
      defects
    )

    window.app.mount('map-container')

    this.element.setAttribute('data-controller-connected', 'true')
  }
}
