import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapWritableState } from 'pinia'

import { useLegendStore } from '../../../../stores/legend_store'
import template from './index.html'

export default {
  name: 'EntityItem',
  props: {
    item: Object
  },
  data() {
    return {
      typeCounter: 0
    }
  },
  mounted() {
    this.typeCounter = this.legendItemTypes['totalCount'][this.item.geometryType]
  },
  computed: {
    ...mapWritableState(useLegendStore, ['legendItemTypes']),
    checkboxValue: {
      get() {
        return this.typeCounter === this.legendItemTypes['checkedCount'][this.item.geometryType]
      },
      set(value) {
        this.item.checkboxValue = value
      }
    },
    indeterminateValue: {
      get() {
        return !this.checkboxValue && this.legendItemTypes['checkedCount'][this.item.geometryType] !== 0
      },
      set(value) {
        this.item.indeterminateValue = value
      }
    },
    disabled() {
      return this.typeCounter === 0
    }
  },
  methods: {
    checkboxValueEntityChange(item) {
      this.$emit('checkbox-value-entity-change', item)
      this.handleTypeCounter()
    },
    handleTypeCounter() {
      if (this.item.checkboxValue) {
        this.legendItemTypes['checkedCount'][this.item.geometryType] = this.typeCounter
      } else {
        this.legendItemTypes['checkedCount'][this.item.geometryType] = 0
      }
    },
  },
  template: template
}
