import { Api } from '../../api'
import { useModeStore } from '../'

const tryLock = async function(lockCallback, errorCallback) {
  const response = await lockCallback()
  if (!response) { errorCallback() }
  return response
}

const fetchAuthToken = async function() {
  const response = await Api.UserToken.create()
  if (response.succeeded()) { this.authToken = response.data.token }

  return response
}

const fetchLock = async function() {
  const response = await Api.SurveyLocks.check()
  if (response.errored()) { return false }
  this.owner = response.data.locked_by
  this.since = response.data.locked_at
  if (this.owner) { this.myLock = response.data.locked_by_current_user }
  return true
}

const confirmClaim = async function() {
  const modeStore = useModeStore()
  const fetched = await this.tryLock(this.fetchLock, modeStore.enteringEditError)
  if (!fetched) { return null } // This signals that the fetchLock threw an error
  if (!this.owner) { return true }

  const msgKey = this.myLock ? 'myLockClaim' : 'otherLockClaim'
  return confirm(this.confirmations[this.mode][msgKey])
}

const claimLock = async function() {
  return (await Api.SurveyLocks.lock()).succeeded()
}

const confirmSave = async function() {
  const modeStore = useModeStore()
  const fetched = await this.tryLock(this.fetchLock, modeStore.savingError)
  if (!fetched) { return null } // This signals that the fetchLock threw an error
  if (!this.owner) { return true }
  if (this.myLock) { return true }

  return confirm(this.confirmations[this.mode].otherLockSave)
}

const releaseLock = async function() {
  return await this.unlockAndClear('saving')
}

const confirmDiscard = function() {
  return confirm(this.confirmations[this.mode].myLockDiscard)
}

const discardLock = async function() {
  return await this.unlockAndClear('exiting')
}

const unlockAndClear = async function(action) {
  const response = await Api.SurveyLocks.unlock(action)
  if (response.errored()) { return false }
  this.resetState()
  return true
}

const enablePageExitGuard = function() {
  window.addEventListener('beforeunload', this.beforeUnloadListener)
  window.addEventListener('pagehide', this.unloadListener, { capture: true, once: true })
}

const disablePageExitGuard = function() {
  window.removeEventListener('beforeunload', this.beforeUnloadListener)
  window.removeEventListener('pagehide', this.unloadListener, { capture: true, once: true })
}

const beforeUnloadListener = function(e) {
  e.preventDefault()
  return (e.returnValue = this.discardMessage)
}

const unloadListener = async function(e) {
  const useSendBeacon = true
  const response = await Api.SurveyLocks.unlock('exiting', useSendBeacon)
}

export {
tryLock,
fetchAuthToken,
fetchLock,
confirmClaim,
claimLock,
confirmSave,
releaseLock,
confirmDiscard,
discardLock,
unlockAndClear,
enablePageExitGuard,
disablePageExitGuard,
beforeUnloadListener,
unloadListener
}
