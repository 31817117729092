import _ from 'lodash'
import * as Turf from '@turf/turf'
import { mapStores, mapState, mapActions } from 'pinia'

import { useMapStore, useModeStore, useGeometryStore, useLockStore } from '../stores'
import { CancelButton, SaveAndExitButton, PreviewButton, ResetButton } from './buttons'
import { initializeLockedMode } from '.'
import ZLayers from '../../../../z_layers'
import colors from '../../../../colors'
import { Api } from '../api'
import { constants } from '../constants'

export default {
  name: 'PlacementMode',
  data() { return {
    perimetersWerePlaced: false
  } },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore, useLockStore),
    sortedPerimeters() { return this.modeStore.placement.perimeters },
    sideLength() {
      if (this.modeStore.placement.target === constants.targets.INTERIOR_PERIMETER) { return 15 }
      if (this.modeStore.placement.target === constants.targets.PERIMETER) { return 30 }
    }
  },
  methods: {
    enterMode() {
      document.querySelector('.mapboxgl-canvas-container').style.cursor = 'default'
      const color = this.currentPerimeterSegmentsColor()
      this.modeStore.stopLeftPanel()
      this.mapStore.hideMenu()

      this.mapStore.map.addSource('placement-static-sections', {
        type: 'geojson',
        data: Turf.featureCollection(this.geometryStore.features('perimeterSegments').filter(p => p != null))
      })
      this.mapStore.map.addSource('placement-blueprints', {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] }
      })

      this.mapStore.map.addLayer({
        id: 'placement-blueprints',
        source: 'placement-blueprints',
        type: 'line',
        paint: {
          'line-color': color,
          'line-width': 2
        },
      }, ZLayers.myPosition.call(this.mapStore, 'view-layers'))

      this.mapStore.map.addLayer({
        id: 'placement-blueprints-highlight',
        source: 'placement-blueprints',
        type: 'line',
        paint: {
          'line-color': color === colors.neon.YELLOW ? colors.neon.RED : colors.neon.YELLOW,
          'line-width': 6
        },
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        }
      }, 'placement-blueprints')

      this.mapStore.map.addLayer({
        id: 'placement-static-sections',
        source: 'placement-static-sections',
        type: 'line',
        paint: {
          'line-color': color,
          'line-width': 2
        }
      }, 'placement-blueprints-highlight')

      this.mapStore.map.on('click', this.placePerimeters)
      this.$parent.modeChanged()
    },
    currentPerimeterSegmentsColor() {
      const perimeterSegmentsFolder = this.mapStore.menu.children.find(folder => folder._title == 'Perimeter Segments')
      const colorOption = perimeterSegmentsFolder.children.find(option => option._name == 'display color')
      return colorOption.object.displayColor
    },
    exitMode() {
      this.mapStore.map.off('click', this.placePerimeters)
      this.modeStore.enterViewMode()
    },
    cancelCallback() { this.exitMode() },
    async saveCallback() {
      const features = this.mapStore.map.getSource('placement-blueprints')._data.features

      const payload = this.sortedPerimeters.map((perimeter) => {
        const feature = features.find((f) => f.properties.sort_order === perimeter.sortOrder)
        return {
          id: perimeter.perimeterId,
          classification_id: perimeter.classificationId,
          quantity: this.lengthInFeet(feature),
          sort_order: perimeter.sortOrder,
          geometry: {
            ...feature,
            properties: {}
          }
        }
      })

      const response = await Api.PerimeterSegments.upsert(
        this.modeStore.placement.sectionId,
        this.modeStore.placement.target,
        this.modeStore.placement.closed,
        payload
      )

      if (response.errored()) {
        this.modeStore.savingError()
        return false
      }

      this.exitMode()
    },
    lengthInFeet(feature) {
      const miles = Turf.length(feature.geometry, { units: 'miles' })
      const feet = Turf.convertLength(miles, 'miles', 'feet')
      return Math.ceil(feet)
    },
    closedShape(e) {
      const center = [e.lngLat.lng, e.lngLat.lat]
      const numberOfSides = this.sortedPerimeters.length <= 3 ? 3 : this.sortedPerimeters.length
      const coordinates = []

      // Calculate the radius that results in the desired side length
      const radius = this.sideLength / (2 * Math.sin(Math.PI / numberOfSides))

      for (let i = 0; i < numberOfSides; i++) {
        // Calculate the angle in 360 degrees, and convert it to -180 to 180 range (needed for Turf.destination)
        const angle = (360 / numberOfSides) * i
        const bearing = Turf.bearingToAzimuth(angle)
        const vertex = Turf.destination(center, radius, bearing, { units: 'feet' })
        coordinates.push(vertex.geometry.coordinates)
      }

      // Close the section
      coordinates.push(coordinates[0])

      const bluePrints = []
      // Create line segments and assign them to perimeters.
      // Note: The number of perimeters may be less than the sides needed for a valid polygon.
      // In such cases, the last perimeter might include multiple segments.
      this.sortedPerimeters.forEach(({ sortOrder }, i) => {
        const isLast = i === this.sortedPerimeters.length - 1

        const feature = isLast
          ? Turf.lineString([coordinates[i], ...coordinates.slice(i + 1)])
          : Turf.lineString([coordinates[i], coordinates[i + 1]])

        feature.properties = {
          blueprint: true,
          sort_order: sortOrder
        }

        bluePrints.push(feature)
      })

      return bluePrints
    },
    openedShape(e) {
      const start = [e.lngLat.lng, e.lngLat.lat]
      const numberOfLineStrings = this.sortedPerimeters.length
      const coordinates = [start]
      const angleA = 45
      const angleB = 135

      for (let i = 0; i < numberOfLineStrings; i++) {
        const angle = (i % 2 === 0) ? angleA : angleB
        const vertex = Turf.destination(coordinates[i], this.sideLength, angle, { units: 'feet' })
        coordinates.push(vertex.geometry.coordinates)
      }

      const bluePrints = []
      // Create line segments and assign them to perimeters.
      this.sortedPerimeters.forEach(({ sortOrder }, i) => {
        const feature = Turf.lineString([coordinates[i], coordinates[i + 1]])

        feature.properties = {
          blueprint: true,
          sort_order: sortOrder
        }

        bluePrints.push(feature)
      })

      return bluePrints
    },
    placePerimeters(e) {
      const bluePrints = this.modeStore.placement.closed ? this.closedShape(e) : this.openedShape(e)

      this.mapStore.setViewSource('placement-blueprints', Turf.featureCollection(bluePrints))
      this.perimetersWerePlaced = true
    }
  },
  async mounted() {
    if (this.sortedPerimeters.length === 0) { throw new Error('No perimeters to place') }
    await initializeLockedMode(this.enterMode)
  },
  unmounted() {
    document.querySelector('.mapboxgl-canvas-container').style.cursor = ''
    this.mapStore.map.removeLayer('placement-blueprints')
    this.mapStore.map.removeLayer('placement-blueprints-highlight')
    this.mapStore.map.removeLayer('placement-static-sections')
    this.mapStore.map.removeSource('placement-blueprints')
    this.mapStore.map.removeSource('placement-static-sections')
    this.lockStore.disablePageExitGuard()
    this.modeStore.startLeftPanel()
    this.mapStore.showMenu()
  },
  components: { CancelButton, SaveAndExitButton },
  template: `
  <div class="absolute top-2.5 bg-white -translate-x-2/4 left-2/4 flex flex-row justify-around space-x-4 pl-4 pr-4 pt-1 pb-1 rounded-lg shadow-md whitespace-nowrap border-black border-4">
    <div class="@apply flex items-center">
      <b>Place Perimeters:</b>
      <div class="text-sm ml-2">Choose the location by clicking on the map where the perimeters will be created. Dimensions can be adjusted later in edit mode</div>
    </div>
  </div>
  <div class='absolute left-2 bottom-8'>
    <SaveAndExitButton :disabled='!perimetersWerePlaced' :save-callback='saveCallback' />
    <CancelButton :cancel-callback='cancelCallback' />
  </div>
  `
}

