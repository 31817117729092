import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapActions, mapState } from 'pinia'
import { useModeStore } from '../../../../stores'

export default {
  name: 'SavingError',
  computed: {
    ...mapState(useModeStore, ['errorMessage']),
  },
  methods: {
    ...mapActions(useModeStore, ['clearError']),
    hideSavingError() { this.clearError('saving') }
  },
  template: `
    <div class="flex flex-col items-center justify-center">
      <p v-if="errorMessage === 'Invalid features'" class="text-white">Save failed due to invalid geometries.</p>
      <p v-else class="text-white">An error occurred during saving.</p>
      <button
        @click="hideSavingError()"
        class="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4 cursor-pointer"
      >
        Back to Map
      </button>
    </div>
  `
}
