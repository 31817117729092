import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accessType', 'companyId'];
  static values = { reloadPath: String };

  accessTypeChange() {
    if (this.reloadPathValue.includes('/edit')) {
      this.toggleLocationSelect();
    } else {
      this.refreshForm();
    }
  }

  toggleLocationSelect() {
    const accessType = this.accessTypeTarget.value;
    const locationSelectionContainer = document.getElementById('location-selection-container');
    const locationSelectionRadio = document.getElementById('location-selection-radio');

    if (accessType == 'client_user') {
      locationSelectionContainer.style.display = 'block';
      locationSelectionRadio.style.display = 'block';
    } else {
      locationSelectionContainer.style.display = 'none';
      locationSelectionRadio.style.display = 'none';
    }
  }

  companyIdChange() {
    this.refreshForm();
  }

  refreshForm() {
    const companyId = this.companyIdTarget.value;
    const accessType = this.accessTypeTarget.value;

    // Reload the form with company_id, access_type, and action attributes preserved
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('company_id', companyId);
    searchParams.set('access_type', accessType);
    window.location.href = this.reloadPathValue + '?' + searchParams.toString();
  }

  togglePropertiesOrTagsTable(event) {
    const propertiesTable = document.getElementById('properties-table');
    const tagsTable = document.getElementById('tags-table');

    if (event.target.value == 'tags') {
      tagsTable.style.display = 'block'
      propertiesTable.style.display = 'none'
    } else {
      tagsTable.style.display = 'none'
      propertiesTable.style.display = 'block'
    }
  }
}
