import _ from 'lodash'
import { length, area, convertLength, convertArea, point } from '@turf/turf'
import polylabel from 'polylabel'
import colors from '../../../../../colors'
import { useLegendStore } from '../'
import { constants } from '../../constants'

const cleanFeatureProperties = (features) => {
  const propertiesToKeep = ['id', 'name', 'legacy_point_ids', 'hole', 'perimeter_type']
  return features.map(feature => ({
    ...feature,
    properties: _.pick(feature.properties, propertiesToKeep)
  }))
}

const metersToFeet = (converter, meters) => {
  return _.round(converter(meters, 'meters', 'feet'))
}

const featureQuantity = (feature) => {
  let quantity = 0
  switch (feature.geometry.type) {
    case 'Point':
      quantity = 1
      break
    case 'LineString':
      quantity = metersToFeet(convertLength, length(feature) * 1000)
      break
    case 'Polygon':
      quantity = metersToFeet(convertArea, area(feature))
      break
  }
  return quantity
}

const featuresWithQuantities = (features) => {
  return features.map(feature => ({
    ...feature,
    properties: { ...feature.properties, quantity: featureQuantity(feature) }
  }))
}

const areConnectedInOrder = (curr, next) => _.isEqual(_.last(curr), _.first(next))
const areConnectedInAnyOrder = (prev, curr, next) => {
  return (
    isVertexConnected(_.first(curr), prev) && isVertexConnected(_.last(curr), next) ||
    isVertexConnected(_.first(curr), next) && isVertexConnected(_.last(curr), prev)
  )
}
const isVertexConnected = (vertex, ls) => _.isEqual(vertex, _.first(ls)) || _.isEqual(vertex, _.last(ls))

const getPolygonConnectionStatus = (features) => {
  // precedence: MISSING_GEOMETRIES > OUT_OF_ORDER > DISCONNECTED > CONNECTED

  if (features.length == 0 || features.some(f => f == null)) return constants.connectionStatuses.MISSING_GEOMETRIES

  const lineStringCoords = features.map(f => f.geometry.coordinates)
  let status = constants.connectionStatuses.CONNECTED

  for (var i = 0; i < lineStringCoords.length; i++) {
    const prev = lineStringCoords[i-1] || _.last(lineStringCoords)
    const curr = lineStringCoords[i]
    const next = lineStringCoords[i+1] || _.first(lineStringCoords)

    if (areConnectedInOrder(curr, next)) { continue }

    if (lineStringCoords.length == 1) { return constants.connectionStatuses.DISCONNECTED }

    if (areConnectedInAnyOrder(prev, curr, next) ) { return constants.connectionStatuses.OUT_OF_ORDER }

    status = constants.connectionStatuses.DISCONNECTED
  }

  return status
}

const closePolygon = (lineStrings) => {
  // Connects the LineStrings so that they form a closed polygon
  const updatedLineStrings = []

  for (var i = 0; i < lineStrings.length; i++) {
    const nextLineString = (i == lineStrings.length - 1) ? 0 : i + 1
    if (!areConnectedInOrder(lineStrings[i].geometry.coordinates, lineStrings[nextLineString].geometry.coordinates)) {
      lineStrings[i].geometry.coordinates.push(lineStrings[nextLineString].geometry.coordinates[0])
      updatedLineStrings.push(lineStrings[i])
    }
  }

  return updatedLineStrings
}

const keyByIdWithMissing = (geometries) => {
  let nullId = 1
  return _.keyBy(geometries, g => {
    // provide missing key for geometries with no id
    if (_.isNull(g.id)) { return `missing-${_.padStart(nullId++, 2, '0')}` }
    return g.id
  })
}

const perimeterCenter = (vertices, sectionId, sectionCode) => {
  if (!vertices || vertices.length == 0) return

  const centerCoords = polylabel([vertices], 0.000001)
  const center = point(centerCoords)
  center.id = sectionId
  const visible = useLegendStore().sectionVisibility[sectionId]
  center.properties = {
    section_code: sectionCode,
    visible: _.isUndefined(visible) ? true : visible
  }
  return center
}

const namedColorToColorCode = (colorName) => {
  if (colorName == 'badge-green') return colors.score.GREEN
  if (colorName == 'badge-orange') return colors.score.YELLOW
  if (colorName == 'badge-red') return colors.score.RED
}

export {
  cleanFeatureProperties,
  featureQuantity,
  featuresWithQuantities,
  getPolygonConnectionStatus,
  closePolygon,
  keyByIdWithMissing,
  perimeterCenter,
  namedColorToColorCode
}
