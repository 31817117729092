export default {
  name: 'MoveBuildingButton',
  props: {
    handleClick: Function,
    disabled: Boolean,
    tooltipText: String
  },
  template:`
    <button
      @click='handleClick()'
      class="whitespace-nowrap black-button ml-2"
      :title="tooltipText"
      :disabled="disabled"
    >
      Move Bldg.
    </button>
  `
}
