import MapboxDraw from '@mapbox/mapbox-gl-draw'
const { createSupplementaryPoints, CommonSelectors } = MapboxDraw.lib
const DirectSelect = MapboxDraw.modes.direct_select
const EditMoveVector = { ...DirectSelect }

EditMoveVector.onDrag = function(state, e) {
  // Allow dragging only vertices
  const isVertexBeingDragged = state.selectedCoordPaths.length > 0
  if (isVertexBeingDragged) { DirectSelect.onDrag.call(this, state, e) }
}

// Disable functions that revert to simple_select mode.
// We want to remain always in this mode or in draw_vector mode.
EditMoveVector.clickNoTarget = function () {}
EditMoveVector.clickInactive = function () {}

// Don't support deletions
EditMoveVector.onTrash = function(state) {}

EditMoveVector.toDisplayFeatures = function(state, geojson, push) {
  // Disable the midpoint feature by setting the midpoint option to false.
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE
    push(geojson)
    createSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: false,
      selectedPaths: state.selectedCoordPaths
    }).forEach((point) => {
      // Assign distintctive color to the end vertex
      if (point.properties.coord_path === "1") { point.properties.user_color = "#39FF14" }
      push(point)
    })
  } else {
    geojson.properties.active = MapboxDraw.constants.activeStates.INACTIVE
    push(geojson)
  }
  this.fireActionable(state)
}

export default EditMoveVector
