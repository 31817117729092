import * as Turf from '@turf/turf'
import polylabel from 'polylabel'
import ZLayers from '../../../../z_layers'
import colors from '../../../../colors'

const id = 'section-labels'

const SectionLabels = {}

SectionLabels.load = function() {
  const centers = this.sections.map((section) => {
    const perimeterCoords = section.perimeters.map(p => p.geometry.data.geometry.coordinates).flat()
    const centerCoords = polylabel([perimeterCoords], 0.000001)
    const center = Turf.point(centerCoords)
    center.id = section.id
    center.properties = {
      id: section.id,
      label_text: section.code
    }
    return center
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(centers)
  })

  this.map.addLayer({
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'text-field': ['get', 'label_text'],
      'text-radial-offset': 1,
      'text-variable-anchor': ['center', 'top', 'bottom', 'left', 'right'],
      'visibility': 'visible'
    },
    'paint': {
      'text-color': colors.BLACK,
      'text-halo-color': colors.WHITE,
      'text-halo-width': 2
    }
  }, ZLayers.myPosition.call(this, id))
}

export default SectionLabels
