import { Controller } from '@hotwired/stimulus'

import AerialMap from './aerial_map/app.js'

export default class extends Controller {
  static targets = ['map-container']

  static values = {
    locationData: Object,
    mapboxToken: String,
    azureKey: String,
    eagleviewKey: String,
    mapProvider: String
  }

  connect() {
    const { location, survey, sections, defects, focus_on_single_defect } = this.locationDataValue
    window.app = new AerialMap(
      this.mapProviderValue,
      this.mapboxTokenValue,
      this.azureKeyValue,
      this.eagleviewKeyValue,
      location,
      survey,
      sections,
      defects,
      focus_on_single_defect
    )
    window.app.mount('map-container')

    this.element.setAttribute('data-controller-connected', 'true')
  }
}
