const Checkboxes = {}

Checkboxes.init = function() {
  const toggle = (layerId) => () => {
    this.map.fire('custom.toggleVisibility', { layerId: layerId })
  }

  const map = document.getElementById('map-container')
  const controls = document.createElement('div')
  controls.className = 'map-checkbox-container'
  controls.innerHTML = `
    <div><input id='warranties-checkbox' type='checkbox'/><label class='ml-1'>Warranty Perimeters</label></div>
    <div><input id='inventories-checkbox' type='checkbox'/><label class='ml-1'>Inventory Photos</label></div>
    <div><input id='defects-checkbox' type='checkbox' checked/><label class='ml-1'>Defect Photos</label></div>
    <div><input id='section-areas-checkbox' type='checkbox' checked/><label class='ml-1'>Section Areas</label></div>
    <div><input id='section-labels-checkbox' type='checkbox' checked/><label class='ml-1'>Section Labels</label></div>
  `
  map.appendChild(controls)

  let checkbox = document.getElementById('warranties-checkbox')
  checkbox.onchange = toggle('warranties')

  checkbox = document.getElementById('inventories-checkbox')
  checkbox.onchange = toggle('inventories')

  checkbox = document.getElementById('defects-checkbox')
  checkbox.onchange = toggle('defects')

  checkbox = document.getElementById('section-areas-checkbox')
  checkbox.onchange = toggle('perimeters')

  checkbox = document.getElementById('section-labels-checkbox')
  checkbox.onchange = toggle('section-labels')
}

export default Checkboxes
