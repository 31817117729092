import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectCheckbox', 'toggleLink'];

  connect() {
    this.toggleLinkText = this.toggleLinkTarget.textContent;
    this.updateToggleLink();
  }

  toggleSelectAll(e) {
    const allChecked = this.selectCheckboxTargets.every(checkbox => checkbox.checked);
    const checkboxes = this.selectCheckboxTargets

    checkboxes.forEach(checkbox => {
      checkbox.checked = !allChecked;
    });

    this.updateToggleLink();
  }

  updateToggleLink() {
    const allChecked = this.selectCheckboxTargets.every(checkbox => checkbox.checked);
    this.toggleLinkTarget.textContent = allChecked ? 'Uncheck all' : this.toggleLinkText;
  }
}
