/*
Resulting order
- [background & raster layers]
- z-0
- z-1
- ...
- z-N
*/

const ZLayers = {
  LAYER_POSITIONS: [],

  myPosition: function(layerId) {
    if (this.LAYER_POSITIONS === undefined) { throw new Error('this.LAYER_POSITIONS not defined') }
    if (!this.LAYER_POSITIONS.includes(layerId)) { throw new Error(`Layer ${layerId} not included as a sortable layer`) }

    return `z-${this.LAYER_POSITIONS.indexOf(layerId)}`
  },

  load: function() {
    return new Promise((resolve) => {
      this.map.addSource('empty', {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] }
      })

      for (let i = this.LAYER_POSITIONS.length - 1; i >= 0; i--) {
        this.map.addLayer({
          id: `z-${i}`,
          type: 'symbol',
          source: 'empty'
        }, (i == this.LAYER_POSITIONS.length - 1) ? undefined : `z-${i+1}`)
      }

      resolve()
    })
  }
}

export default ZLayers
