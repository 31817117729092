import { loadIconsIntoMap } from '../../../../icon_loader'
import colors from '../../../../colors'
import MapPin from '../../../../../assets/images/icons/custom/map-pin-stroked.svg'

const svgSetColor = (svg, color) => svg.replace(/fill="[^"]*"/, `fill="${color}"`)

const svgs = [
  {
    id: 'map-pin-red',
    data: svgSetColor(MapPin, colors.neon.RED)
  },
  {
    id: 'map-pin-orange',
    data: svgSetColor(MapPin, colors.neon.ORANGE)
  }
]

const Icons = {}

Icons.load = function() {
  return loadIconsIntoMap(this.map, svgs)
}

export default Icons
