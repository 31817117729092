import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination"
export default class extends Controller {
  submitLimitForm() {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('page');
    searchParams.set('limit', this.element.value);
    window.location.search = searchParams;
  }
}
