import { Controller } from "@hotwired/stimulus"
import _ from 'lodash'

let baseAmpOptions
// Connects to data-controller="admin--amp-portfolio"
export default class extends Controller {
  static targets = [
    'includeNewAssets',
    'resetAssetPlan',
    'baseAmp',
    'category',
    'regionCostFactor',
    'regionCostFactorGroup',
    'scenario',
    'inflationRate',
    'costModelRow'
  ]

  connect() {
    // Added this to check when new_record and when there is an error in the form.
    // When redirected back to the form, it properly handles includeNewAssets
    if (this.hasBaseAmpTarget) {
      baseAmpOptions = JSON.parse(this.baseAmpTarget.dataset.baseAmpOptions)
      this.filterBaseAmpOptions(this.categoryTarget.value)
      this.handleIncludeNewAssetsVisibility(this.baseAmpTarget.value)
      this.handleResetAssetPlanVisibility(this.baseAmpTarget.value)
      this.handleCostModelRowVisibility(this.categoryTarget.value)
    }
  }

  handleBaseAmpChange(event) {
    let target = event.target
    this.changeBaseAmp(target)
  }

  changeBaseAmp(target) {
    let baseAmpId = target.value
    let baseAmpAttributeMapping = JSON.parse(target.dataset.baseAmpAttributes)
    let baseAmpAttributes = baseAmpAttributeMapping[baseAmpId]
    let defaultAmpAttributes = JSON.parse(target.dataset.defaultAmpAttributes)

    this.handleIncludeNewAssetsVisibility(baseAmpId)
    this.handleResetAssetPlanVisibility(baseAmpId)

    if (baseAmpId === '') {
      this.updateScenario(defaultAmpAttributes)
      this.updateInflationRate(defaultAmpAttributes)
      this.updateAllRegionCostFactors(defaultAmpAttributes['amp_region_cost_factors'])
    } else {
      let regionCostFactors = baseAmpAttributes['amp_region_cost_factors']
      if (_.isEmpty(baseAmpAttributes['amp_region_cost_factors'])) {
        regionCostFactors = defaultAmpAttributes['amp_region_cost_factors']
      }

      this.updateScenario(baseAmpAttributes)
      this.updateInflationRate(baseAmpAttributes)
      this.updateAllRegionCostFactors(regionCostFactors)
    }
  }

  toggleIncludeNewAssetsVisibility(event) {
    this.handleIncludeNewAssetsVisibility(event.target.value)
  }

  toggleResetAssetPlanVisibility(event) {
    this.handleResetAssetPlanVisibility(event.target.value)
  }

  handleIncludeNewAssetsVisibility(value) {
    if (value.length === 0) {
      this.includeNewAssetsTarget.classList.remove('h-auto')
      this.includeNewAssetsTarget.classList.add('h-0')
    } else {
      this.includeNewAssetsTarget.classList.remove('h-0')
      this.includeNewAssetsTarget.classList.add('h-auto')
    }
  }

  handleResetAssetPlanVisibility(value) {
    if (value.length === 0) {
      this.resetAssetPlanTarget.classList.remove('h-auto')
      this.resetAssetPlanTarget.classList.add('h-0')
    } else {
      this.resetAssetPlanTarget.classList.remove('h-0')
      this.resetAssetPlanTarget.classList.add('h-auto')
    }
  }

  filterBaseAmpOptions(category_id) {
    this.clearBaseAmpOptions()
    const filteredOptions = baseAmpOptions.filter(item => item[2] === category_id)
    const result = filteredOptions.map(item => [item[0], item[1]])
    this.addBaseAmpOptions(result)
  }

  addBaseAmpOptions(result) {
    result.forEach(item => {
      const option = document.createElement('option')
      option.value = item[1]
      option.textContent = item[0]
      this.baseAmpTarget.appendChild(option)

      if (item[1] == this.baseAmpTarget.dataset.baseAmpValue) {
        option.selected = true
        this.baseAmpTarget.value = item[1]
      }
    })
  }

  clearBaseAmpOptions() {
    while (this.baseAmpTarget.children.length > 1) {
      this.baseAmpTarget.removeChild(this.baseAmpTarget.children[1])
    }
  }

  handleCategoryChange(event) {
    this.filterBaseAmpOptions(event.target.value)
    this.changeBaseAmp(this.baseAmpTarget)
    this.handleCostModelRowVisibility(event.target.value)
  }

  handleRegionCostFactorChange(event) {
    let el = event.target;
    const groupName = el.dataset.regionCostFactorGroupName
    const regionCostFactor = el.value
    this.updateRegionCostFactor(groupName, regionCostFactor);
  }

  updateRegionCostFactor(groupName, regionCostFactor) {
    this.regionCostFactorTargets.forEach((target) => {
      if (target.dataset.regionCostFactorGroupName === groupName) {
        target.value = regionCostFactor;
      }
    });
  }

  updateScenario(baseAmpAttributes) {
    this.scenarioTarget.value = baseAmpAttributes.amp_portfolio.scenario
  }

  updateInflationRate(baseAmpAttributes) {
    this.inflationRateTarget.value = baseAmpAttributes.amp_portfolio_setting.inflation_rate
  }

  updateAllRegionCostFactors(baseAmpRegionCostFactors) {
    this.regionCostFactorGroupTargets.forEach((target) => {
      const groupName = target.dataset.regionCostFactorGroupName;
      const regionCostFactor = baseAmpRegionCostFactors[groupName].cost_factor;
      if (regionCostFactor) {
        target.value = regionCostFactor;
        this.updateRegionCostFactor(groupName, regionCostFactor)
      }
    })
  }

  handleCostModelRowVisibility(category_id) {
    this.costModelRowTargets.forEach((target) => {
      if (target.dataset.categoryId === category_id) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    })
  }
}
