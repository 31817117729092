import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['radio'];

  clear() {
    this.radioTarget.checked = false;
    this.element.closest('details').open = true
  }
}
