import * as Vue from 'vue/dist/vue.esm-bundler.js'
import * as Turf from '@turf/turf'
import _ from 'lodash'
import { mapWritableState, mapActions, mapStores } from 'pinia'
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid'

import ContextMenu from '../context_menu'
import { useLegendStore, useMapStore, useGeometryStore } from '../../../../stores'

import template from './index.html'

export default {
  name: 'LegendItem',
  components: { ContextMenu, ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon, ExclamationCircleIcon },
  props: {
    item: Object,
    index: Number
  },
  computed: {
    ...mapWritableState(useLegendStore, ['legendItemTree', 'showNestedItemsMap', 'legendItems', 'legendItemTypes']),
    ...mapStores(useMapStore, useGeometryStore),
    itemCheckboxValue: {
      get() {
        return this.item.checkboxValue
      },
      set(value) {
        this.updateAllOptions(this.item, value)
      }
    },
    itemLabel() {
      return (this.classificationLegend ? `[${this.classificationLegend}] ` : '') + this.item.label
    },
    isSection() { return this.item.type === 'section' },
    isInteriorPerimeterGroup() { return this.item.type === 'interior-perimeter-group' },
    classificationLegend() {
      return this.mapStore.plugins.classificationIcons.config.visible &&
        this.geometryStore.classificationIdToNumber[this.item.classificationId]
    },
    hasNestedItems() {
      return this.item.items.length > 0
    },
    hasContentextMenuItems() {
      return this.item?.type !== 'parent_classification'
    },
    showNestedItems() {
      return this.showNestedItemsMap[this.item.id]
    },
    isOutOfOrder() {
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        return this.geometryStore.sections[sectionId]?.isGroupOutOfOrder(groupId)
      }
      if (this.isSection) {
        return this.geometryStore.sections[this.item.id]?.isOutOfOrder
      }
    },
    isPolygonUnclosed() {
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        return this.geometryStore.sections[sectionId]?.isGroupUnclosed(groupId)
      }
      if (this.isSection) {
        return this.geometryStore.sections[this.item.id]?.isUnclosed
      }
    },
    isMissingGeometries() {
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        return this.geometryStore.sections[sectionId]?.isGroupMissingGemetries(groupId)
      }
      if (this.isSection) {
        return this.geometryStore.sections[this.item.id]?.isMissingGeometries
      }
    },
    alertMessage() {
      if (this.isOutOfOrder) {
        return `The perimeter segments forming this ${this.isSection ? 'Section' : 'Group' } are out of order.`
      } else if (this.isMissingGeometries) {
        return 'Perimeter segment(s) are missing geometry.'
      } else if (this.isPolygonUnclosed) {
        return `${this.isSection ? 'Section' : 'Group' } is unclosed. Close it from the context menu in View Mode.`
      }
    },
    isMyContextMenuOpen() {
      return this.item.id === this.mapStore.leftPanelSubMenuProps?.item?.id
    }
  },
  methods: {
    ...mapActions(useGeometryStore, ['featureById']),
    handleLabelClick() {
      if (this.item.items.length > 0) {
        this.toggleNestedItems()
      } else if (this.item.type === 'geometry') {
        this.centerMapOnItem()
      }
    },
    toggleNestedItems() {
      const state = !this.showNestedItemsMap[this.item.id]
      this.showNestedItemsMap[this.item.id] = state
      this.legendItems[this.item.id].showNestedItems = state
    },
    centerMapOnItem() {
      const feature = this.featureById(this.item.id)
      const geometry = feature?.data?.geometry

      if (geometry && geometry?.coordinates.length >= 1) {
        const center = Turf.center(geometry)
        this.mapStore.map.flyTo({ center: center.geometry.coordinates })
      } else {
        window.alert('There is no geometry associated with this record.')
      }
    },
    checkboxValueChange(item) {
      this.$emit('checkbox-value-change', item)
    },
    updateAllOptions(item, value) {
      this.setcheckboxAndIndeterminateValue(item, value)
      this.updateLegendItemTypesCounter(item, value)

      item.items.forEach((nestedItem) => {
        this.updateAllOptions(nestedItem, value)
      })
    },
    setcheckboxAndIndeterminateValue(item, value) {
      if (!item.disabled) {
        item.checkboxValue = value
        item.indeterminateValue = value ? false : item.indeterminateValue
      }
    },
    updateLegendItemTypesCounter(item, value) {
      if (item.geometryType) {
        const checkedCount = this.legendItemTypes['checkedCount'][item.geometryType]
        const totalCount = this.legendItemTypes['totalCount'][item.geometryType]

        if (value && (checkedCount < totalCount)) {
          this.legendItemTypes['checkedCount'][item.geometryType] += 1
        } else if (!value && (checkedCount > 0)) {
          this.legendItemTypes['checkedCount'][item.geometryType] -= 1
        }
      }
    },
    openContextMenu() {
      this.mapStore.$patch({
        leftPanelSubMenuComponent: Vue.shallowRef(ContextMenu),
        leftPanelSubMenuProps: { item: this.item, index: this.index }
      })
    }
  },
  template: template
}
