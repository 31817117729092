import * as Vue from 'vue/dist/vue.esm-bundler.js'
import * as Turf from '@turf/turf'
import { useMapStore, useGeometryStore } from '../../../../stores'
import { mapStores, mapState } from 'pinia'
import { ArrowLongUpIcon } from '@heroicons/vue/20/solid'

import template from './index.html'

export default {
  data() {
    return {
      selectedOption: null,
      options: []
    }
  },
  beforeMount() {
    this.calculateBearings()
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore),
    ...mapState(useGeometryStore, ['selectedFeature', 'selectedCoordPaths']),
  },
  methods: {
    calculateBearings() {
      if (this.selectedFeature.geometry.type == 'Polygon') {
        const [ringPath, coordPath] = this.selectedCoordPaths[0].split('.').map(x => parseInt(x, 10))

        // Mapbox Draw omits the final vertex of each ring when interacting with a feature.
        // Consequently, selectedCoordPaths will never reference the last vertex.
        const ring = this.selectedFeature.geometry.coordinates[ringPath]

        // The following line ensures compatibility with potential changes in the Mapbox Draw library's behavior.
        if (coordPath === ring.length - 1) {
          throw new Error("Selection of the last vertex in a ring is not supported")
        }

        const isFirstVertexSelected = coordPath === 0
        const pathOfLastSegment = [ring.length - 1, ring.length - 2]

        const prevSegmentPath = isFirstVertexSelected ? pathOfLastSegment : [coordPath, coordPath - 1]
        const prevSegment = [ring[prevSegmentPath[0]], ring[prevSegmentPath[1]]]
        const nextSegmentPath = [coordPath, coordPath + 1]
        const nextSegment = [ring[nextSegmentPath[0]], ring[nextSegmentPath[1]]]

        const options = [
          {
            bearing: Turf.bearing(prevSegment[0], prevSegment[1]),
            paths: [`${ringPath}.${prevSegmentPath[0]}`, `${ringPath}.${prevSegmentPath[1]}`],
            selectAfterCreation: `${ringPath}.${isFirstVertexSelected ? coordPath: coordPath + 1}`
          },
          {
            bearing: Turf.bearing(nextSegment[0], nextSegment[1]),
            paths: [`${ringPath}.${nextSegmentPath[0]}`, `${ringPath}.${nextSegmentPath[1]}`],
            selectAfterCreation: `${ringPath}.${coordPath}`
          }
        ]

        this.options = this.sortByBearing(options)
      }

      if (this.selectedFeature.geometry.type == 'LineString') {
        const coords = this.selectedFeature.geometry.coordinates
        const coordPath = parseInt(this.selectedCoordPaths[0], 10)

        const options = [
          {
            bearing: Turf.bearing(coords[coordPath], coords[coordPath - 1]),
            paths: [coordPath - 1, coordPath],
            selectAfterCreation: (coordPath + 1).toString()
          },
          {
            bearing: Turf.bearing(coords[coordPath], coords[coordPath + 1]),
            paths: [coordPath, coordPath + 1],
            selectAfterCreation: coordPath.toString()
          }
        ]

        this.options = this.sortByBearing(options)
      }
    },
    sortByBearing(options) {
      return options.sort((a, b) => {
        if (a.bearing < 0 && b.bearing < 0) {
          return Math.abs(a.bearing) - Math.abs(b.bearing)
        }
        return a.bearing - b.bearing
      })
    },
    selectOption(option) {
      this.selectedOption = option
    },
    createPoint() {
      const { selectAfterCreation, paths: [startingVertex, endingVertex]} = this.options[this.selectedOption]
      this.geometryStore.createCoordinateBetween(this.selectedFeature, startingVertex, endingVertex)
      this.geometryStore.programmaticSelectFeature(this.selectedFeature.id, selectAfterCreation)
      this.closeModal()
    },
    closeModal() {
      this.mapStore.closeModal()
    }
  },
  template: template,
  components: { ArrowLongUpIcon }
}
