import * as Turf from '@turf/turf'
import { BBox } from '../../../../geojson_utils'

const SidebarClickHandler = {}

SidebarClickHandler.load = function () {
  document.querySelectorAll('a[id^="js-defect-marker-"]').forEach(element => {
    element.addEventListener('click', (event) => {
      const idPattern = /^js-defect-marker-(.+)$/
      const match = event.currentTarget.id.match(idPattern)
      if (!match) { return }

      const geometryId = match[1]
      const defectsSource = this.map.getSource('defects')
      const features = defectsSource._data.features
      const defectToCenter = features.find((defect) => defect.id === geometryId)
      if (!defectToCenter) { return }

      features.forEach((feature) => {
        feature.properties.selected = feature.id === defectToCenter.id
      })
      defectsSource.setData(Turf.featureCollection(features))

      const bbox = new BBox(Turf.featureCollection([defectToCenter]))
      bbox.extend(10)
      this.map.fitBounds(bbox.value, { animate: true })

      event.preventDefault()
    })
  })
}

export default SidebarClickHandler
