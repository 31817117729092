<Loader v-if="showLoader" />
<Modal v-if="showModal" />
<div id='map' class='h-full'></div>
<ViewMode v-if="inViewMode || inReadOnlyMode" />
<EditMode v-else-if="inEditMode" />
<BulkMoveMode v-else-if="inBulkMoveMode" />
<PlacementMode v-else-if="inPlacementMode" />
<LeftPanel v-if="showLeftPanel" />
<RightPanel v-if="inEditMode"/>
<Popup v-if="showPopup" />
