import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import Popup from '../../../../images_popup'

const Properties = {}

const id = 'properties'

Properties.load = function() {
  const points = this.properties.map((property) => {
    const point = Turf.point([property.long, property.lat])
    point.id = property.id
    point.properties.popup_text = property.name
    return point
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(points)
  })

  this.map.addLayer({
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'icon-image': 'map-pin-blue',
      'icon-allow-overlap': true,
      'visibility': 'visible'
    }
  }, ZLayers.myPosition.call(this, id))

  Popup.init.call(this, id, { noImagesMessage: null })
}

export default Properties
