import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { Controller } from '@hotwired/stimulus'
import { createPinia } from 'pinia'
import AerialMap from './aerial_map/app.js'

export default class extends Controller {
  static targets = ['map-container']

  static values = {
    data: Object,
    mapboxToken: String,
    azureKey: String,
    eagleviewKey: String,
    urls: Object,
    mapProvider: String,
    readOnly: Boolean
  }

  connect() {
    const { location, survey } = this.dataValue
    let app = new AerialMap(
      this.mapProviderValue,
      this.mapboxTokenValue,
      this.azureKeyValue,
      this.eagleviewKeyValue,
      location,
      survey,
      this.urlsValue,
      this.readOnlyValue
    )

    window.app = Vue.createApp(app.vue()).use(createPinia()).mount('#map-container')
    this.element.setAttribute('data-controller-connected', 'true')
  }
}
