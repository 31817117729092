import { shallowRef } from 'vue'
import { mapStores } from 'pinia'
import { useGeometryStore, useMapStore, useModeStore } from '../../../../stores'
import AddPerimeterForm from './add_perimeter_form'

export default {
  name: 'AddPerimeterLink',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useMapStore, useModeStore, useGeometryStore),
    isLocation() { return this.item.type === 'location' },
    isSection() { return this.item.type === 'section' },
    isInteriorPerimeterGroup() { return this.item.type === 'interior-perimeter-group' },
    preselectedSectionId() {
      if (this.isSection) { return this.item.id }
      if (this.isInteriorPerimeterGroup) { return this.item.properties.sectionId }
      return null
    },
    canAddPerimeter() {
      if (!this.modeStore.inViewMode) { return false }
      if (this.isLocation) { return true }
      if (this.isSection) {
        return this.geometryStore.sections[this.item.id].perimeterIds.length === 0
      }
    },
    canAddInteriorPerimeter() {
      if (!this.modeStore.inViewMode) { return false }
      if (this.isSection) { return true }
      if (this.isInteriorPerimeterGroup) {
        if (!this.item.properties.groupId) { return false } // Adding interior perimeters to "Ungrouped" is not allowed
        return this.groupHasNoGeometries()
      }
    }
  },
  methods: {
    openAddPerimeterForm(addingInteriorPerimeters) {
      this.mapStore.$patch({
        popupData: null, // Close popup window in case it was open
        leftPanelSubMenuComponent: null, // Close submenu
        leftPanelSubMenuProps: null,
        showModal: true,
        modalComponent: shallowRef(AddPerimeterForm),
        modalComponentProps: {
          addingInteriorPerimeters,
          preselectedSectionId: this.preselectedSectionId,
          preselectedGroupId: this.isInteriorPerimeterGroup ? this.item.properties.groupId : undefined
        }
      })
    },
    groupHasNoGeometries() {
      const interiorPerimetersOfThisSection = this.geometryStore.sections[this.item.properties.sectionId].interiorPerimeters
      const interiorPerimetersOfThisGroup = interiorPerimetersOfThisSection.filter(p => {
        return p.properties.group_id === this.item.properties.groupId
      })
      return interiorPerimetersOfThisGroup.length === 0
    }
  },
  template: `
    <li v-if="isLocation || isSection">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !canAddPerimeter,
          'hover:underline': canAddPerimeter
        }"
        @click="openAddPerimeterForm(false)">Add Perimeter
      </a>
    </li>
    <li v-if="isSection || isInteriorPerimeterGroup">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !canAddInteriorPerimeter,
          'hover:underline': canAddInteriorPerimeter
        }"
        @click="openAddPerimeterForm(true)">Add Interior Perimeter
      </a>
    </li>
  `
}
