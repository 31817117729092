document.addEventListener('DOMContentLoaded', () => {
  let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'))

  if (lazyImages.length > 0) {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let img = entry.target
            img.src = img.dataset.src
            img.classList.remove('lazy')
            observer.unobserve(img)
          }
        })
      }, { rootMargin: '100px' })

      lazyImages.forEach((img) => observer.observe(img))

    } else {
      // For browsers that do not support IntersectionObserver
      const lazyLoad = () => {
        let active = false

        if (!active) {
          active = true
          setTimeout(() => {
            lazyImages.forEach((img, index) => {
              let inFrame = (img && img.getBoundingClientRect().top < (window.innerHeight + window.scrollY + 300))
              let visible = window.getComputedStyle(img).display != 'none'

              if (visible && inFrame) {
                img.src = img.dataset.src
                img.classList.remove('lazy')
                lazyImages[index] = null
              }
            })

            lazyImages = lazyImages.filter(img => img !== null)

            if (lazyImages.length === 0) {
              document.removeEventListener('scroll', lazyLoad)
              document.removeEventListener('click', lazyLoad)
              window.removeEventListener('resize', lazyLoad)
              window.removeEventListener('orientationchange', lazyLoad)
            }

            active = false
          }, 200)
        }
      }

      lazyLoad()
      document.addEventListener('scroll', lazyLoad)
      document.addEventListener('click', lazyLoad)
      window.addEventListener('resize', lazyLoad)
      window.addEventListener('orientationchange', lazyLoad)
    }
  }
})
