import MapboxDraw from '@mapbox/mapbox-gl-draw'
const { doubleClickZoom, isEventAtCoordinates } = MapboxDraw.lib
const DrawLineString = MapboxDraw.modes.draw_line_string

const DrawMoveVector = { ...DrawLineString }

DrawMoveVector.clickAnywhere = function(state, e) {
  // this ends the drawing after the user creates a second point, triggering this.onStop
  if (state.currentVertexPosition === 1) {
    state.line.addCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
    return this.changeMode('edit_vector', { featureId: state.line.id })
  }
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD })
  state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
  if (state.direction === 'forward') {
    state.currentVertexPosition++
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat)
  }

  return null
}

// We don't support any keypress events
DrawMoveVector.onKeyUp = function (_state, _e) {}

// Don't support deletions
DrawMoveVector.onTrash = function(state) {}

// This function can only be triggered by clicking on the origin vertex.
// In case this happens, we do nothing so that the user can continue drawing the vector
DrawMoveVector.clickOnVertex = function(state) {}

// We use a modfied version of DrawLineString.onStop so that we can include geoJSON properties
// that identify the generated feature as a special type.
DrawMoveVector.onStop = function(state) {
  doubleClickZoom.enable(this)
  this.activateUIButton()

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`)
  if (state.line.isValid()) {
    const vectorLine = {
      ...state.line.toGeoJSON(),
      properties: {
        type: 'vector'
      }
    }
    this.map.fire(MapboxDraw.constants.events.CREATE, {
      features: [vectorLine]
    })
  }
}

export default DrawMoveVector
