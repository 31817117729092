import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapState } from 'pinia'
import { useMapStore } from '../../stores/map_store/index.js'

import template from './index.html'

export default {
  name: 'RightPanel',
  computed: {
    ...mapState(useMapStore, ['rightPanelTitle', 'rightPanelComponent'])
  },
  template: template
}
