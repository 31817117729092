import * as Vue from 'vue/dist/vue.esm-bundler.js'
import _ from 'lodash'

import { useMapStore, useLegendStore, useGeometryStore } from '../../stores'
import LegendList from './components/legend_list'

export default class Legend {
  constructor() {}
  initialize() {
    const mapStore = useMapStore()
    const legendStore = useLegendStore()
    const geometryStore = useGeometryStore()

    mapStore.$patch({
      leftPanelTitle: 'Legend',
      leftPanelComponent: Vue.shallowRef(LegendList)
    })

    legendStore.buildLegendItemTree()
  }

  modeChanged(toMode) {}
}
