import { mapStores } from 'pinia'

import { useMapStore, useGeometryStore } from '../../../../stores'

export default {
  name: 'RecenterGridLink',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore),
    isSection() {
      return this.item.type === 'section'
    },
    canCenter() {
      const isGridVisible = this.mapStore.map.getLayer('grid') && this.mapStore.map.getSource('grid-lines')
      const sectionHasCenter = this.geometryStore.sections[this.item.id]?.center != null
      return isGridVisible && sectionHasCenter
    }
  },
  methods: {
    recenterGrid() {
      const center = this.geometryStore.sections[this.item.id].center
      this.mapStore.recenterGrid(center.geometry.coordinates)
      this.mapStore.applyGridConfig()
      this.mapStore.saveSettings()
    }
  },
  template: `
    <li v-if="isSection">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !canCenter,
          'hover:underline': canCenter
        }"
        @click="recenterGrid">Center Grid
      </a>
    </li>
  `
}
