<div v-if="!showLoader" class="flex flex-col items-center justify-center absolute h-full w-full bg-opacity-70 z-50 bg-gray-900">
  <div class="bg-white shadow-md p-4 rounded-lg flex flex-col h-[500px] w-96">
    <span class="font-semibold">Add {{addingInteriorPerimeters ? 'Interior ' : null}}Perimeter Form</span>
    <label for="section" class="mt-4 block text-sm font-medium text-gray-dark flex items-center justify-between">
      <span>Section</span>
      <span class="text-xs text-gray-500">
        <b>*</b> indicates the section contains empty perimeters
      </span>
    </label>
    <select v-model="selectedSectionId" :disabled="isSelectSectionDisabled" id="sectionSelect" @change="handleSectionChange" class="border-gray-light focus:border-blue focus:ring-blue placeholder-gray-light appearance-none block border focus:outline-none px-3 py-2 rounded-md shadow-sm text-sm w-full mt-2">
      <option v-for="section in sectionOptions" :key="section.id" :value="section.id" class="truncate">
        {{section.code}}{{ (hasExistingPerimeters(section.id)) ? ' *' : '' }}
      </option>
    </select>

    <div v-if="addingInteriorPerimeters && hasExistingPerimeters(preselectedSectionId)">
      <label for="group" class="mt-4 block text-sm font-medium text-gray-dark flex items-center justify-between">
        <span>Group</span>
      </label>
      <select :disabled="true" id="groupSelect" class="border-gray-light focus:border-blue focus:ring-blue placeholder-gray-light appearance-none block border focus:outline-none px-3 py-2 rounded-md shadow-sm text-sm w-full mt-2">
        <option :value="preselectedGroupId" class="truncate">
          {{ groupLabel }}
        </option>
      </select>
    </div>

    <div v-if="addingInteriorPerimeters" class="mt-4 flex items-center space-x-2">
      <input v-model="isClosed" id="closed-checkbox" type="checkbox" class="appearance-none h-5 w-5 border border-gray-light rounded-md checked:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-0" />
      <label for="closed-checkbox" class="text-sm font-medium text-gray-dark">Closed?</label>
    </div>

    <label for="classification" class="mt-4 block text-sm font-medium text-gray-dark">Classification</label>
    <div class="flex items-center mt-2 space-x-2 w-full">
      <select v-model="selectedClassification" :disabled="isSelectClassificationDisabled" id="classificationSelect" class="w-[70%] border-gray-light focus:border-blue focus:ring-blue placeholder-gray-light appearance-none block border focus:outline-none px-3 py-2 rounded-md shadow-sm text-sm">
        <option v-for="classification in classificationOptions" :key="classification.id" :value="classification">
          {{ classification.name }}
        </option>
      </select>
      <button @click="addPerimeter" :disabled="isAddPerimeterDisabled" type="button" class="w-[30%] blue-button">
        Add
      </button>
    </div>

    <div id="perimeters-area" class="my-4 w-full h-full border border-gray-light rounded-md px-2 overflow-y-auto">
      <draggable v-if="perimeters.length" :list="perimeters" :disabled="isDragDisabled" item-key="index">
        <template #item="{element, index}">
          <div
            :id="`perimeter-${index}`"
            class="space-y-1 flex items-center border-b border-gray-light last:border-b-0 transition-colors"
            :class="{ 'hover:cursor-move': !isDragDisabled, 'bg-gray-50': isDragDisabled }">
            <span class="mt-1 text-sm w-[10%] text-right text-gray-dark pr-2">{{ index + 1 }}.</span>
            <span class="text-sm flex-1">{{ element.classificationName }}</span>
            <TrashIcon
              class="w-4 h-4 mr-2"
              :class="{ 'cursor-pointer': !isDragDisabled, 'text-gray-500 cursor-not-allowed': isDragDisabled }"
              @click="removePerimeter(index)">
          </TrashIcon>
          </div>
        </template>
      </draggable>
      <div v-else class="text-gray-500 text-sm flex items-center justify-center h-full">
        No perimeters added yet.
      </div>
    </div>


    <div class="flex justify-end mt-4 mt-auto">
      <button @click="closeModal" class="white-button">Cancel</button>
      <button @click="handleSubmit" class="ml-3 blue-button" :disabled="isSubmitDisabled">Next</button>
    </div>
  </div>
</div>
