import * as d3 from "d3"
import * as Turf from '@turf/turf'
import _ from 'lodash'
import { watch } from 'vue'
import { useGeometryStore, useMapStore, useModeStore, useLegendStore } from '../stores'
import { loadIconsIntoMap } from '../../../../icon_loader'
import { isPoint, isLineString, isPolygon, calculateCenter } from '../../../../geojson_utils'
import ZLayers from '../../../../z_layers'

export default class Icons {
  constructor() {
    this.id = 'classification-icons'
    this.svgs = {}
    this.mapStore = useMapStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.geometryStore = useGeometryStore()
    this.config = {
      visible: false
    }
  }

  async initialize() {
    this.mapStore.map.addSource(this.id, {
      'type': 'geojson',
      'data': await this.initIcons(),
      'promoteId': 'id'
    })

    this.mapStore.map.addLayer({
      'id': this.id,
      'type': 'symbol',
      'source': this.id,
      'layout': {
        'icon-anchor': 'right',
        'icon-rotation-alignment': 'viewport',
        'icon-image': ['get', 'icon_id'],
        'visibility': this.config.visible ? 'visible' : 'none'
      },
      'filter': ['==', 'visible', true]
    }, ZLayers.myPosition.call(this.mapStore, this.id))

    this.subscribeToLegendStore()
  }

  async modeChanged() {
    if (this.modeStore.inBulkMoveMode || this.modeStore.inPlacementMode) {
      return this.mapStore.map.setLayoutProperty(this.id, 'visibility', 'none')
    } else {
      await this.refresh()
      this.mapStore.map.setLayoutProperty(this.id, 'visibility', this.config.visible ? 'visible' : 'none')
    }
  }

  addControls() {
    const folder = this.mapStore.menu.folders.find(folder => folder._title == "Settings")
    const visibilityController = folder.add(this.config, 'visible').name('show defect and inventory icons')

    visibilityController.onChange(value => {
      this.mapStore.map.setLayoutProperty(this.id, 'visibility', value ? 'visible' : 'none')
    })
  }

  async initIcons() {
    const defects = this.geometryStore.featuresWithExtendedProps('defects').filter(p => p != null)
    const inventories = this.geometryStore.featuresWithExtendedProps('inventories').filter(p => p != null)
    const features = defects.concat(inventories)

    const iconFeatures = features.map(feature => {
      const classification_id = feature.properties.classification_id

      if (!this.hasSVG(classification_id)) { this.createSVG(classification_id) }

      return this.iconFeature(feature)
    })

    await loadIconsIntoMap(this.mapStore.map, Object.values(this.svgs))

    return Turf.featureCollection(iconFeatures)
  }

  deleteIcons() {
    Object.values(this.svgs).forEach(({ id }) => {
      if (this.mapStore.map.hasImage(id)) { this.mapStore.map.removeImage(id) }
    })
    this.svgs = {}
  }

  async refresh() {
    this.deleteIcons()
    this.mapStore.setViewSource(this.id, await this.initIcons())
  }

  recalculate(feature) {
    const iconFeatures = this.mapStore.map.getSource(this.id)._data.features
    const oldIcon = _.remove(iconFeatures, f => feature.id == f.id)[0]
    const newIcon = this.iconFeature(feature)
    iconFeatures.push(newIcon)

    this.mapStore.setViewSource(this.id, Turf.featureCollection(iconFeatures))
  }

  iconFeature(feature) {
    const result = this.calculateIconPosition(feature)
    result.id = feature.id
    result.properties.icon_id = this.svgs[feature.properties.classification_id].id
    result.properties.visible = feature.properties.visible

    // Store the id in the properties too so it can be promoted with the option promoteId.
    // By doing this, non-numerical ids will be returned by queryRenderedFeatures (necessary in the tests)
    result.properties.id = feature.id

    return result
  }

  hasSVG(id) {
    this.svgs[id] != null
  }

  createSVG(id) {
    const number = this.geometryStore.classificationIdToNumber[id].toString()

    const svgNode = d3.create('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('viewBox', number.length > 2 ? '0 0 30 20' : '0 0 20 20')
      .attr('fill', '#000000')

    const textNode = svgNode.append('text')
      .attr('x', '50%')
      .attr('y', '50%')
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .attr('font-family', 'Open Sans, sans-serif')
      .attr('font-weight', 'bold')
      .text(number)

    this.svgs[id] = {
      id: `classification-icon-${id}`,
      data: svgNode.node().outerHTML,
      width: number.length > 2 ? 30 : 20,
      heigth: 20
    }
  }

  calculateIconPosition(feature) {
    if (isPoint(feature)) { return Turf.point(feature.geometry.coordinates) }
    if (isPolygon(feature)) { return Turf.point(feature.geometry.coordinates[0][0]) }
    if (isLineString(feature)) {
      if (feature.geometry.coordinates.length == 2) {
        return calculateCenter(feature)
      } else {
        const middleVertex = feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)]
        return Turf.point(middleVertex)
      }
    }
  }

  setVisibleProperty(iconFeatures) {
    const iconsById = _.keyBy(iconFeatures, 'id')

    _.forEach(iconFeatures, f => f.properties.visible = true)
    _.forEach(this.legendStore.noVisibilityIds, id => {
      if (iconsById[id]) { (iconsById[id].properties.visible = false) }
    })
  }

  subscribeToLegendStore() {
    watch(
      () => this.legendStore.noVisibilityIds,
      (newNoVisibilityIds) => {
        const iconFeatures = this.mapStore.map.getSource(this.id)._data.features
        this.setVisibleProperty(iconFeatures)
        this.mapStore.setViewSource(this.id, Turf.featureCollection(iconFeatures))
      }
    )
  }
}
