import MapboxGL from 'mapbox-gl'
import * as Turf from '@turf/turf'
import _ from 'lodash'
import Azure from './azure'
import Eagleview from './eagleview'
import Perimeters from './perimeters'
import SectionLabels from './section_labels'
import Defects from './defects'
import Icons from './icons'
import ZLayers from '../../../z_layers'
import { BBox } from '../../../geojson_utils'

export default class {
  constructor(provider, mapboxToken, azureKey, eagleviewKey, location, survey, sections, defects, focusOnSingleDefect) {
    this.provider = provider
    this.mapboxToken = mapboxToken
    this.azureKey = azureKey
    this.eagleviewKey = eagleviewKey
    this.location = location
    this.center = [Number(this.location.long), Number(this.location.lat)]
    this.survey = survey
    this.sections = sections
    this.defects = defects
    this.focusOnSingleDefect = focusOnSingleDefect
    this.LAYER_POSITIONS = [
      'satellite',
      'custom-satellite',
      'perimeters',
      'section-labels',
      'defects'
    ]
    this.map = null
  }

  mount(container) {
    this.map = new MapboxGL.Map({
      accessToken: this.mapboxToken,
      container: container,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: this.center,
      zoom: 18,
      minZoom: 15
    })

    this.map.addControl(new MapboxGL.NavigationControl(), 'bottom-right')

    this.map.once('load', async () => {
      await Icons.load.call(this)
      await ZLayers.load.call(this)
      this.setup()
    })
  }

  setup() {
    if (this.provider == 'azure') {
      this.map.removeLayer('satellite')
      this.map.removeLayer('background')
      this.map.removeSource('mapbox')
      Azure.load.call(this)
    } else if (this.provider == 'eagleview') {
      Eagleview.load.call(this)
    }

    SectionLabels.load.call(this)
    Perimeters.load.call(this)
    Defects.load.call(this)
    this.fitMapToGeometries()
    this.map.once('idle', this.stopLoader)
  }

  stopLoader() {
    const loader = document.getElementById('loader')
    loader.style.display = 'none'
  }

  fitMapToGeometries() {
    const sourceIds = ['perimeters', 'defects']
    const combinedGeojsons = Turf.featureCollection([])
    sourceIds.forEach((id) => {
      combinedGeojsons.features = combinedGeojsons.features.concat(this.map.getSource(id)._data.features)
    })

    const bbox = new BBox(combinedGeojsons).toSquare()

    bbox.extend(10) // mandatory visible area
    if (!this.focusOnSingleDefect) { this.map.fitBounds(bbox.value, { animate: this.provider == 'azure' }) }

    bbox.extend(1500) // maximum visible area
    this.map.setMaxBounds(bbox.value)
  }
}
