import _ from 'lodash'
import * as Turf from '@turf/turf'
import { mapStores, mapState, mapActions } from 'pinia'

import { useMapStore, useModeStore, useGeometryStore, useLockStore } from '../stores'
import { CancelButton, SaveAndExitButton, PreviewButton, ResetButton } from './buttons'
import { initializeLockedMode } from '.'
import { BBox } from '../../../../geojson_utils'

export default {
  name: 'BulkMoveMode',
  data() {
    const mapStore = useMapStore()
    return {
      originalFeatures: null,
      drawMode: 'move_vector',
      geometryType: 'perimeterSegments',
      sourceId: 'bulk-move-perimeter-segments',
      layerId: 'bulk-move-perimeter-segments-line',
      vector: null,
      previewing: false,
      originalPivot: null, // stores the original point used for pivoting, used when resetting
      rotationPivot: null, // stores the current point used for pivoting, is moved along with the rest of the features
      rotationAngle: 0 // keeps track of the total rotation angle
    }
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore, useLockStore),
    ...mapState(useMapStore, ['map', 'draw']),
    isPreviewDisabled() { return !this.vector || this.previewing },
    isResetDisabled() { return !this.vector && !this.rotationAngle },
    isSaveDisabled() { return !this.vector && !this.rotationAngle },
    sectionId() { return this.modeStore.bulkMove.sectionId },
    perimeters() {
      if (!this.sectionId) { return this.geometryStore[this.geometryType] }
      return _.filter(this.geometryStore[this.geometryType], ['section_id', this.sectionId])
    },
    perimeterFeatures() {
      const features = _.chain(this.perimeters).map('data').compact().value()
      return Turf.featureCollection(features)
    }
  },
  methods: {
    ...mapActions(useModeStore, ['enteringEditError']),
    drawVectorListener({ features }) {
      this.vector = features[0]
    },
    enterBulkMove() {
      document.querySelector('.mapboxgl-canvas-container').style.cursor = 'default'
      this.modeStore.stopLeftPanel()
      this.mapStore.hideMenu()
      this.originalFeatures = this.perimeterFeatures
      this.map.addSource(this.sourceId, { type: 'geojson', data: this.originalFeatures })
      this.map.addLayer({
        id: this.layerId,
        source: this.sourceId,
        type: 'line',
        paint: { 'line-color': this.mapStore.colors.line.blue, 'line-width': 2 }
      })
      this.mapStore.addDrawListener('draw.create', this.drawVectorListener)
      this.mapStore.addDrawListener('draw.update', this.drawVectorListener)
      this.draw.changeMode(this.drawMode)
      this.$parent.modeChanged()

      const bbox = new BBox(this.originalFeatures).toSquare()
      const bboxPolygon = Turf.bboxPolygon(bbox.value)
      this.originalPivot = this.rotationPivot = Turf.center(bboxPolygon)
    },
    exitBulkMove() { this.modeStore.enterViewMode() },
    previewButton() {
      const source = this.map.getSource(this.sourceId)
      const features = source._data.features
      const [from, to] = this.vector.geometry.coordinates
      const distance = Turf.distance(from, to)
      const bearing = Turf.bearing(from, to)

      const movedFeatures = features.map((f) => Turf.transformTranslate(f, distance, bearing))
      this.rotationPivot = Turf.transformTranslate(this.rotationPivot, distance, bearing)

      source.setData(Turf.featureCollection(movedFeatures))

      // Preview removes the vector from the map but maintains the modifications in the state
      this.draw.delete(this.vector.id)
      this.previewing = true
    },
    clearAll() {
      if (this.vector) {
        this.draw.delete(this.vector.id)
        this.vector = null
      }
      this.rotationPivot = this.originalPivot
      this.rotationAngle = 0
    },
    resetButton() {
      this.clearAll()

      const source = this.map.getSource(this.sourceId)
      source.setData(this.originalFeatures)
      this.draw.changeMode(this.drawMode)
      this.previewing = false
    },
    cancelCallback() {
      this.clearAll()
      this.exitBulkMove()
    },
    async saveCallback() {
      const opts = { sectionId: this.sectionId, vector: this.vector, angle: this.rotationAngle, pivot: this.rotationPivot }
      this.geometryStore.moveAndRotateAllFeatures(opts)
      const saved = await this.geometryStore.saveAll()
      if (!saved) { return }

      this.clearAll()
      this.exitBulkMove()
    },
    rotate(clockwise) {
      const source = this.mapStore.map.getSource(this.sourceId)
      this.rotationAngle += clockwise ? 5 : -5
      const pivot = this.rotationPivot.geometry.coordinates
      source.setData(Turf.transformRotate(source._data, clockwise ? 5 : -5, { pivot }))
    }
  },
  async mounted() { await initializeLockedMode(this.enterBulkMove) },
  unmounted() {
    document.querySelector('.mapboxgl-canvas-container').style.cursor = ''
    this.map.removeLayer(this.layerId)
    this.map.removeSource(this.sourceId)
    this.mapStore.removeDrawListener('draw.create', this.drawVectorListener)
    this.mapStore.removeDrawListener('draw.update', this.drawVectorListener)
    this.draw.changeMode('simple_select')
    this.draw.deleteAll()
    this.lockStore.disablePageExitGuard()
    this.modeStore.startLeftPanel()
    this.mapStore.showMenu()
  },
  components: { CancelButton, SaveAndExitButton, PreviewButton, ResetButton },
  template: `
  <div class='absolute left-2 bottom-8'>
    <button @click='rotate(false)' class='whitespace-nowrap black-button'>-</button>
    <button @click='rotate(true)' class='whitespace-nowrap black-button ml-2'>+</button>
    <PreviewButton :disabled="isPreviewDisabled" :handle-click='previewButton' />
    <ResetButton :disabled="isResetDisabled" :handle-click='resetButton' />
    <SaveAndExitButton :disabled="isSaveDisabled":save-callback='saveCallback' />
    <CancelButton :cancel-callback='cancelCallback' />
  </div>
  `
}
