import { useGeometryStore, useMapStore } from '../index'
import { getPolygonConnectionStatus, perimeterCenter, namedColorToColorCode } from './utils'
import { constants } from '../../constants'
import _ from 'lodash'
import * as Turf from '@turf/turf'

class Section {
  constructor(elems) {
    const withGeometry = elems.filter((e) => e.data)
    const defectsWithGeometry = withGeometry.filter(e => e.classification_target === constants.targets.DEFECT)
    const inventoriesWithGeometry = withGeometry.filter(e => e.classification_target === constants.targets.INVENTORY)

    const interiorPerimeters = elems.filter(e => e.classification_target === constants.targets.INTERIOR_PERIMETER)
    const interiorPerimeterByGroup = _.groupBy(interiorPerimeters, 'group_id')
    const interiorPerimetersWithGeometry = interiorPerimeters.filter((e) => e.data)

    const perimeters = elems.filter(e => e.classification_target === constants.targets.PERIMETER)
    const sortedPerimeters = _.sortBy(perimeters, 'sort_order')
    const sortedPerimetersWithGeometry = sortedPerimeters.filter((e) => e.data)

    this._id = elems[0].section_id
    this._code = elems[0].section_code
    this._defectIds = defectsWithGeometry.map(e => e.id)
    this._inventoryIds = inventoriesWithGeometry.map(e => e.id)
    this._interiorPerimeterIds = interiorPerimetersWithGeometry.map(e => e.id)
    this._sortedPerimeterIds = sortedPerimetersWithGeometry.map(e => e.id)
    this._center = perimeterCenter(this.vertices, this._id, this._code) || null
    this._scoreColor = namedColorToColorCode(perimeters[0]?.section_score_color) || null
    this._status = getPolygonConnectionStatus(sortedPerimeters.map((p) => p.data))

    this._interiorPerimeterGroups = _.mapValues(interiorPerimeterByGroup, (group, groupId) => {
      const sortedGroup = _.sortBy(group, 'sort_order').map((p) => p.data)
      return {
        status: group[0].closed ? getPolygonConnectionStatus(sortedGroup) : constants.connectionStatuses.OPEN_GROUP
      }
    })
  }

  get id() {
    return this._id
  }

  get code() {
    return this._code
  }

  get isOutOfOrder() {
    return this._status === constants.connectionStatuses.OUT_OF_ORDER
  }

  get isUnclosed() {
    return this._status === constants.connectionStatuses.DISCONNECTED
  }

  get isMissingGeometries() {
    return this._status === constants.connectionStatuses.MISSING_GEOMETRIES
  }

  get isConnected() {
    return this._status === constants.connectionStatuses.CONNECTED
  }

  get defectIds() {
    return this._defectIds
  }

  get inventoryIds() {
    return this._inventoryIds
  }

  get interiorPerimeterIds() {
    return this._interiorPerimeterIds
  }

  get perimeterIds() {
    return this._sortedPerimeterIds
  }

  get center() {
    return this._center
  }

  get scoreColor() {
    return this._scoreColor
  }

  get defects() {
    const store = useGeometryStore()
    return this._defectIds.map(id => Turf.clone(store.defects[id].data))
  }

  get interiorPerimeters() {
    const store = useGeometryStore()
    return this._interiorPerimeterIds.map(id => {
      const clone = Turf.clone(store.perimeterSegments[id].data)
      clone.properties.classification_name = store.perimeterSegments[id].classification_name
      clone.properties.group_id = store.perimeterSegments[id].group_id
      return clone
    })
  }

  get updatedInteriorPerimeters() {
    const store = useMapStore()
    return this._interiorPerimeterIds.map(id => store.draw.get(id))
  }

  get perimeters() {
    const store = useGeometryStore()
    return this._sortedPerimeterIds.map(id => {
      const clone = Turf.clone(store.perimeterSegments[id].data)
      clone.properties.classification_name = store.perimeterSegments[id].classification_name
      return clone
    })
  }

  get updatedPerimeters() {
    const store = useMapStore()
    return this._sortedPerimeterIds.map(id => store.draw.get(id))
  }

  get vertices() {
    return this.perimeters.map(f => f.geometry.coordinates).flat()
  }

  get updatedVertices() {
    return this.updatedPerimeters.map(f => f.geometry.coordinates).flat()
  }

  get clockwisePerimeters() {
    const perimeters = this.perimeters
    const isClockwise = Turf.booleanClockwise(this.vertices)

    if (!isClockwise) {
      perimeters.reverse().forEach(p => p.geometry.coordinates.reverse())
    }

    return perimeters
  }

  get updatedClockwisePerimeters() {
    const perimeters = this.updatedPerimeters
    const isClockwise = Turf.booleanClockwise(this.updatedVertices)

    if (!isClockwise) {
      perimeters.reverse().forEach(p => p.geometry.coordinates.reverse())
    }

    return perimeters
  }

  get clockwiseVertices() {
    // Returns not only the vertex coords but also the id of the perimeter it belongs to
    return this.clockwisePerimeters.flatMap(p =>
      p.geometry.coordinates.map(coords => ({
        id: p.id,
        coordinates: coords,
        mercatorCoordinates: Turf.toMercator(coords)
      }))
    )
  }

  get updatedClockwiseVertices() {
    // Returns not only the vertex coords but also the id of the perimeter it belongs to
    return this.updatedClockwisePerimeters.flatMap(p =>
      p.geometry.coordinates.map(coords => ({
        id: p.id,
        coordinates: coords,
        mercatorCoordinates: Turf.toMercator(coords)
      }))
    )
  }

  isGroupUnclosed(groupId) {
    return this._interiorPerimeterGroups[groupId]?.status === constants.connectionStatuses.DISCONNECTED
  }

  isGroupMissingGemetries(groupId) {
    return this._interiorPerimeterGroups[groupId]?.status === constants.connectionStatuses.MISSING_GEOMETRIES
  }

  isGroupOutOfOrder(groupId) {
    return this._interiorPerimeterGroups[groupId]?.status === constants.connectionStatuses.OUT_OF_ORDER
  }

  isGroupConnected(groupId) {
    return this._interiorPerimeterGroups[groupId]?.status === constants.connectionStatuses.CONNECTED
  }

  isGroupOpen(groupId) {
    return this._interiorPerimeterGroups[groupId]?.status === constants.connectionStatuses.OPEN_GROUP
  }

  set center(newCenter) {
    this._center = newCenter
  }

  set centerVisibility(visible) {
    this._center.properties.visible = visible
  }
}

export default Section
