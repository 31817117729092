import { Controller } from "@hotwired/stimulus"

import AerialMap from './aerial_map/app.js'

export default class extends Controller {
  static targets = ['map-container']

  static values = {
    mapProvider: String,
    mapboxToken: String,
    azureKey: String,
    eagleviewKey: String,
    propertyData: Object
  }

  connect() {
    const { location, defects } = this.propertyDataValue
    window.app = new AerialMap(
      this.mapProviderValue,
      this.mapboxTokenValue,
      this.azureKeyValue,
      this.eagleviewKeyValue,
      location,
      defects
    )

    window.app.mount('map-container')

    this.element.setAttribute('data-controller-connected', 'true')
  }
}
