<div class="text-center text-sm py-2">
  <div v-if="selectedFeature">
    <span class="font-semibold">{{ selectedFeatureType }}</span>:
    <a :href="selectedFeatureUrl" target="_blank" class="underline">{{ selectedFeature.properties.classification_name }}</a>
    <div v-if="showMenuOptions" class="block text-right">
      <hr class="my-2" />
      <button
        v-if="!isPoint"
        @click="handleAddPoint()"
        :disabled="!canAddPoint"
        :class="{
          'text-blue-600 cursor-pointer': canAddPoint,
          'text-gray-200 cursor-not-allowed': !canAddPoint
        }"
        class="block mt-2 font-semibold"
        :title="canAddPoint ? '' : 'Please select exactly one point to proceed'"
      >
        Add Point
      </button>
      <button
        v-if="isPerimeterSegment"
        @click="handleChangeType()"
        class="text-blue-600 cursor-pointer block mt-2 font-semibold"
      >
        Change Type
      </button>
    </div>
  </div>
  <span v-else>No Selection</span>
</div>
