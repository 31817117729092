import { mapState } from 'pinia'
import { useMapStore } from '../../stores/map_store/index.js'

import template from './index.html'

export default {
  computed: {
    ...mapState(useMapStore, ['modalComponent', 'modalComponentProps'])
  },
  template: template
}
