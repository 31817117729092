import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';
import polylabel from 'polylabel';
import _capitalize from 'lodash/fp/capitalize';

export default class extends Controller {
  static targets = [
    'map',
    'workOrderOverlayTemplate',
    'legendTemplate',
    'miscWorkOrderButtonTemplate',
    'workOrderStatsTemplate',
    'workOrderRowTemplate',
    'workOrderTableTemplate',
    'noWorkOrdersTemplate',
  ];

  static values = {
    data: Object,
  };

  connect() {
    const { property: { lat, lng }, property, sections } = this.dataValue;
    this._property = property;
    this._sections = sections;
    this._currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const loader = new Loader({
      apiKey: 'AIzaSyAJPxB8yF1EMzCC2EqzvY4Bhpdkp84dLqU',
      version: 'weekly',
    });

    const defaultLat = 39.602761;
    const defaultLng = -104.893176;
    const defaultZoom = 17;

    let points = this.allPoints(sections);
    const [centerLat, centerLng] = this.getCenterPoint(points);
    const centerPoint = {
      lat: Number(centerLat || lat || defaultLat),
      lng: Number(centerLng || lng || defaultLng),
    };

    loader.load().then(() => {
      const { google } = window;
      this._google = google;
      this._map = new google.maps.Map(this.mapTarget, {
        zoom: Number(defaultZoom),
        center: centerPoint,
        mapTypeId: 'satellite',
        tilt: 0,
      });
      let bounds  = new google.maps.LatLngBounds();
      this.allPoints(sections).forEach(({ lat, lng }) => {
        bounds.extend(new google.maps.LatLng(lat, lng));
      });

      if (!bounds.isEmpty()) {
        this._map.fitBounds(bounds, 100);
        this._map.panToBounds(bounds);
      }

      const overlay = new google.maps.OverlayView();
      overlay.draw = function () {};
      overlay.setMap(this._map);

      let topControlsDiv = document.createElement("div");
      const stats = this.workOrderStats();
      topControlsDiv.innerHTML = this.workOrderStatsContainer(stats);

      for (const [sectionId, { work_orders: workOrders, points }] of Object.entries(sections)) {
        let color;
        if (workOrders.length === 0) {
          color = 'white';
        } else if (workOrders.length >= 1 && workOrders.length < 4) {
          color = '#B9D4EE';
        } else if (workOrders.length >= 4 && workOrders.length < 8) {
          color = '#3B8CDB';
        } else {
          color = '#1F5992';
        }

        if (sectionId === 'misc') {
          if (workOrders.length !== 0) {
            const miscWorkOrdersControlDiv = document.createElement("div");
            this.miscWorkOrdersControl(miscWorkOrdersControlDiv);
            miscWorkOrdersControlDiv.className = "text-center"

            miscWorkOrdersControlDiv.querySelector('button').addEventListener('click', e => {
              e.stopPropagation();
              this.openSectionPopup(sectionId, { x: e.currentTarget.offsetLeft, y: e.currentTarget.offsetTop + e.currentTarget.offsetHeight });
            });

            topControlsDiv.append(miscWorkOrdersControlDiv);
          }
        } else {
          const sectionPolygon = new google.maps.Polygon({
            paths: points,
            fillColor: color,
            fillOpacity: 0.8,
            strokeColor: color,
            strokeWeight: 2,
          });
          sectionPolygon.setMap(this.map());

          const [ centerLat, centerLng ] = this.getCenterPoint(points);
          const marker = new google.maps.Marker({
            id: sectionId,
            position: { lat: centerLat, lng: centerLng },
            label: {
              text: sections[sectionId].label,
              color: 'black',
            },
            title: property.name,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 14,
              fillColor: '#ffffff',
              fillOpacity: 0.8,
              strokeColor: '#cdcdcd',
              strokeWeight: 2,
            },
          });
          marker.setMap(this.map());

          google.maps.event.addListener(sectionPolygon, 'click', e => {
            this.openSectionPopup(sectionId, e.latLng);
          });

          google.maps.event.addListener(marker, 'click', e => {
            this.openSectionPopup(sectionId, e.latLng);
          });
        }
      }

      this.map().controls[google.maps.ControlPosition.TOP_CENTER].push(topControlsDiv);
      this.map().controls[google.maps.ControlPosition.LEFT_TOP].push(this.legendControl());

      topControlsDiv.addEventListener('click', e => {
        this.closePopup();
      });
      this.map().addListener('click', e => {
        this.closePopup();
      });
      this.map().addListener('bounds_changed', _ => {
        this.closePopup();
      });
    });
  }

  allPoints(sections) {
    let _allPoints = [];

    for (const [_, { points }] of Object.entries(sections)) {
      _allPoints.push(points);
    }

    return _allPoints.flat();
  }

  getCenterPoint(points) {
    if (points.length == 0) {
      return [];
    }
    return polylabel([points.map(({ lat, lng }) => [ lat, lng ])], 0.000005);
  }

  map() {
    return this._map;
  }

  property() {
    return this._property;
  }

  getSection(sectionId) {
    return this.getSections()[sectionId] || {};
  }

  getSections() {
    return this._sections || {};
  }

  getWorkOrders(section) {
    const { work_orders: workOrders = [] } = section;
    return workOrders;
  }

  openSectionPopup(sectionId, latLng) {
    this.closePopup();
    this.popup(sectionId, latLng).setMap(this.map());
  }

  closePopup() {
    if (this._popup !== undefined) {
      this.popup().setMap(null);
      this._popup = undefined;
    }
  }

  workOrderStats() {
    let stats = {
      count: 0,
      cost: 0,
      worstSection: []
    }
    for (const [sectionId, { work_orders: workOrders, points }] of Object.entries(this.getSections())) {
      const section = this.getSections()[sectionId];
      stats.count += workOrders.length
      stats.cost += workOrders.reduce((sum, workOrder) => sum += parseFloat(workOrder.total_cost), 0)

      if (stats.worstSection.length === 0 && workOrders.length > 0) {
        stats.worstSection.push(section.label, workOrders.length)
      } else {
        if (stats.worstSection[1] < workOrders.length) {
          stats.worstSection[0] = section.label
          stats.worstSection[1] = workOrders.length
        }
      }
    }
    return stats;
  }

  popup(sectionId, latLng) {
    if (this._popup === undefined) {
      const section = this.getSection(sectionId);
      this._popup = new (this.popupClass())(
        latLng,
        this.popupContent(section, this.getWorkOrders(section)),
      );
    }

    return this._popup;
  }

  popupContent(section, workOrders) {
    return this.workOrderOverlayTemplateTarget.innerHTML.interpolate({ self: this, section, workOrders });
  }

  workOrdersTable(workOrders) {
    const workOrderRows = workOrders.reduce((str, workOrder) => str.concat(
      this.workOrderRowTemplateTarget.innerHTML.interpolate({ self: this, workOrder })
    ), '')
    let div = document.createElement('div');
    div.innerHTML = this.workOrderTableTemplateTarget.innerHTML;
    let tbody = div.getElementsByTagName('tbody')[0]
    tbody.innerHTML = workOrderRows;
    return div.innerHTML;
  }

  noWorkOrders(section) {
    return this.noWorkOrdersTemplateTarget.innerHTML.interpolate({ section });
  }

  workOrderStatsContainer(stats) {
    return this.workOrderStatsTemplateTarget.innerHTML.interpolate({ self: this, stats });
  }

  miscWorkOrdersControl(controlDiv) {
    controlDiv.innerHTML = this.miscWorkOrderButtonTemplateTarget.innerHTML.interpolate();
  }

  legendControl() {
    let container = document.createElement('div')

    container.innerHTML = this.legendTemplateTarget.innerHTML.interpolate();

    return container;
  }

  popupClass() {
    const self = this;
    if (this._popupClass === undefined) {
      this._popupClass = class extends self._google.maps.OverlayView {
        position;
        containerDiv;
        constructor(position, content) {
          super();
          this.position = position;
          this.containerDiv = document.createElement('div');
          this.containerDiv.classList.add('absolute');
          this.containerDiv.style.left = '0px';
          this.containerDiv.style.top = '0px';
          this.containerDiv.style.zIndex = '10000';

          this.contentDiv = document.createElement('div');
          this.contentDiv.classList.add('absolute');
          this.contentDiv.style.left = '50%';
          this.contentDiv.style.bottom = '0px';
          this.contentDiv.style.zIndex = 0;
          this.contentDiv.style.animationDuration = '100ms';
          this.contentDiv.innerHTML = content;
          this.containerDiv.append(this.contentDiv);
          // Optionally stop clicks, etc., from bubbling up to the map.
          this.constructor.preventMapHitsAndGesturesFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd() {
          this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
          if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
          }
        }

        /** Called each frame when the popup needs to draw itself. */
        draw() {
          const width = this.contentDiv.offsetWidth;
          const height = this.contentDiv.offsetHeight;
          const { size: mapSize } = self.viewport();
          let display;

          if (this.position !== undefined && this.position.hasOwnProperty('lat')) {
            const containerPosition = this.getProjection().fromLatLngToDivPixel(
              this.position,
            );
            // Hide the popup when it is far out of view.
            display
              = Math.abs(containerPosition.x) < 4000 && Math.abs(containerPosition.y) < 4000
                ? 'block'
                : 'none';

            if (display === 'block') {
              this.containerDiv.style.transform = `translate(${containerPosition.x}px, ${containerPosition.y}px)`;

              const {
                positionX,
                positionY,
                offsetX,
                offsetY,
              } = self.popupPosition(this.position, mapSize, { width, height });

              const contentTransform = `translate(calc(${{
                left: 0,
                center: -50,
                right: -100,
              }[positionX]}% + ${offsetX}px), calc(${{
                top: 100,
                middle: 50,
                bottom: 0,
              }[positionY]}% + ${offsetY}px))`;

              this.contentDiv.style.transform = contentTransform;
            }
          } else {
            display = 'block';
            this.contentDiv.style.bottom = null;
            const yPos = -(mapSize.height / 2) + this.position.y + 10;
            this.containerDiv.style.transform = `translate(0px, ${yPos}px)`;
            this.contentDiv.style.transform = `translate(-50%, 0px)`;;
          }

          if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
          }
        }
      };
    }

    return this._popupClass;
  }

  mapPaddings() {
    return { top: 24, bottom: 24, left: 24, right: 24 };
  }

  popupPosition(point, mapSize, popupSize) {
    const mapBounds = {
      topRight: {
        x: mapSize.width / 2,
        y: -mapSize.height / 2,
      },
      bottomLeft: {
        x: -mapSize.width / 2,
        y: mapSize.height / 2,
      },
    };
    const paddedMapBounds = this.padBounds(mapBounds, this.mapPaddings());
    const halfPaddedPopupBounds = {
      top: (popupSize.height) / 2,
      bottom: (popupSize.height + 0) / 2,
      left: (popupSize.width - 0) / 2,
      right: (popupSize.width + 0) / 2,
    };
    const fullPaddedPopupBounds = {
      top: popupSize.height,
      bottom: popupSize.height,
      left: popupSize.width,
      right: popupSize.width,
    };
    const {
      x: centerX,
      y: centerY,
    } = this.fromLatLngToCenterPixel(this.fromGoogleLatLng(point), this.viewport().zoom, this.viewport().center);
    const swX = paddedMapBounds.bottomLeft.x;
    const neX = paddedMapBounds.topRight.x;
    const neY = paddedMapBounds.topRight.y;
    const seY = paddedMapBounds.bottomLeft.y;

    return this.popupOffsets({
      topFullRemaining: centerY - fullPaddedPopupBounds.top - neY,
      topHalfRemaining: centerY - halfPaddedPopupBounds.top - neY,
      bottomFullRemaining: seY - (fullPaddedPopupBounds.bottom + centerY),
      bottomHalfRemaining: seY - (halfPaddedPopupBounds.bottom + centerY),
      leftFullRemaining: centerX - fullPaddedPopupBounds.left - swX,
      leftHalfRemaining: centerX - halfPaddedPopupBounds.left - swX,
      rightFullRemaining: neX - (fullPaddedPopupBounds.right + centerX),
      rightHalfRemaining: neX - (halfPaddedPopupBounds.right + centerX),
    });
  }

  padBounds(bounds, paddings) {
    return {
      topRight: {
        x: bounds.topRight.x - paddings.right,
        y: bounds.topRight.y + paddings.top,
      },
      bottomLeft: {
        x: bounds.bottomLeft.x + paddings.left,
        y: bounds.bottomLeft.y - paddings.bottom,
      },
    };
  }

  fromLatLngToCenterPixel(point, zoom, mapCenter) {
    const zoomMultiplier = 2 ** zoom;
    const pointPixel = this.fromLatLngToPixel(point);
    const mapCenterPixel = this.fromLatLngToPixel(mapCenter);
    const centerPixel = {
      x: (pointPixel.x - mapCenterPixel.x) * zoomMultiplier,
      y: (pointPixel.y - mapCenterPixel.y) * zoomMultiplier,
    };
    const maxPixel = 256 * zoomMultiplier;

    return centerPixel.x > maxPixel / 2 ? {
      x: centerPixel.x - maxPixel,
      y: centerPixel.y,
    } : centerPixel.x < -maxPixel / 2 ? {
      x: centerPixel.x + maxPixel,
      y: centerPixel.y,
    } : centerPixel;
  }

  fromLatLngToPixel(point) {
    return {
      x: (this.moduloCoordinate(point.lng) + 180) / 360 * 256,
      y: (1 - (Math.log(Math.tan(point.lat * Math.PI / 180) + (1 / Math.cos(point.lat * Math.PI / 180))) / Math.PI)) / 2 * (2 ** 0) * 256,
    };
  }

  fromGoogleLatLng(point) {
    return {
      lat: point.lat(),
      lng: point.lng(),
    };
  }

  moduloCoordinate(lng) {
    return lng === 180 ? 180 : lng < 0 ? ((lng - 180) % 360) + 180 : ((lng + 180) % 360) - 180;
  }

  popupOffsets({
    topFullRemaining,
    topHalfRemaining,
    bottomFullRemaining,
    bottomHalfRemaining,
    leftFullRemaining,
    leftHalfRemaining,
    rightFullRemaining,
    rightHalfRemaining,
  }) {
    if (bottomFullRemaining > 0 || topFullRemaining > 0) {
      let positionY = 'middle';
      if (topFullRemaining > 0) {
        positionY = 'bottom';
      } else if (bottomFullRemaining > 0) {
        positionY = 'top';
      }

      const offsetX = this.calculateOffset({
        startRemaining: leftHalfRemaining,
        endRemaining: rightHalfRemaining,
      });
      return {
        positionY,
        offsetY: 0,
        offsetX,
        positionX: 'center',
      };
    }

    let positionX = 'center';
    if (leftFullRemaining > 0) {
      positionX = 'right';
    }

    if (rightFullRemaining > 0) {
      positionX = 'left';
    }

    const offsetX = this.calculateOffset({
      startRemaining: leftHalfRemaining,
      endRemaining: rightHalfRemaining,
    });
    const offsetY = this.calculateOffset({
      startRemaining: topHalfRemaining,
      endRemaining: bottomHalfRemaining,
    });
    return {
      positionX,
      positionY: 'middle',
      offsetX: positionX === 'center' ? offsetX : 0,
      offsetY,
    };
  }

  calculateOffset({ startRemaining, endRemaining }) {
    return (startRemaining > 0 && endRemaining > 0) || (startRemaining < 0 && endRemaining < 0)
      ? 0
      : endRemaining < 0
        ? endRemaining
        : startRemaining < 0
          ? -startRemaining
          : 0;
  }

  viewport() {
    const container = this.map().getDiv().getBoundingClientRect();
    const mapSize = {
      height: container.height,
      width: container.width,
    };
    const centerLatLng = this.map().getCenter();
    const center = this.fromGoogleLatLng(centerLatLng);
    const zoom = this.map().getZoom();

    return {
      center,
      zoom,
      size: mapSize,
    };
  }

  currencyFormatter() {
    return this._currencyFormatter;
  }

  capitalize(string) {
    return _capitalize(string);
  }
}
