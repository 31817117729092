import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapActions } from 'pinia'
import { useModeStore } from '../../../../stores'

export default {
  name: 'LoadingFormError',
  methods: {
    ...mapActions(useModeStore, ['clearError']),
    hideLoadingFormError() { this.clearError('loadingForm') }
  },
  template: `
    <div class="flex flex-col items-center justify-center">
      <p class="text-white">An error occurred while loading the form.</p>
      <button
       data-test="closeButton"
        @click="hideLoadingFormError()"
        class="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4 cursor-pointer"
      >
        Close
      </button>
    </div>
  `
}
