import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'dependent'];
  static values = { 'toggle': String }

  connect() {
    this.updateTarget();
  }

  handleSelectChange() {
    this.updateTarget();
  }

  updateTarget() {
    this.dependentTargets.forEach(select => {
      select.parentNode.parentNode.classList.toggle('hidden', this.parentTarget.value != this.toggleValue);
    });
  }
}
