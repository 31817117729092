import _ from 'lodash'
import { defineStore } from 'pinia'

import { useModeStore } from '../'
import * as Actions from './actions'

export const useLockStore = defineStore('lock', {
  state: () => ({
    authToken: null,
    owner: null,
    since: null,
    myLock: false,
    discardMessage: 'Are you sure you want to exit the current mode and discard any unsaved changes?'
  }),
  getters: {
    mode: (_state) => {
      const modeStore = useModeStore()
      // ViewMode can use the edit confirmation messages when deleting a feature.
      return modeStore.inViewMode ? 'edit' : modeStore.current
    },
    confirmations: (state) => {
      const genericLockMessages = {
        myLockClaim: `This survey was locked by you on ${state.since}. `
          + 'Please verify that there are no other browsers or tabs open with unsaved changes pending. '
          + 'Continuing can override your previous lock, do you wish to continue?',
        otherLockClaim: `${state.owner} currently has this survey locked, since ${state.since}. `
          + "Continuing can overwrite the other user's data, do you wish to continue?",
        otherLockSave: `${state.owner} currently has this survey locked, since ${state.since}. `
          + "Saving can overwrite the other user's data, do you wish to continue?",
        myLockDiscard: state.discardMessage
      }

      return {
        edit: {
          myLockClaim: `This survey was started editing by you on ${state.since}. `
            + 'Please verify that there are no other browsers or tabs open with unsaved changes pending. '
            + 'Continuing can overwrite your unsaved data, do you wish to continue?',
          otherLockClaim: `${state.owner} is currently editing this survey, starting at ${state.since}. `
            + "Continuing can overwrite the other user's data, do you wish to continue?",
          otherLockSave: `${state.owner} is also editing this survey, starting at ${state.since}. `
            + "Saving can overwrite the other user's data, do you wish to continue?",
          myLockDiscard: state.discardMessage
        },
        bulkMove: { ...genericLockMessages },
        placement: { ...genericLockMessages }
      }
    },
  },
  actions: {
    resetState() {
      _.merge(this, { owner: null, since: null, myLock: false })
    },
    tryLock: Actions.tryLock,
    fetchAuthToken: Actions.fetchAuthToken,
    fetchLock: Actions.fetchLock,
    confirmClaim: Actions.confirmClaim,
    claimLock: Actions.claimLock,
    confirmSave: Actions.confirmSave,
    releaseLock: Actions.releaseLock,
    confirmDiscard: Actions.confirmDiscard,
    discardLock: Actions.discardLock,
    unlockAndClear: Actions.unlockAndClear,
    enablePageExitGuard: Actions.enablePageExitGuard,
    disablePageExitGuard: Actions.disablePageExitGuard,
    beforeUnloadListener: Actions.beforeUnloadListener,
    unloadListener: Actions.unloadListener
  }
})
